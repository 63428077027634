import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import {
  FrontendProjectCategoryType,
  FrontendProjectDisciplineType,
  FrontendProjectHolidayType,
  FrontendProjectLocationLimited,
  FrontendProjectManpowerPositionLimitedType,
  FrontendProjectPositionType,
  FrontendProjectTradeType,
  FrontendSubPositionType,
} from "../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { getProjectLocation } from "./getProjectLocation.ts";
import {
  convertCategoryData,
  convertPositionData,
  convertProjectDisciplineData,
  convertProjectHolidayData,
  convertProjectLocationLimitedData,
  convertProjectManpowerPositionData,
  convertProjectMissingConfigurationInfo,
  convertSubPositionData,
  convertSubPositionSingleData,
  convertTradeData,
} from "../../../types/apicallstypes/apicallstypesconverters/projects/projectsutils/ProjectsUtilsApiConverter.ts";
import { FrontendCtrBasicType, FrontendCtrLimitedType, FrontendCtrNormalType } from "../../../types/apicallstypes/CtrTypes.ts";
import { getProjectCtr } from "./getProjectCtr.ts";
import {
  convertAllCtrsLimitedFrontend,
  convertCtrBasicFrontend,
  convertCtrLimitedFrontend,
  convertCtrNormalFrontend,
} from "../../../types/apicallstypes/apicallstypesconverters/CtrApiConverter.ts";
import { CtrBasic, CtrLimited, CtrNormal } from "epcm-common/dist/Types/CtrTypes";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { getAllProjectCtrs } from "./getAllProjectCtrs.ts";
import { getProjectPosition } from "./getProjectPosition.ts";
import { getManpowerPositionCodeCategory } from "./getProjectCategory.ts";
import { getManpowerPositionCodeTrade } from "./getProjectTrade.ts";
import { getAllProjectSubpositions } from "./getAllProjectSubpositions.ts";
import { getAllProjectPositions } from "./getAllProjectPositions.ts";
import { getProjectSubPosition } from "./getProjectSubposition.ts";
import { getAllProjectTrades } from "./getAllProjectTrades.ts";
import { getAllProjectLocations } from "./getAllProjectLocations.ts";
import { getAllProjectCategories } from "./getAllProjectCategories.ts";
import { getAllProjectDisciplines } from "./getAllProjectDisciplines.ts";
import { getAllProjectManpowerPositions } from "./getAllProjectManpowerPositions.ts";
import { FrontendMissingConfigurationType } from "../../../types/projects/ConfigurationTypes.ts";
import { getProjectConfigurationsMissingInfo } from "./getProjectConfigurationsMissingInfo.ts";
import { getProjectDiscipline } from "./getProjectDiscipline.ts";
import { getAllProjectHolidays } from "./getAllProjectHolidays.ts";

interface ApiDefinition {
  getProjectLocation: (id: number, locationId: number, detailLevel?: DetailLevel) => Promise<FrontendProjectLocationLimited>;
  getAllProjectCtrs: (
    id: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendCtrLimitedType>>;

  getProjectCtr: (
    id: number,
    ctrId: number,
    detailLevel?: DetailLevel,
  ) => Promise<FrontendCtrLimitedType | FrontendCtrBasicType | FrontendCtrNormalType>;

  getAllProjectPositions: (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendProjectPositionType>>;

  getAllProjectManpowerPositions: (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendProjectManpowerPositionLimitedType>>;

  getAllProjectTrades: (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendProjectTradeType>>;

  getAllProjectDisciplines: (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendProjectDisciplineType>>;

  getAllProjectLocations: (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendProjectLocationLimited>>;

  getAllProjectCategories: (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendProjectCategoryType>>;

  getAllProjectHolidays: (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendProjectHolidayType>>;

  getProjectPosition: (id: number, positionId: number, detailLevel?: DetailLevel) => Promise<FrontendProjectPositionType>;

  getProjectCategory: (id: number, categoryId: number, detailLevel?: DetailLevel) => Promise<FrontendProjectCategoryType>;
  getProjectTrade: (id: number, tradeId: number, detailLevel?: DetailLevel) => Promise<FrontendProjectTradeType>;
  getProjectDiscipline: (id: number, disciplineId: number, detailLevel?: DetailLevel) => Promise<FrontendProjectDisciplineType>;
  getProjectSubpositions: (projectId: number, positionId: number) => Promise<FrontendSubPositionType[]>;
  getProjectSubposition: (
    projectId: number,
    positionId: number,
    subpositionId: number,
    detailLevel?: DetailLevel,
  ) => Promise<FrontendSubPositionType>;
  getProjectMissingInfo: (projectId: number) => Promise<FrontendMissingConfigurationType>;
}

export const useEpcmApiProjectsUtils = (): ApiDefinition => {
  const getProjectLocationConverted = async (id: number, locationId: number, detailLevel?: DetailLevel): Promise<FrontendProjectLocationLimited> => {
    const projectLocationData = await getProjectLocation(id, locationId, detailLevel);
    return convertProjectLocationLimitedData(projectLocationData);
  };

  const getAllProjectCtrsConverted = async (
    id: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendCtrLimitedType>> => {
    const projectCtrsData = await getAllProjectCtrs(id, pageOffset, searchFilter, pageSize);

    return {
      data: convertAllCtrsLimitedFrontend(projectCtrsData.data),
      nextPage: projectCtrsData.nextPage,
      totalCount: projectCtrsData.totalCount,
    };
  };

  const getProjectCtrConverted = async (
    id: number,
    ctrId: number,
    detailLevel?: DetailLevel,
  ): Promise<FrontendCtrLimitedType | FrontendCtrBasicType | FrontendCtrNormalType> => {
    const projectCtrData = await getProjectCtr(id, ctrId, detailLevel);
    return detailLevel === DetailLevel.LIMITED
      ? convertCtrLimitedFrontend(projectCtrData as CtrLimited)
      : detailLevel === DetailLevel.NORMAL
        ? convertCtrNormalFrontend(projectCtrData as CtrNormal)
        : convertCtrBasicFrontend(projectCtrData as CtrBasic);
  };

  const getAllProjectPositionsConverted = async (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendProjectPositionType>> => {
    const projectPositionsData = await getAllProjectPositions(projectId, pageOffset, searchFilter, pageSize);
    return {
      data: projectPositionsData.data.map((position) => convertPositionData(position)),
      nextPage: projectPositionsData.nextPage,
      totalCount: projectPositionsData.totalCount,
    };
  };
  const getAllProjectManpowerPositionsConverted = async (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendProjectManpowerPositionLimitedType>> => {
    const projectPositionsData = await getAllProjectManpowerPositions(projectId, pageOffset, searchFilter, pageSize);
    return {
      data: projectPositionsData.data.map((position) => convertProjectManpowerPositionData(position)),
      nextPage: projectPositionsData.nextPage,
      totalCount: projectPositionsData.totalCount,
    };
  };

  const getAllProjectTradesConverted = async (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendProjectTradeType>> => {
    const projectTradesData = await getAllProjectTrades(projectId, pageOffset, searchFilter, pageSize);
    return {
      data: projectTradesData.data.map((trade) => convertTradeData(trade)),
      nextPage: projectTradesData.nextPage,
      totalCount: projectTradesData.totalCount,
    };
  };

  const getAllProjectDisciplinesConverted = async (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendProjectDisciplineType>> => {
    const projectDisciplinesData = await getAllProjectDisciplines(projectId, pageOffset, searchFilter, pageSize);
    return {
      data: projectDisciplinesData.data.map((discipline) => convertProjectDisciplineData(discipline)),
      nextPage: projectDisciplinesData.nextPage,
      totalCount: projectDisciplinesData.totalCount,
    };
  };
  const getAllProjectLocationsConverted = async (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendProjectLocationLimited>> => {
    const projectLocationsData = await getAllProjectLocations(projectId, pageOffset, searchFilter, pageSize);

    return {
      data: projectLocationsData.data.map((location) => convertProjectLocationLimitedData(location)),
      nextPage: projectLocationsData.nextPage,
      totalCount: projectLocationsData.totalCount,
    };
  };

  const getAllProjectCategoriesConverted = async (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendProjectCategoryType>> => {
    const projectCategoriesData = await getAllProjectCategories(projectId, pageOffset, searchFilter, pageSize);
    return {
      data: projectCategoriesData.data.map((category) => convertCategoryData(category)),
      nextPage: projectCategoriesData.nextPage,
      totalCount: projectCategoriesData.totalCount,
    };
  };

  const getAllProjectHolidaysConverted = async (
    projectId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendProjectHolidayType>> => {
    const projectHolidaysData = await getAllProjectHolidays(projectId, pageOffset, searchFilter, pageSize);
    return {
      data: convertProjectHolidayData(projectHolidaysData.data),
      nextPage: projectHolidaysData.nextPage,
      totalCount: projectHolidaysData.totalCount,
    };
  };

  const getProjectPositionConverted = async (id: number, positionId: number, detailLevel?: DetailLevel) => {
    const projectPositionData = await getProjectPosition(id, positionId, detailLevel);
    return convertPositionData(projectPositionData);
  };

  const getProjectCategoryConverted = async (id: number, categoryId: number, detailLevel?: DetailLevel) => {
    const manpowerPositionCodeCategoryData = await getManpowerPositionCodeCategory(id, categoryId, detailLevel);
    return detailLevel == DetailLevel.LIMITED
      ? convertCategoryData(manpowerPositionCodeCategoryData)
      : convertCategoryData(manpowerPositionCodeCategoryData);
  };

  const getProjectTradeConverted = async (id: number, tradeId: number, detailLevel?: DetailLevel) => {
    const manpowerPositionCodeTradeData = await getManpowerPositionCodeTrade(id, tradeId, detailLevel);

    return detailLevel == DetailLevel.LIMITED ? convertTradeData(manpowerPositionCodeTradeData) : convertTradeData(manpowerPositionCodeTradeData);
  };

  const getProjectDisciplineConverted = async (id: number, disciplineId: number, detailLevel?: DetailLevel) => {
    const projectDisciplineData = await getProjectDiscipline(id, disciplineId, detailLevel);
    return convertProjectDisciplineData(projectDisciplineData);
  };

  const getProjectSubpositionsConverted = async (projectId: number, positionId: number) => {
    const projectSubpositionsData = await getAllProjectSubpositions(projectId, positionId);
    return convertSubPositionData(projectSubpositionsData);
  };

  const getProjectSubpositionConverted = async (projectId: number, positionId: number, subpositionId: number) => {
    const projectSubpositionData = await getProjectSubPosition(projectId, positionId, subpositionId);
    return convertSubPositionSingleData(projectSubpositionData);
  };
  const getProjectMissingInfoConverted = async (projectId: number) => {
    const response = await getProjectConfigurationsMissingInfo(projectId);
    return convertProjectMissingConfigurationInfo(response);
  };

  return {
    getProjectLocation: getProjectLocationConverted,
    getAllProjectCtrs: getAllProjectCtrsConverted,
    getProjectCtr: getProjectCtrConverted,
    getProjectPosition: getProjectPositionConverted,
    getProjectCategory: getProjectCategoryConverted,
    getProjectTrade: getProjectTradeConverted,
    getProjectDiscipline: getProjectDisciplineConverted,
    getProjectSubpositions: getProjectSubpositionsConverted,
    getAllProjectPositions: getAllProjectPositionsConverted,
    getAllProjectManpowerPositions: getAllProjectManpowerPositionsConverted,
    getAllProjectTrades: getAllProjectTradesConverted,
    getAllProjectLocations: getAllProjectLocationsConverted,
    getAllProjectDisciplines: getAllProjectDisciplinesConverted,
    getAllProjectCategories: getAllProjectCategoriesConverted,
    getAllProjectHolidays: getAllProjectHolidaysConverted,
    getProjectSubposition: getProjectSubpositionConverted,
    getProjectMissingInfo: getProjectMissingInfoConverted,
  };
};
