import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import GlobalConfigurationTitle from "./globalconfigurationtitle/GlobalConfigurationTitle.tsx";
import configCalendarImg from "../../../assets/images/configuration/configurationcalendar.svg";

import { GlobalConfigurationBackRouteType } from "../../../types/settings/GlobalConfigurationsBackRouteTypes.ts";
import ConfigurationMenuStyles from "../../projects/project/configurationmenu/ConfigurationMenu.module.css";
import ConfigurationMenuItem from "../../projects/project/configurationmenu/configurationmenuitem/ConfigurationMenuItem.tsx";
import { AppRoutesPaths } from "../../../types/AppRoutesTypes.ts";
import locationTypesImg from "../../../assets/images/configuration/location-types.svg";
import positionCategoriesImg from "../../../assets/images/configuration/position-categories.svg";
import positionTradesImg from "../../../assets/images/configuration/position-trades.svg";
import disciplinesImg from "../../../assets/images/configuration/disciplines.svg";
import positionsImg from "../../../assets/images/configuration/positions.svg";
import ctrManagementImg from "../../../assets/images/configuration/ctr-management.svg";
import { useRetrieveUserPermittedActions } from "../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../types/Roles.ts";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const GlobalConfigurations = () => {
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();
  const navigate = useNavigate();

  const canViewConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  // const canEditPermissions = canPerformGlobalAction(GlobalAction.EditPermissions);

  useEffect(() => {
    if (!canViewConfigurations) {
      navigate(`/${AppRoutesPaths.unauthorized}`);
    }
  }, [canViewConfigurations, navigate]);

  return (
    <div
      className={classNames(
        GlobalStyles.overflowAutoFullHeight,
        GlobalStyles.flex,
        GlobalStyles.flex1,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
      )}
    >
      <GlobalConfigurationTitle routeType={GlobalConfigurationBackRouteType.ExitGlobalConfigurations} />
      <div className={classNames(ConfigurationMenuStyles.descriptionText)}>
        Please, select from the below categories regarding to configurations that are available for all individual projects. You can also just view a
        setting category for advice or edit it.
      </div>
      <div
        className={classNames(
          ConfigurationMenuStyles.menuItemsSpaceHandler,
          GlobalStyles.overflowAutoFullHeight,
          GlobalStyles.flex,
          GlobalStyles.gap,
          GlobalStyles.flexWrap,
        )}
      >
        <ConfigurationMenuItem
          img={locationTypesImg}
          title={"Location Types"}
          text={"List all the different locations where employees may be based as per contract. Click to edit, add or delete locations."}
          to={AppRoutesPaths.configurationsLocations}
        />
        <ConfigurationMenuItem
          img={positionsImg}
          title={"Positions"}
          text={"View or edit positions and sub-positions. Define set of positions and sub-positions available for all projects."}
          to={AppRoutesPaths.configurationsPositions}
        />
        <ConfigurationMenuItem
          img={positionCategoriesImg}
          title={"Position Categories"}
          text={
            "Categories is a classification entity required to group data in the manpower plan. Configure in order to edit, add or delete categories."
          }
          to={AppRoutesPaths.configurationsCategories}
        />
        <ConfigurationMenuItem
          img={positionTradesImg}
          title={"Position Trades"}
          text={"Trades is a classification entity required to group data in the manpower plan. Configure in order to edit, add or delete Trades."}
          to={AppRoutesPaths.configurationsTrades}
        />
        <ConfigurationMenuItem
          img={disciplinesImg}
          title={"Disciplines"}
          text={
            "Disciplines is a classification entity required to group data in the manpower plan. Configure in order to edit, add or delete disciplines."
          }
          to={AppRoutesPaths.configurationsDisciplines}
        />
        <ConfigurationMenuItem
          img={ctrManagementImg}
          title={"CTR Library"}
          text={"Configure to track the activity of an employee against time. Create CTR library to be utilized while filling timesheets."}
          to={AppRoutesPaths.configurationsCtrLibrary}
        />
        <ConfigurationMenuItem
          img={configCalendarImg}
          title={"Holidays"}
          text={
            "Holidays is a classification entity required to create a calendar for the project. Configure in order to edit,add or delete Holidays."
          }
          to={AppRoutesPaths.configurationsHolidays}
        />
        {/*{canEditPermissions && (*/}
        {/*  <ConfigurationMenuItem*/}
        {/*    img={positionCategoriesImg}*/}
        {/*    title={"Roles and Permissions"}*/}
        {/*    text={"View or edit roles and configuration permissions for the global users"}*/}
        {/*    to={AppRoutesPaths.configurationsPermissions}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </div>
  );
};
