import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FrontendTimesheetGroupManagerialEntity, ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import ArrowLeftBlackLarger from "../../../../../assets/images/arrow-left-black-larger.svg";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";

import AddSectionHeadStyles from "./AddTimesheetGroupManagerialEntity.module.css";

import GroupUsersForManagerialRoleList from "../groupusersformanagerialrolelist/GroupUsersForManagerialRoleList.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import ConfirmAddManagerialEntityToGroupPopup from "../confirmaddmanagerialentitytogrouppopup/ConfirmAddManagerialEntityToGroupPopup.tsx";
import { Fade, Tooltip } from "@mui/material";
import { SearchBar } from "../../../../../ui/searchbar/SearchBar.tsx";
import SearchInputStyles from "../../../../../ui/searchbar/SearchInputStyles.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";

interface AddTimesheetGroupManagerialEntityProps {
  setPopupMode: Dispatch<SetStateAction<ProjectTimesheetGroupPopupModeEnum>>;
  projectId: number;
  groupId: number;
  managerialEntityType: FrontendTimesheetGroupManagerialEntity;
}

const AddTimesheetGroupManagerialEntity: FC<AddTimesheetGroupManagerialEntityProps> = ({
  setPopupMode,
  projectId,
  groupId,
  managerialEntityType,
}) => {
  const [searchMember, setSearchMember] = useState<string>("");
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const [newManagerialEntity, setNewManagerialEntity] = useState<string>(""); // Added state for the selected employee code
  const [newManagerialEntityName, setNewManagerialEntityName] = useState<string>(""); // Added state for the selected employee name
  const [isAddManagerialEntityButtonHovered, setIsAddManagerialEntityButtonHovered] = useState<boolean>(false);
  const { getTimesheetGroupSectionHead, getTimesheetGroupDepartmentManager } = useEpcmApiProjectsTimesheetsGroups();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const isAddManagerialEntityButtonDisabled = useMemo(() => {
    return !newManagerialEntity;
  }, [newManagerialEntity]);

  const isSectionHead = useMemo(() => {
    return managerialEntityType === FrontendTimesheetGroupManagerialEntity.sectionHead;
  }, [managerialEntityType]);

  const isDepartmentManager = useMemo(() => {
    return managerialEntityType === FrontendTimesheetGroupManagerialEntity.departmentManager;
  }, [managerialEntityType]);

  const sectionHeadQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId],
    queryFn: () => getTimesheetGroupSectionHead(projectId, groupId),
    enabled: isAuthorized && isSectionHead,
  });

  const sectionHeadData = useMemo(() => {
    return sectionHeadQuery.data;
  }, [sectionHeadQuery]);

  const departmentManagerQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupDepartmentManager, projectId, groupId],
    queryFn: () => getTimesheetGroupDepartmentManager(projectId, groupId),
    enabled: isAuthorized && isDepartmentManager,
  });

  const departmentManagerData = useMemo(() => {
    return departmentManagerQuery.data;
  }, [departmentManagerQuery]);

  useEffect(() => {
    return () => {
      if (isSectionHead) {
        void queryClient.cancelQueries({ queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId] });
      }
      if (isDepartmentManager) {
        void queryClient.cancelQueries({ queryKey: [QueryNames.TimesheetGroupDepartmentManager, projectId, groupId] });
      }
    };
  }, [projectId, groupId, queryClient, isSectionHead, isDepartmentManager]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
          onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.view)}
        >
          <img src={ArrowLeftBlackLarger} alt={"timesheet-group-settings-back-icon"} />
        </div>
        <div className={classNames(AddSectionHeadStyles.backText)}>{`
            Set ${isSectionHead ? "Section Head" : "Department Manager"}
            `}</div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
        <SearchBar
          searchBarContainerStyles={classNames(
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap05,
            SearchInputStyles.searchInputContainer,
          )}
          setSearchQuery={setSearchMember}
          placeholder={"Search for employee"}
        />
        <GroupUsersForManagerialRoleList
          projectId={projectId}
          newManagerialEntity={newManagerialEntity}
          setNewManagerialEntity={setNewManagerialEntity}
          setNewManagerialEntityName={setNewManagerialEntityName}
          searchMember={searchMember}
          existingManagerialEntity={isSectionHead ? sectionHeadData?.sectionHead?.code : departmentManagerData?.departmentManager?.code}
          managerialEntityType={managerialEntityType}
        />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          {isAddManagerialEntityButtonDisabled ? (
            <Tooltip title={"Select a user first"} TransitionComponent={Fade} placement={"top"} arrow>
              <div className={classNames(AddSectionHeadStyles.addSectionBtn, AddSectionHeadStyles.addSectionBtnDisabled)}>{`
              Set as ${isSectionHead ? "Section Head" : "Department Manager"}
              `}</div>
            </Tooltip>
          ) : (
            <div
              className={classNames(
                AddSectionHeadStyles.addSectionBtn,
                GlobalStyles.elementWithCursor,
                isAddManagerialEntityButtonHovered && AddSectionHeadStyles.addSectionBtnHovered,
              )}
              onMouseEnter={() => setIsAddManagerialEntityButtonHovered(true)}
              onMouseLeave={() => setIsAddManagerialEntityButtonHovered(false)}
              onClick={() => onOpenPopup(ProjectsPagePopups.confirmAddManagerialEntityToGroup, popupHandler)}
            >
              {`Set as ${isSectionHead ? "Section Head" : "Department Manager"}`}
            </div>
          )}
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div
            className={classNames(AddSectionHeadStyles.cancelBtn, GlobalStyles.elementWithCursor)}
            onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.view)}
          >
            Back
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.confirmAddManagerialEntityToGroup)!.isOpen && (
        <ConfirmAddManagerialEntityToGroupPopup
          newManagerialEntityName={newManagerialEntityName}
          setNewManagerialEntityName={setNewManagerialEntityName}
          setNewManagerialEntity={setNewManagerialEntity}
          managerialEntityCode={newManagerialEntity}
          timesheetGroupId={groupId}
          isOpen={popupHandler.get(ProjectsPagePopups.confirmAddManagerialEntityToGroup)!.isOpen}
          projectId={Number(projectId!)}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.confirmAddManagerialEntityToGroup, popupHandler);
            setPopupMode(ProjectTimesheetGroupPopupModeEnum.view);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.confirmAddManagerialEntityToGroup)}
          setPopupMode={setPopupMode}
          managerialEntityType={managerialEntityType}
        />
      )}
    </div>
  );
};

export default AddTimesheetGroupManagerialEntity;
