import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import GroupTimesheetsListItemStyles from "./GroupTimesheetsListItemStyles.module.css";
import TimesheetGroupListItemReviewedIcon from "../../../../../../../assets/images/timesheet-group-list-item-reviewed-icon.svg";
import TimesheetGroupListItemOrangeIcon from "../../../../../../../assets/images/timesheet-group-list-item-prepared-icon.svg";
import classNames from "classnames";
import DownloadIconBlue from "../../../../../../../assets/images/project_employee_download_icon.svg";
import DownloadMmrGreenIcon from "../../../../../../../assets/images/download-mmr-green-icon.svg";
import trashcanRedIcon from "../../../../../../../assets/images/trashcan-icon-red.svg";
import TimesheetListItemViewIcon from "../../../../../../../assets/images/timesheet-list-item-view-icon.svg";
import TimesheetEditPencilIcon from "../../../../../../../assets/images/pencil-black.svg";
import TimesheetEditGreenPencilIcon from "../../../../../../../assets/images/pencil-green.svg";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { capitalizeFirstLetter } from "../../../../../../../utils/StringManipulation.ts";

import {
  FrontendGroupsTimesheetModeEnum,
  FrontendTimesheetBasicType,
  FrontendTimesheetLimitedType,
  FrontendTimesheetStatusEnum,
} from "../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import arrowDownFilledBlue from "../../../../../../../assets/images/arrow-down-blue-fill.svg";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import {
  ProjectGroupTimesheetItemActionTypesEnum,
  ProjectTimesheetTabStatusEnum,
} from "../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import TimesheetGroupListItemPendingIcon from "../../../../../../../assets/images/timesheet-group-list-item-pending-icon.svg";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { formatDate } from "../../../../../../../utils/DateManipulation.ts";
import { AppRoutesPaths } from "../../../../../../../types/AppRoutesTypes.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../../use-projects-popups.ts";
import TimesheetListItemStyles from "../../../timesheetsperiods/periodtimesheetlist/periodtimesheetlistitem/TimesheetListItemStyles.module.css";
import ChangeEntityStatusPopup from "../../../../../popups/changeentitystatuspopup/ChangeEntityStatusPopup.tsx";
import { ChangeStatusPopupEntityType } from "../../../../../../../types/PopupChangeEntityStatusType.ts";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../../utils/use-popup-state.ts";
import { DeletePopup } from "../../../../../../../ui/deletepopup/DeletePopup.tsx";
import { LoaderPopup } from "../../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { ResponseAlertPopup } from "../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../../types/Roles.ts";
import { useEpcmApiProjectsTimesheets } from "../../../../../../../apicalls/projects/projecttimesheets/useEpcmApiProjectsTimesheets.ts";
import useProcessFileEvents from "../../../../../../../utils/useProcessFileEvents.ts";
import { FrontendFileAction } from "../../../../../../../types/projects/FileTypes.ts";
import { useNotificationContext } from "../../../../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import TimesheetStepButton from "../../timesheetstepbutton/TimesheetStepButton.tsx";
import { convertProjectTimesheetTabStatusEnumToFrontend } from "../../../../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/ProjectTimesheetsApiConverters.ts";

interface GroupTimesheetListItemProps {
  timesheetListStatus: ProjectTimesheetTabStatusEnum;
  groupTimesheetItem: FrontendTimesheetLimitedType;
  hideActions?: boolean;
  inList: boolean;
}

export const GroupTimesheetListItem: FC<GroupTimesheetListItemProps> = ({ timesheetListStatus, groupTimesheetItem, hideActions, inList }) => {
  const { projectId, groupId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { getTimesheetGroupTimesheet } = useEpcmApiProjectsTimesheetsGroups();
  const queryClient = useQueryClient();
  const { addNotification, updateNotification } = useNotificationContext();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTimesheetFileExported } = useEpcmApiProjectsTimesheets();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { processFileEvents: processFileEventsTimesheetFile } = useProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );

  const [actionButtonTypeHovered, setActionButtonTypeHovered] = useState<ProjectGroupTimesheetItemActionTypesEnum | null>(null);

  const groupTimesheetQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupId, groupTimesheetItem.id, inList, DetailLevel.BASIC],
    queryFn: () => getTimesheetGroupTimesheet(parseInt(projectId!), parseInt(groupId!), groupTimesheetItem.id, DetailLevel.BASIC),
    enabled: isAuthorized,
    select: (data) => data as FrontendTimesheetBasicType,
  });

  const groupTimesheetData = useMemo(() => {
    return groupTimesheetQuery.data ?? undefined;
  }, [groupTimesheetQuery]);

  const isReviewed = useMemo(() => {
    return timesheetListStatus === ProjectTimesheetTabStatusEnum.reviewed;
  }, [timesheetListStatus]);

  const isPending = useMemo(() => {
    return timesheetListStatus === ProjectTimesheetTabStatusEnum.pending;
  }, [timesheetListStatus]);

  const isPrepared = useMemo(() => {
    return timesheetListStatus === ProjectTimesheetTabStatusEnum.prepared;
  }, [timesheetListStatus]);

  const isChecked = useMemo(() => {
    return timesheetListStatus === ProjectTimesheetTabStatusEnum.checked;
  }, [timesheetListStatus]);

  const isApproved = useMemo(() => {
    return timesheetListStatus === ProjectTimesheetTabStatusEnum.approved;
  }, [timesheetListStatus]);

  const { useDeleteTimesheetGroupIdMutation } = useEpcmApiProjectTimesheetsGroupsMutations();

  const deleteTimesheetGroupIdMutation = useDeleteTimesheetGroupIdMutation(parseInt(projectId!), parseInt(groupId!), groupTimesheetItem.id, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Timesheet was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });
  const startFileEventsExportListener = async (fileId: string) => {
    await processFileEventsTimesheetFile(fileId);
  };

  const downloadTimesheetFile = useCallback(async () => {
    getTimesheetFileExported(parseInt(projectId!), parseInt(groupId!), groupTimesheetItem.id)
      .then((value) => {
        startFileEventsExportListener(value.fileId);
      })
      .catch((error) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      });
  }, [
    getTimesheetFileExported,
    projectId,
    groupId,
    groupTimesheetItem.id,
    startFileEventsExportListener,
    setResponseType,
    setResponseObject,
    onOpenResponseAlertPopup,
  ]);

  const onDeleteTimesheetRemoveParams = useCallback(() => {
    searchParams.delete("timesheetId");
    setSearchParams({ status: timesheetListStatus, mode: FrontendGroupsTimesheetModeEnum.VIEW });
  }, [setSearchParams, timesheetListStatus, searchParams]);

  const invalidateQueries = useCallback(() => {
    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroups, projectId, groupId],
    });
    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupId],
    });
  }, [queryClient, projectId, groupId]);

  const canChangeTimesheetStatus = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatus);
  const canDeleteTimesheet = canPerformProjectAction(ProjectAction.ProjectTimesheetListDelete);
  const canFillTimesheetsData = canPerformProjectAction(ProjectAction.ProjectTimesheetFillData);
  const canChangeAllTimesheetStatus = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeAllStatuses);
  const canChangeStatusFromPendingToPrepared = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
  const canChangeStatusFromPreparedToChecked = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToChecked);
  const canChangeStatusFromCheckedToReviewed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromCheckedToReviewed);
  const canChangeStatusFromReviewedToApproved = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToApproved);

  // const canChangeStatusBasedOnRole = useMemo(() => {
  //   if (canChangeAllTimesheetStatus) {
  //     return true;
  //   }
  //   if (canChangeTimesheetStatus && canChangeStatusFromPendingToPrepared && groupTimesheetItem.status === FrontendTimesheetStatusEnum.PENDING) {
  //     return true;
  //   }
  //   if (canChangeTimesheetStatus && canChangeStatusFromPreparedToChecked && groupTimesheetItem.status === FrontendTimesheetStatusEnum.PREPARED) {
  //     return true;
  //   }
  //   if (canChangeTimesheetStatus && canChangeStatusFromCheckedToReviewed && groupTimesheetItem.status === FrontendTimesheetStatusEnum.CHECKED) {
  //     return true;
  //   }
  //   if (canChangeTimesheetStatus && canChangeStatusFromReviewedToApproved && groupTimesheetItem.status === FrontendTimesheetStatusEnum.APPROVED) {
  //     return true;
  //   }
  //   if (canChangeTimesheetStatus && canChangeStatusFromApprovedToReviewed && groupTimesheetItem.status === FrontendTimesheetStatusEnum.APPROVED) {
  //     return true;
  //   }
  //
  //   return false;
  // }, [
  //   canChangeTimesheetStatus,
  //   canChangeStatusFromApprovedToReviewed,
  //   canChangeStatusFromCheckedToReviewed,
  //   canChangeStatusFromPreparedToChecked,
  //   canChangeStatusFromReviewedToApproved,
  //   canChangeStatusFromPendingToPrepared,
  //   canChangeAllTimesheetStatus,
  //   groupTimesheetItem.status,
  // ]);

  const canEditBasedOnRole = useMemo(() => {
    if (canChangeAllTimesheetStatus && FrontendTimesheetStatusEnum.APPROVED !== groupTimesheetItem.status) {
      return true;
    }
    if (groupTimesheetData?.status === FrontendTimesheetStatusEnum.PENDING && canChangeStatusFromPendingToPrepared) {
      return true;
    }
    if (groupTimesheetData?.status === FrontendTimesheetStatusEnum.PREPARED && canChangeStatusFromPreparedToChecked) {
      return true;
    }
    if (groupTimesheetData?.status === FrontendTimesheetStatusEnum.CHECKED && canChangeStatusFromCheckedToReviewed) {
      return true;
    }
    if (groupTimesheetData?.status === FrontendTimesheetStatusEnum.REVIEWED && canChangeStatusFromReviewedToApproved) {
      return true;
    }
    return false;
  }, [
    canFillTimesheetsData,
    groupTimesheetData,
    canChangeAllTimesheetStatus,
    canChangeStatusFromCheckedToReviewed,
    canChangeStatusFromPendingToPrepared,
    canChangeStatusFromPreparedToChecked,
    canChangeStatusFromReviewedToApproved,
  ]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({
          queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupId, groupTimesheetItem.id, inList, DetailLevel.BASIC],
        })
        .then(() =>
          console.log(
            `In timesheet group ${groupId}, timesheet with id ${groupTimesheetItem.id}, ${QueryNames.ProjectTimesheetGroupTimesheets} query canceled`,
          ),
        );
    };
  }, [queryClient, projectId, groupId, groupTimesheetItem, inList]);

  return (
    <div
      className={classNames(
        GroupTimesheetsListItemStyles.groupTimesheetListItemContainer,
        hideActions && GroupTimesheetsListItemStyles.groupTimesheetListItemWithNoActionsContainer,
        GlobalStyles.flex,
        GlobalStyles.flex1,
        GlobalStyles.gap025,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap, GlobalStyles.centerHorizontal)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img
              className={classNames(GroupTimesheetsListItemStyles.submittedTimesheetListItemDocIconImg)}
              src={
                isApproved
                  ? TimesheetGroupListItemReviewedIcon
                  : isPrepared || isChecked || isReviewed
                    ? TimesheetGroupListItemOrangeIcon
                    : isPending
                      ? TimesheetGroupListItemPendingIcon
                      : undefined
              }
              alt="reviewed-timesheet-list-item-icon"
            />
          </div>
        </div>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.centerVertical,
            groupTimesheetQuery.isLoading && GlobalStyles.gap05,
          )}
        >
          <div className={classNames()}>{"Period"}</div>
          {groupTimesheetQuery.isLoading && <Skeleton variant={"rounded"} height={16} width={200} />}
          {groupTimesheetData && (
            <div className={classNames(GroupTimesheetsListItemStyles.detailsText)}>
              {formatDate(groupTimesheetData.periodStart)} - {formatDate(groupTimesheetData.periodEnd)}
            </div>
          )}
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex4, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex3, GlobalStyles.gap, GlobalStyles.centerHorizontal)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
            <div className={classNames()}>{"Status"}</div>
            <div
              className={classNames(
                GroupTimesheetsListItemStyles.detailsText,
                isApproved
                  ? GlobalStyles.approvedStatusColor
                  : isPrepared || isChecked || isReviewed
                    ? GlobalStyles.preparedStatusColor
                    : isPending && GlobalStyles.pendingStatusColor,
              )}
            >
              {capitalizeFirstLetter(groupTimesheetItem.status)}
            </div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          {inList && canChangeTimesheetStatus && canChangeAllTimesheetStatus && !isApproved ? (
            <div
              className={classNames(
                GroupTimesheetsListItemStyles.linkBlueBoldText,
                GlobalStyles.flex,
                GlobalStyles.gap05,
                GlobalStyles.elementWithCursor,
              )}
              onClick={() => onOpenPopup(ProjectsPagePopups.changeEntityStatus, popupHandler)}
            >
              <div className={classNames(GlobalStyles.centerVertical)}>{"Change"}</div>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(GroupTimesheetsListItemStyles.arrowDownBlueIconImg)} src={arrowDownFilledBlue} alt="arrowDown" />
              </div>
            </div>
          ) : (
            inList &&
            groupTimesheetData &&
            canChangeTimesheetStatus && (
              <TimesheetStepButton
                timesheetListStatus={convertProjectTimesheetTabStatusEnumToFrontend(timesheetListStatus)}
                groupTimesheetItem={groupTimesheetItem}
                timesheetId={groupTimesheetData.id}
              />
            )
          )}
        </div>
        {isPending && (
          <div
            className={classNames(
              GroupTimesheetsListItemStyles.deadlineContainer,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.centerVertical,
            )}
          >
            <div className={classNames()}>{"Deadline By"}</div>
            {groupTimesheetQuery.isLoading && <Skeleton variant={"rounded"} height={16} width={100} />}
            {groupTimesheetData && (
              <div className={classNames(GroupTimesheetsListItemStyles.detailsText)}>{formatDate(groupTimesheetData.deadline)}</div>
            )}
          </div>
        )}
        <div className={classNames(GlobalStyles.flex4)} />
        {!hideActions && isReviewed ? (
          <></>
        ) : (
          isPending &&
          (groupTimesheetQuery.isLoading ? (
            <div className={classNames(GlobalStyles.centerVertical)}>
              <Skeleton variant={"rounded"} height={18} width={120} />
            </div>
          ) : (
            groupTimesheetData &&
            canDeleteTimesheet && (
              <div
                className={classNames(
                  GroupTimesheetsListItemStyles.deleteActionText,
                  GlobalStyles.flex,

                  GlobalStyles.gap,
                  GlobalStyles.elementWithCursor,
                )}
                onClick={() => {
                  onOpenUtilPopup();
                }}
              >
                <div className={classNames(GlobalStyles.centerVertical)}>{"Delete"}</div>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img className={classNames(GroupTimesheetsListItemStyles.deleteIconImg)} src={trashcanRedIcon} alt="delete" />
                </div>
              </div>
            )
          ))
        )}
      </div>
      {!hideActions && (
        <div
          className={classNames(
            GroupTimesheetsListItemStyles.submittedTimesheetListItemLastSection,
            GlobalStyles.flex,
            GlobalStyles.gap2,
            GlobalStyles.centerHorizontal,
          )}
        >
          <div
            className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
            onMouseEnter={() => setActionButtonTypeHovered(ProjectGroupTimesheetItemActionTypesEnum.download)}
            onMouseLeave={() => setActionButtonTypeHovered(null)}
            onClick={() => downloadTimesheetFile()}
          >
            <Tooltip TransitionComponent={Fade} title={"Download Timesheet"} placement="top" arrow>
              <img
                className={classNames(GroupTimesheetsListItemStyles.utilIconImg)}
                src={actionButtonTypeHovered === ProjectGroupTimesheetItemActionTypesEnum.download ? DownloadMmrGreenIcon : DownloadIconBlue}
                alt="Download"
              />
            </Tooltip>
          </div>

          {canEditBasedOnRole ? (
            isPending ? (
              <Link
                to={`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}/${groupId}?status=${timesheetListStatus}&mode=${FrontendGroupsTimesheetModeEnum.EDIT}&timesheetId=${groupTimesheetItem.id}`}
                className={classNames(
                  TimesheetListItemStyles.linkBlueText,
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.gap05,
                  GlobalStyles.elementWithCursor,
                )}
              >
                Fill In
              </Link>
            ) : (
              <Link
                to={`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}/${groupId}?status=${timesheetListStatus}&mode=${FrontendGroupsTimesheetModeEnum.EDIT}&timesheetId=${groupTimesheetItem.id}`}
                className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
              >
                <Tooltip
                  TransitionComponent={Fade}
                  title={"Edit Timesheet"}
                  placement="top"
                  arrow
                  onMouseEnter={() => setActionButtonTypeHovered(ProjectGroupTimesheetItemActionTypesEnum.edit)}
                  onMouseLeave={() => setActionButtonTypeHovered(null)}
                >
                  <div className={classNames(TimesheetListItemStyles.editIconImageContainer, GlobalStyles.centerVertical)}>
                    <img
                      className={classNames(TimesheetListItemStyles.utilIconImg)}
                      src={
                        actionButtonTypeHovered === ProjectGroupTimesheetItemActionTypesEnum.edit
                          ? TimesheetEditGreenPencilIcon
                          : TimesheetEditPencilIcon
                      }
                      alt={"view-timesheet-icon"}
                    />
                  </div>
                </Tooltip>
              </Link>
            )
          ) : (
            <Link
              to={`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}/${groupId}?status=${timesheetListStatus}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}&timesheetId=${groupTimesheetItem.id}`}
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
            >
              <Tooltip
                TransitionComponent={Fade}
                title={"View Timesheet"}
                placement="top"
                arrow
                onMouseEnter={() => setActionButtonTypeHovered(ProjectGroupTimesheetItemActionTypesEnum.edit)}
                onMouseLeave={() => setActionButtonTypeHovered(null)}
              >
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img className={classNames(TimesheetListItemStyles.utilIconImg)} src={TimesheetListItemViewIcon} alt={"view-timesheet-icon"} />
                </div>
              </Tooltip>
            </Link>
          )}
          {/*<div className={classNames(GlobalStyles.centerVertical)}>*/}
          {/*  <Tooltip*/}
          {/*    TransitionComponent={Fade}*/}
          {/*    title={"Clone Timesheet is not implemented"}*/}
          {/*    placement="top"*/}
          {/*    arrow*/}
          {/*    onMouseEnter={() => setActionButtonTypeHovered(ProjectGroupTimesheetItemActionTypesEnum.clone)}*/}
          {/*    onMouseLeave={() => setActionButtonTypeHovered(null)}*/}
          {/*  >*/}
          {/*    <img className={classNames(GroupTimesheetsListItemStyles.utilIconImg)} src={CloneTimesheetIcon} alt="Download" />*/}
          {/*  </Tooltip>*/}
          {/*</div>*/}
        </div>
      )}
      {popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen && groupTimesheetData && (
        <ChangeEntityStatusPopup
          isOpen={popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.changeEntityStatus, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.changeEntityStatus)}
          secondaryHeaderText={`Timesheet: ${groupTimesheetData.id}`}
          //currentStatus={groupTimesheetData.status}
          entityInfo={{
            type: ChangeStatusPopupEntityType.TIMESHEET,
            projectId: parseInt(projectId!),
            timesheetGroupId: parseInt(groupId!),
            timesheetId: groupTimesheetData.id,
          }}
        />
      )}
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteTimesheetGroupIdMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              onDeleteTimesheetRemoveParams();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </div>
  );
};
