import { FrontendProjectEmployeeLimited } from "./ProjectEmployeesApiTypes.ts";
import { FrontendCtrBasicType } from "./CtrTypes.ts";
import { FrontendCalendarDayRecords, FrontendCalendarMMRType } from "./CalendarApiTypes.ts";
import { FrontendUserLimitedType } from "./UsersApiTypes.ts";

export enum FrontendCalendarProjectStatusEnum {
  VALID = "valid",
  BEFORE_PROJECT_START = "before project start",
  AFTER_PROJECT_END = "after project end",
}

export interface FrontendTimesheetGroupBasicType {
  id: number;
  name: string;
  sectionHeadCode: string | null;
  departmentManagerCode: string | null;
}

export interface FrontendTimesheetGroupLimitedType extends Pick<FrontendTimesheetGroupBasicType, "id" | "name"> {}

export interface FrontendTimesheetGroupBasicWithHasPendingType extends FrontendTimesheetGroupBasicType {
  hasPending: boolean;
}

export interface FrontendTimesheetGroupCanBeDeletedType {
  canBeDeleted: boolean;
}

export interface FrontendTimesheetGroupNormalType extends FrontendTimesheetGroupBasicType {
  projectEmployees: FrontendProjectEmployeeLimited[];
}

export interface FrontendTimesheetGroupLimitedType extends Pick<FrontendTimesheetGroupBasicWithHasPendingType, "id" | "name"> {}

export interface FrontendTimesheetGroupNotFinal extends FrontendTimesheetGroupLimitedType {
  message: string;
}

export interface FrontendTimesheetGroupMemberType {
  id: number;
  name: string;
  code: string;
}

export interface FrontendSectionHeadType {
  sectionHead: FrontendUserLimitedType | null;
}

export interface FrontendTimesheetGroupDepartmentManagerType {
  departmentManager: FrontendUserLimitedType | null;
}

export enum FrontendTimesheetStatusEnum {
  PENDING = "pending",
  PREPARED = "prepared",
  CHECKED = "checked",
  REVIEWED = "reviewed",
  APPROVED = "approved",
}

export interface FrontendTimesheetPeriodType {
  periodStart: Date;
  periodEnd: Date;
}

export interface FrontendTimesheetCreationDates {
  periods: FrontendTimesheetPeriodType[];
}

export interface FrontendTimesheetActorType {
  user: FrontendUserLimitedType;
  actionDate: Date;
}

export interface FrontendTimesheetBasicType {
  id: number;
  status: FrontendTimesheetStatusEnum;
  periodStart: Date;
  periodEnd: Date;
  deadline: Date;
}

export interface FrontendTimesheetLimitedType extends Pick<FrontendTimesheetBasicType, "id" | "status"> {
  timesheetGroupId: number;
}

export interface FrontendTimesheetType extends FrontendTimesheetBasicType {
  timesheetGroup: FrontendTimesheetGroupLimitedType;
  reviewer: FrontendTimesheetActorType | null;
  submitter: FrontendTimesheetActorType | null;
  checker: FrontendTimesheetActorType | null;
  approver: FrontendTimesheetActorType | null;
  isValid: boolean;
  editDate: Date;
  editor: FrontendUserLimitedType;
}

export interface FrontendTimesheetEmployeeLimitedType {
  timesheetEmployeeId: number;
  projectEmployeeId: number;
  employeeId: number;
}

export interface FrontendTimesheetProjectEmployeeActiveMMRType {
  mmrId: number;
  calendars: FrontendCalendarMMRType[];
}

export enum FrontendGroupsTimesheetModeEnum {
  VIEW = "view",
  EDIT = "edit",
}

export interface FrontendTimesheetFormHeaderDateType {
  id: number;
  date: Date;
  dayName: string;
  calendarProjectStatus: FrontendCalendarProjectStatusEnum;
}

export interface FrontendTimesheetRecordBodyType {
  ctr: FrontendCtrBasicType;
  workedHours: number;
}

export interface FrontendTimesheetRecordType {
  maxHours: number;
  locationWorkingHours: number;
  overtimeHours: number;
  isApplicable: boolean;
  isWorkingDay?: boolean;
}

export interface FrontendTimesheetCtrRecordType {
  records: FrontendCalendarDayRecords[];
}
