import { FC, useEffect, useMemo, useState } from "react";
import TrashCanDarkIcon from "../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIconHover from "../../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import EditIcon from "../../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../../assets/images/trashcan-icon-red.svg";

import ConfigurationHolidaysRowStyles from "./ConfigurationHolidaysRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import { useQueryClient } from "@tanstack/react-query";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";

import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";
import { formatDate, formatDateWithoutYear } from "../../../../../../utils/DateManipulation.ts";
import { FrontendProjectHolidayType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { ProjectsPagePopups } from "../../../../use-projects-popups.ts";
import { useProjectsPopups } from "../../../../use-projects-popups.ts";
import ConfigurationHolidaysPopup from "../../popups/configurationholidayspopup/ConfigurationHolidaysPopup.tsx";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useParams } from "react-router-dom";

interface ProjectConfigurationHolidayRowProps {
  projectHolidayItem: FrontendProjectHolidayType;
}

const ProjectConfigurationHolidaysRow: FC<ProjectConfigurationHolidayRowProps> = ({ projectHolidayItem }) => {
  const { projectId } = useParams();
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { useDeleteProjectConfigurationEntityMutation } = useEpcmApiProjectUtilsMutations();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleteGlobalHolidayMutation = useDeleteProjectConfigurationEntityMutation(
    Number(projectId!),
    projectHolidayItem.id,
    FrontendProjectConfigurationsEntityEnum.Holiday,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Global Holiday was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const invalidateQueries = () => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectHolidays] });
  };

  const canListConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canEditConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);
  const canDeleteConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigDelete);

  const isRecurring = useMemo(() => projectHolidayItem.isRecurring, [projectHolidayItem.isRecurring]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectHolidays, projectHolidayItem.id] }).then(() => {
        console.log(
          `In project-configuration-holiday-row component, ${QueryNames.ProjectHolidays} query for position ${projectHolidayItem.id} cancelled`,
        );
      });
    };
  }, [queryClient, projectHolidayItem.id]);

  return (
    <>
      <tr
        key={projectHolidayItem.id}
        className={classNames(
          ConfigurationHolidaysRowStyles.itemContainer,
          isRowHovered && ConfigurationHolidaysRowStyles.rowHoverEffect,
          ConfigurationHolidaysRowStyles.Trow,
        )}
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
      >
        {projectHolidayItem && canListConfigurations ? (
          <>
            <td className={classNames(ConfigurationHolidaysRowStyles.tableCell, ConfigurationHolidaysRowStyles.tD)}>
              {projectHolidayItem.description}
            </td>
            <td className={classNames(ConfigurationHolidaysRowStyles.tableCell, ConfigurationHolidaysRowStyles.tD)}>
              {isRecurring ? formatDateWithoutYear(projectHolidayItem.startDate) : formatDate(projectHolidayItem.startDate)}
            </td>
            <td className={classNames(ConfigurationHolidaysRowStyles.tableCell, ConfigurationHolidaysRowStyles.tD)}>
              {projectHolidayItem.endDate
                ? isRecurring
                  ? formatDateWithoutYear(projectHolidayItem.endDate)
                  : formatDate(projectHolidayItem.endDate)
                : "-"}
            </td>
            <td className={classNames(ConfigurationHolidaysRowStyles.tableCell, ConfigurationHolidaysRowStyles.tD)}>
              {projectHolidayItem.duration > 1 ? `${projectHolidayItem.duration} Days` : ` ${projectHolidayItem.duration}  Day`}
            </td>
            <td className={classNames(ConfigurationHolidaysRowStyles.tableCell, ConfigurationHolidaysRowStyles.tD)}>
              {projectHolidayItem.isRecurring ? "Yes" : "No"}
            </td>
            <td className={classNames(ConfigurationHolidaysRowStyles.tableCell, ConfigurationHolidaysRowStyles.tD)}>
              {projectHolidayItem.setAsDefault ? "Yes" : "No"}
            </td>
            <td className={classNames(ConfigurationHolidaysRowStyles.tableCellActions, ConfigurationHolidaysRowStyles.tD)}>
              <div
                className={classNames(
                  ConfigurationHolidaysRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfigurations && (
                  <div
                    className={classNames(ConfigurationHolidaysRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(ProjectsPagePopups.updateProjectHoliday, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>

      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteGlobalHolidayMutation.mutate();

            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(ProjectsPagePopups.updateProjectHoliday)!.isOpen && (
        <ConfigurationHolidaysPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectHoliday, popupHandler)}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectHoliday)!.isOpen}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectHoliday)}
          projectHolidayItem={projectHolidayItem}
        />
      )}
      {/*{popupHandler.get(ProjectsPagePopups.updateGlobalPosition)!.isOpen && (*/}
      {/*  <GlobalConfigurationPositionsPopup*/}
      {/*    mode={configurationsPopupType.edit}*/}
      {/*    closeFn={() => onClosePopup(ProjectsPagePopups.updateGlobalPosition, popupHandler)}*/}
      {/*    isOpen={popupHandler.get(ProjectsPagePopups.updateGlobalPosition)!.isOpen}*/}
      {/*    headerText={popupHeaders.get(ProjectsPagePopups.updateGlobalPosition)}*/}
      {/*    globalPositionItem={globalHolidayItem}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default ProjectConfigurationHolidaysRow;
