import { FrontendCtrMappingRow } from "../../../types/apicallstypes/CtrMappingTypes.ts";
import { getProjectCtrMapping } from "./getProjectCtrMapping.ts";
import { convertCtrMappingToFrontendCtrMappingRow } from "../../../types/apicallstypes/apicallstypesconverters/CtrMappingApiConverter.ts";
import { FrontendFileRetrievalResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";
import { getProjectCtrMappingFile } from "./getProjectCtrMappingExportedFile.ts";

interface ApiDefinition {
  getProjectCtrMapping: (projectId: number) => Promise<FrontendCtrMappingRow[]>;
  getProjectCtrMappingExportedFile: (id: number) => Promise<FrontendFileRetrievalResponseType>;
}

export const useEpcmApiProjectCtrMapping = (): ApiDefinition => {
  const getProjectCtrMappingConverted = async (projectId: number): Promise<FrontendCtrMappingRow[]> => {
    const ctrMappingData = await getProjectCtrMapping(projectId);

    return convertCtrMappingToFrontendCtrMappingRow(ctrMappingData);
  };
  const getProjectCtrMappingExportedFile = async (id: number): Promise<FrontendFileRetrievalResponseType> => {
    return await getProjectCtrMappingFile(id);
  };

  return {
    getProjectCtrMapping: getProjectCtrMappingConverted,
    getProjectCtrMappingExportedFile: getProjectCtrMappingExportedFile,
  };
};
