import { FrontendTimesheetType } from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { FC } from "react";
import TimesheetFlowActor from "./timesheetflowactor/TimesheetFlowActor.tsx";

interface TimesheetActorInfoProps {
  isTimesheetQueryLoading: boolean;
  timesheetData: FrontendTimesheetType | undefined;
}

const TimesheetFlowActorActions: FC<TimesheetActorInfoProps> = ({ timesheetData, isTimesheetQueryLoading }) => {
  return (
    <>
      <TimesheetFlowActor label={"Prepared By"} actorData={timesheetData?.submitter} isTimesheetQueryLoading={isTimesheetQueryLoading} />
      <TimesheetFlowActor label={"Checked By"} actorData={timesheetData?.checker} isTimesheetQueryLoading={isTimesheetQueryLoading} />
      <TimesheetFlowActor label={"Reviewed By"} actorData={timesheetData?.reviewer} isTimesheetQueryLoading={isTimesheetQueryLoading} />
      <TimesheetFlowActor label={"Approved By"} actorData={timesheetData?.approver} isTimesheetQueryLoading={isTimesheetQueryLoading} />
    </>
  );
};
export default TimesheetFlowActorActions;
