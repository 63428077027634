import {
  convertBackendFileTransactionTypeToFrontend,
  FileTransactionType,
  FileTypeOptions,
  FileUploadStatus,
  FrontendFileType,
  SseRequestExtended,
} from "../../../../projects/FileTypes.ts";
import { SseStatus, SseTypeOptions } from "epcm-common/dist/Types/SseTypes";

export const convertProjectFileData = (projectFileData: SseRequestExtended[]): FrontendFileType[] => {
  return projectFileData.map((projectFile) => {
    return {
      ...projectFile,
      eventId: projectFile.id,
      status: convertBackendSseStatusToFrontend(projectFile.status),
      type: convertBackendFileTypeOptionsToFrontend(projectFile.type),
      creationDateTimestamp: new Date(projectFile.creationDateTimestamp),
      creationTime: new Date(projectFile.creationDateTimestamp).toLocaleTimeString(),
      errorDetails: projectFile.errorDetails,
      fileName: projectFile.fileName,
      id: projectFile.id,
      transactionType: convertBackendFileTransactionTypeToFrontend(projectFile.transactionType)
        ? convertBackendFileTransactionTypeToFrontend(projectFile.transactionType)!
        : (FileTransactionType.imported as FileTransactionType),
    };
  });
};

export const convertBackendSseStatusToFrontend = (status: SseStatus): FileUploadStatus => {
  const statusMap: Map<SseStatus, FileUploadStatus> = new Map([
    [SseStatus.IN_PROGRESS, FileUploadStatus.inProgress],
    [SseStatus.COMPLETED, FileUploadStatus.completed],
    [SseStatus.FAILED, FileUploadStatus.failed],
  ]);
  return statusMap.get(status)!;
};
export const convertBackendFileTypeOptionsToFrontend = (type: SseTypeOptions): FileTypeOptions => {
  const typeMap: Map<SseTypeOptions, FileTypeOptions> = new Map([
    [SseTypeOptions.MANPOWER, FileTypeOptions.MANPOWER],
    [SseTypeOptions.OVERTIME, FileTypeOptions.OVERTIME],
    [SseTypeOptions.TIMESHEET, FileTypeOptions.TIMESHEET],
    [SseTypeOptions.MMR, FileTypeOptions.MMR],
    [SseTypeOptions.MMR_REGISTER, FileTypeOptions.MMR_REGISTER],
    [SseTypeOptions.MMR_BULK, FileTypeOptions.MMR_BULK],
    [SseTypeOptions.REPORTS, FileTypeOptions.REPORTS],
    [SseTypeOptions.CTR_MAPPING, FileTypeOptions.CTR_MAPPING],
  ]);
  return typeMap.get(type)!;
};
