import { FrontendTimesheetStatusEnum } from "../types/apicallstypes/ProjectTimesheetsApiTypes.ts";

export const useFindNextTimesheetStatusStep = () => {
  const findNextTimesheetStatusStep = (currentState: FrontendTimesheetStatusEnum): FrontendTimesheetStatusEnum | undefined => {
    let nextTimesheetState: FrontendTimesheetStatusEnum | undefined = undefined;

    if (currentState === FrontendTimesheetStatusEnum.PENDING) {
      nextTimesheetState = FrontendTimesheetStatusEnum.PREPARED;
    }
    if (currentState === FrontendTimesheetStatusEnum.PREPARED) {
      nextTimesheetState = FrontendTimesheetStatusEnum.CHECKED;
    }
    if (currentState === FrontendTimesheetStatusEnum.CHECKED) {
      nextTimesheetState = FrontendTimesheetStatusEnum.REVIEWED;
    }
    if (currentState === FrontendTimesheetStatusEnum.REVIEWED) {
      nextTimesheetState = FrontendTimesheetStatusEnum.APPROVED;
    }

    return nextTimesheetState;
  };
  return { findNextTimesheetStatusStep };
};
