import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import EditUserRolePopupStyles from "./EditUserRolePopup.module.css";
import { FrontendRoleNormal } from "../../../../types/apicallstypes/UsersApiTypes.ts";
import UserRolesList from "./userroleslist/UserRolesList.tsx";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { FrontendGlobalUserTypeWithRoleIds, FrontendProjectUserTypeWithRoleIds } from "../../../../types/projects/PermissionTypes.ts";
import { useEpcmApiProjectPermissionsMutation } from "../../../../apicalls/projects/projectpermissions/mutations/useEpcmApiProjectPermissionsMutation.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { useEpcmApiUserPermissionsMutation } from "../../../../apicalls/configurations/permissions/mutations/useEpcmApiUserPermissionMutation.ts";
import { ConfigurationScopeEnum } from "../../../../types/projects/ConfigurationTypes.ts";
import { useParams } from "react-router-dom";

interface EditUserRolePopupProps extends PopupType {
  existingRolesIds: number[];
  rolesList: FrontendRoleNormal[];
  userItem: FrontendProjectUserTypeWithRoleIds | FrontendGlobalUserTypeWithRoleIds;
  scope: ConfigurationScopeEnum;
}

const EditUserRolePopup: FC<EditUserRolePopupProps> = ({
  existingRolesIds,
  rolesList,
  userItem,
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  scope,
}) => {
  const [locallySelectedRoles, setLocallySelectedRoles] = useState<number[]>(existingRolesIds);
  const { useUpdateProjectPermissionMutation } = useEpcmApiProjectPermissionsMutation();
  const { useUpdateGlobalPermissionMutation } = useEpcmApiUserPermissionsMutation();
  const queryClient = useQueryClient();
  const { projectId } = useParams();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  // const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  // const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const updateProjectPermissionMutation = useUpdateProjectPermissionMutation(parseInt(projectId!), {
    onSuccessCallback: (data) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `User project permissions were updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const specialRolesIds = useMemo(() => {
    return rolesList.filter((role) => !role.isEligible).map((role) => role.id);
  }, [rolesList]);

  const updateGlobalPermissionMutation = useUpdateGlobalPermissionMutation({
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `User global permissions were updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onUpdateGlobalUserRole = useCallback(() => {
    updateGlobalPermissionMutation.mutate({
      userGlobalRoles: [
        {
          userCode: userItem.code,
          roleIds: locallySelectedRoles,
        },
      ],
    });
    onOpenLoaderPopup();
  }, [locallySelectedRoles, updateGlobalPermissionMutation]);

  const onUpdateProjectUserRole = useCallback(() => {
    updateProjectPermissionMutation.mutate({
      userProjectRoles: [
        {
          userCode: userItem.code,
          roleIds: locallySelectedRoles.filter((role) => !specialRolesIds.includes(role)),
        },
      ],
    });
    onOpenLoaderPopup();
  }, [locallySelectedRoles, updateProjectPermissionMutation]);

  const isScopeProject = useMemo(() => scope === ConfigurationScopeEnum.PROJECT, [scope]);

  const hasNoNewRoles = useMemo(() => locallySelectedRoles === existingRolesIds, [locallySelectedRoles]);
  const invalidateQueries = useCallback(() => {
    if (isScopeProject) {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectConfigurationsPermissions, parseInt(projectId!)] });
      void queryClient.invalidateQueries({ queryKey: [QueryNames.UserProjectRoles, projectId] });
    } else {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalPermissions] });
      void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalRoles] });
    }
  }, [queryClient, projectId, isScopeProject]);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Save Changes",
          buttonType: PopupButtonTypeEnum.main,
          action: isScopeProject ? onUpdateProjectUserRole : onUpdateGlobalUserRole,
          disabled: hasNoNewRoles,
          tooltipText: hasNoNewRoles ? "No changes detected" : "",
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div className={classNames(EditUserRolePopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.gap075)}>
        <UserRolesList
          rolesList={rolesList}
          locallySelectedRoles={locallySelectedRoles}
          setLocallySelectedRoles={setLocallySelectedRoles}
          isScopeProject={isScopeProject}
        ></UserRolesList>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};
export default EditUserRolePopup;
