import React, { useCallback, useEffect, useMemo, useState } from "react";
import TimesheetFormStyles from "./TimesheetFormStyles.module.css";
import {
  FrontendGroupsTimesheetModeEnum,
  FrontendTimesheetLimitedType,
  FrontendTimesheetStatusEnum,
} from "../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetFormHeaders from "./timesheetformheaders/TimesheetFormHeaders.tsx";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../../../apicalls/config.ts";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import TimesheetFormEmployeeListItem from "./timesheetformemployeelistitem/TimesheetFormEmployeeListItem.tsx";
import {
  FrontendBodyTimesheetRecordSave,
  FrontendMmrCtrsBody,
  FrontendTimesheetEmployeeBodyType,
} from "../../../../../../../types/apicallstypes/CtrTypes.ts";
import { useInView } from "react-intersection-observer";
import InfiniteScrollInViewElement from "../../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../../utils/use-popup-state.ts";
import { ResponseAlertPopup } from "../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  ActionButtonTooltipTextEnum,
  ProjectTimesheetTabStatusEnum,
  TimesheetFormSubmitActionEnum,
  TimesheetRevertActionEnum,
  WarningMessagesEnum,
} from "../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { AppRoutesPaths } from "../../../../../../../types/AppRoutesTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../../types/Roles.ts";
import { useHandleUnauthorized } from "../../../../../../../utils/use-handle-unauthorized.ts";
import { PopupButtonTypeEnum } from "../../../../../../../types/PopupType.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { capitalizeFirstLetter } from "../../../../../../../utils/StringManipulation.ts";
import { convertProjectTimesheetTabStatusEnumToFrontend } from "../../../../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/ProjectTimesheetsApiConverters.ts";
import { useFindNextTimesheetStatusStep } from "../../../../../../../utils/findNextTimesheetStatusStep.ts";

interface TimesheetGridProps {
  groupTimesheet: FrontendTimesheetLimitedType;
  timesheetStatus: FrontendTimesheetStatusEnum;
  backToListLink: string;
}

const TimesheetForm: React.FC<TimesheetGridProps> = ({ groupTimesheet, timesheetStatus, backToListLink }) => {
  const { projectId, groupId } = useParams();
  const { findNextTimesheetStatusStep } = useFindNextTimesheetStatusStep();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllGroupTimesheetEmployees, getGroupTimesheetDates } = useEpcmApiProjectsTimesheetsGroups();
  const { useUpdateTimesheetMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();
  const [clickedSubmitAction, setClickedSubmitAction] = useState<TimesheetFormSubmitActionEnum | null>(null);

  const queryClient = useQueryClient();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const {
    isResponseAlertPopupOpen: isStatusChangeConfirmationPopupOpen,
    onOpenResponseAlertPopup: onOpenStatusChangeConfirmationPopup,
    onCloseResponseAlertPopup: onCloseStatusChangeConfirmationPopup,
  } = useResponseAlertPopupStateType();

  const {
    isResponseAlertPopupOpen: isWarningAlertPopupOpenModified,
    onOpenResponseAlertPopup: onOpenWarningAlertPopupModified,
    onCloseResponseAlertPopup: onCloseWarningAlertPopupModified,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { ref, inView } = useInView();
  const [redirectOnClose, setRedirectOnClose] = useState<boolean>(false);
  const [submitAction, setSubmitAction] = useState<TimesheetFormSubmitActionEnum | null>(null);
  const [buttonTypeClicked, setButtonTypeClicked] = useState<TimesheetRevertActionEnum | null>(null);

  const [timesheetMutationData, setTimesheetMutationData] = useState<FrontendTimesheetEmployeeBodyType[]>([]);
  const [hasAnyInvalidWorkedHourSum, setHasAnyInvalidWorkedHourSum] = useState<boolean>(false);
  const [hasLessThanMaxHoursRecord, setHasLessThanMaxHoursRecord] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const displayModeSearchQuery = (searchParams.get("mode") as FrontendGroupsTimesheetModeEnum) || FrontendGroupsTimesheetModeEnum.VIEW;
  const statusSearchQuery = (searchParams.get("status") as ProjectTimesheetTabStatusEnum) || "";

  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);
  const canFillTimesheet = canPerformProjectAction(ProjectAction.ProjectTimesheetFillData);
  const canChangeTimesheetStatus = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatus);
  const canChangeAllTimesheetsStatuses = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeAllStatuses);
  const canChangeFromPendingToPrepared = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
  const canChangeFromPreparedToChecked = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToChecked);
  const canChangeFromCheckedToReviewed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromCheckedToReviewed);
  const canChangeFromReviewedtoChecked = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToChecked);
  const canChangeFromReviewedToApproved = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToApproved);
  const canChangeFromApprovedToReviewed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromApprovedToReviewed);

  const isEditMode = useMemo(() => displayModeSearchQuery === FrontendGroupsTimesheetModeEnum.EDIT, [displayModeSearchQuery]);

  const groupTimesheetEmployeesQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupTimesheetEmployees, projectId, groupId, groupTimesheet.id],
    queryFn: ({ pageParam }) =>
      getAllGroupTimesheetEmployees(parseInt(projectId!), parseInt(groupId!), groupTimesheet.id, PAGINATION_PAGE_SIZE, pageParam).catch(
        handleErrorRedirect,
      ),
    initialPageParam: 1,
    enabled: isAuthorized,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
  });

  const groupTimesheetDatesQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupTimesheetDates, projectId, groupId, groupTimesheet.id],
    queryFn: () => getGroupTimesheetDates(parseInt(projectId!), parseInt(groupId!), groupTimesheet.id),
    enabled: isAuthorized,
  });

  const groupTimesheetEmployeesData = useMemo(() => {
    return groupTimesheetEmployeesQuery.data?.pages.flatMap((page) => page.data);
  }, [groupTimesheetEmployeesQuery.data]);

  const groupTimesheetDatesData = useMemo(() => {
    return groupTimesheetDatesQuery.data ?? undefined;
  }, [groupTimesheetDatesQuery]);

  const updateTimesheetMutation = useUpdateTimesheetMutation(parseInt(projectId!), parseInt(groupId!), groupTimesheet.id, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Timesheet was edited successfully!" });
      if (submitAction === TimesheetFormSubmitActionEnum.final) {
        setRedirectOnClose(true);
      }
      if (buttonTypeClicked === TimesheetRevertActionEnum.revertApproved) {
        setButtonTypeClicked(TimesheetRevertActionEnum.revertApproved);
        setRedirectOnClose(true);
      }
      if (buttonTypeClicked === TimesheetRevertActionEnum.revertReviewed) {
        setButtonTypeClicked(TimesheetRevertActionEnum.revertReviewed);
        setRedirectOnClose(true);
      }

      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  // const hasApplicableDayWithNoRecords = useMemo(() => {
  //   for (const employee of timesheetMutationData) {
  //     //Check for applicable days that have either no records or sum of all worked hours for that day is 0
  //
  //     //Check if i have applicable day but without any records in the mmrctrs for the specific calendarDayId
  //     if (
  //       employee.mmrs.some((mmr) =>
  //         mmr.mmrCalendarDays.some(
  //           (day) =>
  //             day.isApplicable &&
  //             !mmr.mmrCtrs.some((ctr) => ctr.calendarDayHours.some((calendarDay) => calendarDay.calendarDayId === day.calendarDayId)),
  //         ),
  //       )
  //     ) {
  //       return true;
  //     }
  //   }
  //
  //   return false;
  // }, [timesheetMutationData]);

  const hasCtrWithoutAnyWorkedHours = useMemo(() => {
    for (const employee of timesheetMutationData) {
      if (
        employee.mmrs.some((mmr) =>
          mmr.mmrCalendarDays.some(
            (day) =>
              day.isApplicable &&
              // Check if for a specific mmr and calendar day, there is a at least one ctr with worked hours > 0. If not, return true
              !mmr.mmrCtrs.some((ctr) =>
                ctr.calendarDayHours.some((calendarDay) => calendarDay.calendarDayId === day.calendarDayId && calendarDay.workedHours >= 0),
              ),
          ),
        )
      ) {
        return true;
      }
    }
    return false;
  }, [timesheetMutationData]);

  // const hasWorkingDayRecordWithLessThanMaxHours = useMemo(() => {
  //     for (const employee of timesheetMutationData) {
  //         if (
  //             employee.mmrs.some((mmr) =>
  //                 mmr.mmrCalendarDays.some(
  //                     (day) =>
  //                         day.isApplicable &&
  //                         mmr.mmrCtrs.some((ctr) =>
  //                             ctr.calendarDayHours.some((calendarDay) => calendarDay.calendarDayId === day.calendarDayId && calendarDay.workedHours > ctr.maxHours),
  //                         ),
  //                 ),
  //             )
  //         ) {
  //             return true;
  //         }
  //     }
  //     return false;
  // }, [timesheetMutationData]);

  const submitButtonTooltipText: ActionButtonTooltipTextEnum = useMemo(() => {
    // if (hasApplicableDayWithNoRecords) {
    //   return ActionButtonTooltipTextEnum.applicableDayWithNoRecordsViolation;
    // }
    if (hasAnyInvalidWorkedHourSum) {
      return ActionButtonTooltipTextEnum.exceedMaxHoursViolation;
    }
    if (hasCtrWithoutAnyWorkedHours) {
      return ActionButtonTooltipTextEnum.lessThanMinHoursViolation;
    }
    return ActionButtonTooltipTextEnum.noViolation;
  }, [hasCtrWithoutAnyWorkedHours, hasAnyInvalidWorkedHourSum]);

  const isFormValid: boolean = useMemo(() => {
    return !(hasAnyInvalidWorkedHourSum || hasCtrWithoutAnyWorkedHours);
  }, [hasAnyInvalidWorkedHourSum, hasCtrWithoutAnyWorkedHours]);

  const filteredEmployees: FrontendTimesheetEmployeeBodyType[] = useMemo(
    () =>
      timesheetMutationData
        .map(
          (employee): FrontendTimesheetEmployeeBodyType => ({
            ...employee,
            mmrs: employee.mmrs.map(
              (mmr): FrontendBodyTimesheetRecordSave => ({
                ...mmr,
                mmrId: mmr.mmrId,

                mmrCalendarDays: mmr.mmrCalendarDays,
                mmrCtrs: mmr.mmrCtrs.map(
                  (ctr): FrontendMmrCtrsBody => ({
                    ...ctr,
                    calendarDayHours: ctr.calendarDayHours.filter((calendarDay) => calendarDay.workedHours >= 0),
                  }),
                ),
              }),
            ),
          }),
        )
        .filter((employeeWithEditedMmrs) => employeeWithEditedMmrs.mmrs.some((mmr) => mmr.mmrCalendarDays.length > 0)),
    [timesheetMutationData],
  );

  const onUpdateTimesheet = useCallback(
    (submitActionType: TimesheetFormSubmitActionEnum) => {
      setSubmitAction(submitActionType);

      updateTimesheetMutation.mutate({
        timesheetEmployees: filteredEmployees ?? null,
        timesheetStatus: submitActionType === TimesheetFormSubmitActionEnum.final ? findNextTimesheetStatusStep(timesheetStatus)! : null,
      });

      onOpenLoaderPopup();
    },
    [updateTimesheetMutation, timesheetMutationData, onOpenLoaderPopup],
  );

  const groupTimesheetEmployeesListSkeletonLoader = (
    <div className={classNames(TimesheetFormStyles.gridRow, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      {["timesheet1", "timesheet2", "timesheet3"].map((key) => (
        <Skeleton key={key} variant="rounded" height={120} />
      ))}
    </div>
  );

  const groupTimesheetDateHeadersSkeletonLoader = (
    <>
      <div
        className={classNames(
          TimesheetFormStyles.gridHeaderSkeletonLoader,
          TimesheetFormStyles.firstHeaderSkeletonLoader,
          GlobalStyles.centerVertical,
        )}
      >
        {"Group Employees"}
      </div>
      <div className={classNames(TimesheetFormStyles.gridHeaderSkeletonLoader, GlobalStyles.centerVertical)}>
        <div>Job Title &</div>
        <div>MMR Reference</div>
      </div>

      {["timesheetDate1", "timesheetDate2", "timesheetDate3", "timesheetDate4", "timesheetDate5", "timesheetDate6", "timesheetDate7"].map((key) => (
        <div
          key={key}
          className={classNames(
            TimesheetFormStyles.gridHeaderSkeletonLoader,
            TimesheetFormStyles.gridDateHeaderSkeletonLoader,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
          )}
        >
          <Skeleton key={key} variant="rounded" height={38} width={"100%"} />
        </div>
      ))}
    </>
  );

  const isEitherQueryLoading = useMemo(
    () => groupTimesheetDatesQuery.isLoading || groupTimesheetEmployeesQuery.isLoading,
    [groupTimesheetDatesQuery.isLoading, groupTimesheetEmployeesQuery.isLoading],
  );
  const backToFinalizedListLink = useMemo(() => {
    if (statusSearchQuery === ProjectTimesheetTabStatusEnum.approved) {
      return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${
        AppRoutesPaths.projectSingleTimesheetsGroups
      }/${groupId}?status=${FrontendTimesheetStatusEnum.REVIEWED}`;
    } else if (statusSearchQuery === ProjectTimesheetTabStatusEnum.reviewed && buttonTypeClicked === TimesheetRevertActionEnum.revertReviewed) {
      return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${
        AppRoutesPaths.projectSingleTimesheetsGroups
      }/${groupId}?status=${FrontendTimesheetStatusEnum.CHECKED}`;
    } else {
      return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${
        AppRoutesPaths.projectSingleTimesheetsGroups
      }/${groupId}?status=${findNextTimesheetStatusStep(convertProjectTimesheetTabStatusEnumToFrontend(statusSearchQuery))!}`;
    }
  }, [projectId, groupId, statusSearchQuery, buttonTypeClicked]);

  const onCloseResponseAlertPopupModified = () => {
    if (responseType === ApiResponseTypeEnum.success) {
      invalidateQueries();
      invalidateAfterStatusChange();
      setSubmitAction(null);
      if (redirectOnClose) {
        setRedirectOnClose(false); // Reset the redirect state
        navigate(backToFinalizedListLink);
      }
      if (buttonTypeClicked === TimesheetRevertActionEnum.revertApproved || buttonTypeClicked === TimesheetRevertActionEnum.revertReviewed) {
        navigate(backToFinalizedListLink);
        setButtonTypeClicked(null);
      }
    }
  };

  const resetSubmitAction = () => {
    setClickedSubmitAction(null);
  };
  const isApproved = useMemo(() => timesheetStatus === FrontendTimesheetStatusEnum.APPROVED, [timesheetStatus]);
  const isReviewed = useMemo(() => timesheetStatus === FrontendTimesheetStatusEnum.REVIEWED, [timesheetStatus]);

  const hasEditPrivilegedBasedOnPermissions = useMemo(() => {
    if (canFillTimesheet && canChangeTimesheetStatus) {
      if (canChangeAllTimesheetsStatuses && timesheetStatus !== FrontendTimesheetStatusEnum.APPROVED) {
        return true;
      }
      if (timesheetStatus === FrontendTimesheetStatusEnum.PENDING && canChangeFromPendingToPrepared) {
        return true;
      }
      if (timesheetStatus === FrontendTimesheetStatusEnum.PREPARED && canChangeFromPreparedToChecked) {
        return true;
      }
      if (timesheetStatus === FrontendTimesheetStatusEnum.CHECKED && canChangeFromCheckedToReviewed) {
        return true;
      }
      if (timesheetStatus === FrontendTimesheetStatusEnum.REVIEWED && canChangeFromReviewedtoChecked) {
        return true;
      }
      if (timesheetStatus === FrontendTimesheetStatusEnum.REVIEWED && canChangeFromReviewedToApproved) {
        return true;
      }
      if (timesheetStatus === FrontendTimesheetStatusEnum.APPROVED && canChangeFromApprovedToReviewed) {
        return true;
      }
    }
  }, [
    canFillTimesheet,
    canChangeTimesheetStatus,
    canChangeAllTimesheetsStatuses,
    timesheetStatus,
    canChangeFromPendingToPrepared,
    canChangeFromPreparedToChecked,
    canChangeFromCheckedToReviewed,
    canChangeFromReviewedtoChecked,
    canChangeFromReviewedToApproved,
  ]);

  const sendBackToReviewed = () => {
    onOpenLoaderPopup();
    updateTimesheetMutation.mutate({
      timesheetStatus: FrontendTimesheetStatusEnum.REVIEWED,
      timesheetEmployees: null,
    });
    setButtonTypeClicked(TimesheetRevertActionEnum.revertApproved);
  };

  const sendBackToChecked = () => {
    onOpenLoaderPopup();
    updateTimesheetMutation.mutate({
      timesheetEmployees: filteredEmployees ?? null,
      timesheetStatus: FrontendTimesheetStatusEnum.CHECKED,
    });
    setButtonTypeClicked(TimesheetRevertActionEnum.revertReviewed);
  };

  const invalidateQueries = useCallback(() => {
    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupActiveMmrCtrItemRecords, parseInt(projectId!), parseInt(groupId!), groupTimesheet.id],
    });

    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupActiveMmrCtrCodes, parseInt(projectId!), parseInt(groupId!), groupTimesheet.id],
    });

    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupActiveMmrCtrItemRecords, parseInt(projectId!), parseInt(groupId!)],
    });
  }, [queryClient, projectId, groupId, groupTimesheet.id]);

  const invalidateAfterStatusChange = useCallback(() => {
    void queryClient.resetQueries({
      queryKey: [
        QueryNames.ProjectTimesheetGroupTimesheets,
        SubQueryNames.changeStatus,
        projectId,
        groupTimesheet.timesheetGroupId,
        groupTimesheet.id,
      ],
    });
    void queryClient.resetQueries({
      queryKey: [
        QueryNames.ProjectTimesheetGroupTimesheets,
        projectId?.toString(),
        groupTimesheet.timesheetGroupId.toString(),
        groupTimesheet.id,
        DetailLevel.BASIC,
      ],
    });
  }, [queryClient, projectId, groupTimesheet.timesheetGroupId, groupTimesheet.id]);

  useEffect(() => {
    if (isEditMode && !hasEditPrivilegedBasedOnPermissions) {
      setSearchParams({ mode: FrontendGroupsTimesheetModeEnum.VIEW });
    }
  }, [isEditMode, statusSearchQuery, setSearchParams]);

  useEffect(() => {
    groupTimesheetEmployeesData &&
      setTimesheetMutationData(
        groupTimesheetEmployeesData.map((timesheetEmployee) => {
          return {
            timesheetEmployeeId: timesheetEmployee.timesheetEmployeeId,
            mmrs: [],
          };
        }),
      );
  }, [groupTimesheetEmployeesData]);

  useEffect(() => {
    if (
      inView &&
      !groupTimesheetEmployeesQuery.isLoading &&
      !groupTimesheetEmployeesQuery.isFetching &&
      !groupTimesheetEmployeesQuery.isFetchingNextPage &&
      groupTimesheetEmployeesQuery.hasNextPage
    ) {
      void groupTimesheetEmployeesQuery.fetchNextPage();
    }
  }, [inView, groupTimesheetEmployeesQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroupTimesheetEmployees, projectId, groupId, groupTimesheet.id] })
        .then(() =>
          console.log(`In timesheet form with id ${groupTimesheet.id}, ${QueryNames.ProjectTimesheetGroupTimesheetEmployees} query canceled`),
        );
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroupTimesheetDates, projectId, groupId, groupTimesheet.id] })
        .then(() => console.log(`In timesheet form with id ${groupTimesheet.id}, ${QueryNames.ProjectTimesheetGroupTimesheetDates} query canceled`));
    };
  }, [queryClient, projectId, groupId, groupTimesheet.id]);

  return (
    <div className={classNames(GlobalStyles.overflowAutoFullHeight, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(TimesheetFormStyles.gridGeneral)}>
        {groupTimesheetDatesQuery.isLoading
          ? groupTimesheetDateHeadersSkeletonLoader
          : groupTimesheetDatesData &&
            canListTimesheets && (
              <>
                <TimesheetFormHeaders dateInfoHeaders={groupTimesheetDatesData} />
                {groupTimesheetEmployeesQuery.isLoading ? (
                  groupTimesheetEmployeesListSkeletonLoader
                ) : groupTimesheetEmployeesData && groupTimesheetEmployeesData.length > 0 ? (
                  groupTimesheetEmployeesData.map((timesheetEmployee) => (
                    <React.Fragment key={timesheetEmployee.timesheetEmployeeId}>
                      <TimesheetFormEmployeeListItem
                        timesheetId={groupTimesheet.id}
                        timesheetEmployee={timesheetEmployee}
                        groupTimesheetDates={groupTimesheetDatesData}
                        setTimesheetMutationData={setTimesheetMutationData}
                        setHasAnyInvalidWorkedHourSum={setHasAnyInvalidWorkedHourSum}
                        setHasLessThanMaxHoursRecord={setHasLessThanMaxHoursRecord}
                      />
                      <InfiniteScrollInViewElement reference={ref} infiniteQueryResult={groupTimesheetEmployeesQuery} loaderComponent={<></>} />
                    </React.Fragment>
                  ))
                ) : (
                  <div className={classNames(TimesheetFormStyles.gridRow, GlobalStyles.flex, GlobalStyles.gap)}>
                    <div className={classNames(GlobalStyles.flex1)} />
                    <div className={classNames(GlobalStyles.emptyListMsg)}>{"No employees for this timesheet"}</div>
                    <div className={classNames(GlobalStyles.flex1)} />
                  </div>
                )}
              </>
            )}
      </div>
      {isEditMode
        ? canFillTimesheet &&
          !isEitherQueryLoading &&
          hasEditPrivilegedBasedOnPermissions && (
            <>
              <div className={classNames(GlobalStyles.flex1)} />
              <div className={classNames(TimesheetFormStyles.actionButtonsContainer, GlobalStyles.flex, GlobalStyles.gap)}>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <Link className={classNames(TimesheetFormStyles.actionButton, TimesheetFormStyles.actionCancelButton)} to={backToListLink}>
                    {"Cancel"}
                  </Link>
                </div>
                <Tooltip TransitionComponent={Fade} title={!isFormValid ? submitButtonTooltipText : ""} placement="top" arrow>
                  <div
                    className={classNames(
                      TimesheetFormStyles.actionDraftButton,
                      !isFormValid && TimesheetFormStyles.actionDraftButtonDisabled,
                      GlobalStyles.flex,
                      GlobalStyles.flexDirectionColumn,
                    )}
                    onClick={() => {
                      setClickedSubmitAction(TimesheetFormSubmitActionEnum.draft);
                      isFormValid && hasLessThanMaxHoursRecord
                        ? onOpenWarningAlertPopupModified()
                        : onUpdateTimesheet(TimesheetFormSubmitActionEnum.draft);
                    }}
                  >
                    <div className={classNames(TimesheetFormStyles.actionDraftButtonLargerText)}>{"Save"}</div>
                    <div className={classNames(TimesheetFormStyles.actionDraftButtonSmallerText)}>{"without submitting"}</div>
                  </div>
                </Tooltip>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  {isReviewed && (
                    <div
                      className={classNames(TimesheetFormStyles.revertStatusButton, GlobalStyles.reviewedStatusBackground)}
                      onClick={onOpenStatusChangeConfirmationPopup}
                    >
                      Send back for Review
                    </div>
                  )}
                </div>
                <div className={classNames(GlobalStyles.flex1)} />
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
                  <Tooltip TransitionComponent={Fade} title={!isFormValid ? submitButtonTooltipText : ""} placement="top" arrow>
                    <div
                      className={classNames(
                        TimesheetFormStyles.actionButton,
                        !isFormValid && TimesheetFormStyles.actionButtonDisabled,
                        TimesheetFormStyles.actionSubmitButton,
                        GlobalStyles.centerVertical,
                      )}
                      onClick={() => {
                        setClickedSubmitAction(TimesheetFormSubmitActionEnum.final);
                        isFormValid && hasLessThanMaxHoursRecord
                          ? onOpenWarningAlertPopupModified()
                          : onUpdateTimesheet(TimesheetFormSubmitActionEnum.final);
                      }}
                    >
                      {`Mark as ${capitalizeFirstLetter(
                        findNextTimesheetStatusStep(convertProjectTimesheetTabStatusEnumToFrontend(statusSearchQuery))!,
                      )}`}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </>
          )
        : isApproved &&
          !isEitherQueryLoading && (
            <>
              <div className={classNames(GlobalStyles.flex1)}></div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
                <div className={classNames(GlobalStyles.flex1, GlobalStyles.flex)}></div>
                <div
                  className={classNames(
                    TimesheetFormStyles.revertStatusButton,
                    GlobalStyles.flex,
                    GlobalStyles.gap05,
                    GlobalStyles.elementWithCursor,
                    GlobalStyles.reviewedStatusBackground,
                  )}
                  onClick={onOpenStatusChangeConfirmationPopup}
                >
                  {`Cancel Approval`}
                </div>
              </div>
            </>
          )}

      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseResponseAlertPopupModified();
          }}
        />
      )}
      {isWarningAlertPopupOpenModified && (
        <ResponseAlertPopup
          responseType={ApiResponseTypeEnum.warning}
          responseObject={{
            status: 400,
            message: WarningMessagesEnum.lessThanMaxHoursDayWarning,
          }}
          isOpen={isWarningAlertPopupOpenModified}
          closeFn={onCloseWarningAlertPopupModified}
          popupButtons={[
            {
              text: "Close",
              buttonType: PopupButtonTypeEnum.neutral,
              action: onCloseWarningAlertPopupModified,
            },
            {
              text: "Submit",
              buttonType: PopupButtonTypeEnum.main,
              action: () => {
                if (clickedSubmitAction) {
                  onUpdateTimesheet(clickedSubmitAction);
                }
                onCloseWarningAlertPopupModified();
                resetSubmitAction();
              },
            },
          ]}
        />
      )}
      {isStatusChangeConfirmationPopupOpen && (isApproved || isReviewed) && (
        <ResponseAlertPopup
          responseType={ApiResponseTypeEnum.warning}
          responseObject={{
            status: 400,
            message: isApproved ? "Are you sure you want to cancel Approval?" : isReviewed ? "Are you sure you want to send back for Review?" : "",
          }}
          isOpen={isStatusChangeConfirmationPopupOpen}
          closeFn={onCloseWarningAlertPopupModified}
          popupButtons={[
            {
              text: "Close",
              buttonType: PopupButtonTypeEnum.neutral,
              action: () => {
                onCloseStatusChangeConfirmationPopup();
              },
            },
            {
              text: "Submit",
              buttonType: PopupButtonTypeEnum.main,
              action: () => {
                isApproved ? sendBackToReviewed() : isReviewed ? sendBackToChecked() : "";
                onCloseStatusChangeConfirmationPopup();
              },
            },
          ]}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </div>
  );
};

export default TimesheetForm;
