import { FC, useEffect, useMemo } from "react";
import { useImpersonationStore } from "../../../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiUsers } from "../../../../../../../../../apicalls/users/useEpcmApiUsers.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleUnauthorized } from "../../../../../../../../../utils/use-handle-unauthorized.ts";
import { QueryNames } from "../../../../../../../../../types/apicallstypes/queryCommons.ts";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../../../assets/css/GlobalStyles.module.css";
import { Skeleton } from "@mui/material";
import TimesheetFlowActorStyles from "./TimesheetFlowActor.module.css";
import { formatTimesheetActorName } from "../../../../../../../../../utils/StringManipulation.ts";
import clockIcon from "../../../../../../../../../assets/images/clock-gray.svg";
import { DateFormatEnum, formatDate, getTimeFromDate, isCurrentDate } from "../../../../../../../../../utils/DateManipulation.ts";
import { FrontendTimesheetActorType } from "../../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";

interface TimesheetFlowActorProps {
  label: string;
  actorData: FrontendTimesheetActorType | null | undefined;
  isTimesheetQueryLoading: boolean;
}

const TimesheetFlowActor: FC<TimesheetFlowActorProps> = ({ actorData, label, isTimesheetQueryLoading }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getUser } = useEpcmApiUsers();
  const queryClient = useQueryClient();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const actorQuery = useQuery({
    queryKey: [QueryNames.Users, actorData?.user.code],
    queryFn: () => getUser(actorData ? actorData.user.code : "").catch(handleErrorRedirect),
    enabled: isAuthorized && !!actorData?.user.code,
  });

  const actorUserData = useMemo(() => {
    return actorQuery.data ?? undefined;
  }, [actorQuery]);

  useEffect(() => {
    return () => {
      if (actorData?.user.code) {
        void queryClient.cancelQueries({ queryKey: [QueryNames.Users, actorData.user.code] });
      }
    };
  }, [queryClient, actorData?.user.code]);
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
      <div className={classNames()}>{label}</div>
      {(isTimesheetQueryLoading || (actorQuery.isLoading && !!actorData)) && <Skeleton variant={"rounded"} height={16} width={150} />}

      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(actorData && TimesheetFlowActorStyles.detailsText, GlobalStyles.centerVertical)}>
          <div className={classNames(TimesheetFlowActorStyles.noWrapText)}>{!actorData && `- -`}</div>
          {actorData && actorUserData && formatTimesheetActorName(actorUserData.name)}
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        {actorData ? (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(TimesheetFlowActorStyles.clockIconImg)} src={clockIcon} alt="clock" />
            </div>
            <div className={classNames(TimesheetFlowActorStyles.actionDateContainer, GlobalStyles.centerVertical)}>
              {isCurrentDate(actorData.actionDate)
                ? getTimeFromDate(actorData.actionDate)
                : formatDate(actorData.actionDate, DateFormatEnum.DD_MM_YYYY)}
            </div>
          </div>
        ) : (
          <div className={classNames(TimesheetFlowActorStyles.dateFillerDiv)}></div>
        )}
      </div>
    </div>
  );
};

export default TimesheetFlowActor;
