import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import ConfigurationMenuItemStyles from "./ConfigurationMenuItem.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import arrowRightImg from "../../../../../assets/images/configuration/arrow-right-black.svg";
import missingConfigurationInfoImg from "../../../../../assets/images/orange-dot.svg";

interface ConfigurationMenuItemProps {
  img: string;
  title: string;
  text: string;
  to: string;
  hasMissingInfo?: boolean;
}

const ConfigurationMenuItem: React.FC<ConfigurationMenuItemProps> = ({ img, title, text, to, hasMissingInfo }) => {
  const [isMissingInfoIconHovered, setIsMissingInfoIconHovered] = React.useState(false);
  const [isLinkItemHovered, setIsLinkItemHovered] = useState(false);

  return (
    <NavLink
      to={to}
      className={classNames(ConfigurationMenuItemStyles.navLinkContainer)}
      onMouseEnter={() => setIsMissingInfoIconHovered(true)}
      onMouseLeave={() => setIsMissingInfoIconHovered(false)}
    >
      <div
        onMouseEnter={() => setIsLinkItemHovered(true)}
        onMouseLeave={() => setIsLinkItemHovered(false)}
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          ConfigurationMenuItemStyles.container,
          isLinkItemHovered && ConfigurationMenuItemStyles.containerHover,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            <div className={classNames()}>
              <img className={classNames(ConfigurationMenuItemStyles.projectsInfoImg)} src={img} alt={title} />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            {hasMissingInfo && (
              <>
                {isMissingInfoIconHovered && <div className={classNames(ConfigurationMenuItemStyles.missingInfoText)}>{`Missing ${title} Info`}</div>}
                <div className={classNames(ConfigurationMenuItemStyles.missingInfoDiv)}>
                  <img
                    className={classNames(ConfigurationMenuItemStyles.missingConfigurationInfoImg)}
                    src={missingConfigurationInfoImg}
                    alt="missing"
                  />
                </div>
              </>
            )}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(ConfigurationMenuItemStyles.containerTitle)}>{title}</div>
          </div>
          <hr className={classNames(ConfigurationMenuItemStyles.line)} />
          <div className={classNames(ConfigurationMenuItemStyles.containerText)}>{text}</div>
        </div>
        <div className={classNames(GlobalStyles.flex1)}></div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, ConfigurationMenuItemStyles.configureButton)}>
          <div>Configure</div>
          <img className={classNames(ConfigurationMenuItemStyles.arrowButton)} src={arrowRightImg} alt="arrow" />
        </div>
      </div>
    </NavLink>
  );
};

export default ConfigurationMenuItem;
