import { ApiResponseBodyTimesheetGroupGetDepartmentManager } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";
import { FrontendTimesheetGroupDepartmentManagerType } from "../../../../ProjectTimesheetsApiTypes.ts";

export const convertProjectTimesheetGroupDepartmentManagerData = (
  departmentManagerData: ApiResponseBodyTimesheetGroupGetDepartmentManager,
): FrontendTimesheetGroupDepartmentManagerType => {
  return departmentManagerData.departmentManager
    ? {
        departmentManager: {
          code: departmentManagerData.departmentManager.code,
          name: ` ${departmentManagerData.departmentManager.surname} ${departmentManagerData.departmentManager.name}`,
          email: departmentManagerData.departmentManager.email,
        },
      }
    : {
        departmentManager: null,
      };
};
