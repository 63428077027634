import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import {
  ChangeStatusPopupEntityInfo,
  ChangeStatusPopupEntityType,
  MmrInfo,
  FrontendQueryConfig,
  TimesheetInfo,
} from "../../../../types/PopupChangeEntityStatusType.ts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FrontendTimesheetStatusEnum, FrontendTimesheetType } from "../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { FrontendMmrStatusEnum, FrontendProjectEmployeeMmrDetailedType } from "../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import ChangeEntityStatusPopupStyles from "./ChangeEntityStatusPopup.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";

import ChangeMmrStatusContent from "./changemmrstatuscontent/ChangeMmrStatusContent.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useEpcmApiProjectEmployee } from "../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { useEpcmApiProjectEmployeeMutations } from "../../../../apicalls/projects/projectemployee/mutations/useEpcmApiProjectEmployeeMutations.ts";
import { convertMmrStatusToBackend } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projectemployees/ProjectEmployeeMmrApiConverter.ts";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import ChangeTimesheetStatusContent from "./changetimesheetstatuscontent/ChangeTimesheetStatusContent.tsx";
import { MmrDateStatus } from "epcm-common/dist/Types/MmrTypes";
import { convertDateToMilSecs } from "../../../../utils/DateManipulation.ts";

interface ChangeEntityStatusPopupProps extends PopupType {
  entityInfo: ChangeStatusPopupEntityInfo;
}

const ChangeEntityStatusPopup: FC<ChangeEntityStatusPopupProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText, entityInfo }) => {
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const queryClient = useQueryClient();
  const { getProjectEmployeeMmr } = useEpcmApiProjectEmployee();
  const { getTimesheetGroupTimesheet } = useEpcmApiProjectsTimesheetsGroups();
  const { useUpdateMmrMutation } = useEpcmApiProjectEmployeeMutations();
  const { useUpdateTimesheetMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const timesheetInfo = entityInfo as TimesheetInfo;
  const mmrInfo = entityInfo as MmrInfo;
  const [statusSelected, setStatusSelected] = useState<FrontendTimesheetStatusEnum | FrontendMmrStatusEnum>();
  const [customEditDate, setCustomEditDate] = useState<Date | null>(new Date());
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const STATUS_ARRAY = useMemo(() => {
    return entityInfo.type === ChangeStatusPopupEntityType.MMR
      ? [FrontendMmrStatusEnum.APPROVED, FrontendMmrStatusEnum.PENDING, FrontendMmrStatusEnum.REJECTED]
      : [
          FrontendTimesheetStatusEnum.PENDING,
          FrontendTimesheetStatusEnum.PREPARED,
          FrontendTimesheetStatusEnum.CHECKED,
          FrontendTimesheetStatusEnum.REVIEWED,
          FrontendTimesheetStatusEnum.APPROVED,
        ];
  }, [entityInfo.type]);

  const getMmrQueryConfig = useCallback(
    ({ projectId, projectEmployeeId, mmrId }: MmrInfo): FrontendQueryConfig => {
      return {
        queryKey: [QueryNames.ProjectEmployeeMmrItem, SubQueryNames.changeStatus, projectId, projectEmployeeId, mmrId],
        queryFn: () =>
          getProjectEmployeeMmr(projectId, projectEmployeeId, mmrId, DetailLevel.DETAILED) as Promise<FrontendProjectEmployeeMmrDetailedType>,
        enabled: isAuthorized && !!projectId && !!projectEmployeeId && !!mmrId,
      };
    },
    [getProjectEmployeeMmr, isAuthorized],
  );

  const getTimesheetQueryConfig = useCallback(
    ({ projectId, timesheetGroupId, timesheetId }: TimesheetInfo): FrontendQueryConfig => {
      return {
        queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, SubQueryNames.changeStatus, projectId, timesheetGroupId, timesheetId],
        queryFn: () => getTimesheetGroupTimesheet(projectId, timesheetGroupId, timesheetId, DetailLevel.NORMAL) as Promise<FrontendTimesheetType>,
        enabled: isAuthorized,
      };
    },
    [getTimesheetGroupTimesheet, isAuthorized],
  );

  const queryConfigFunctions = useMemo(
    () =>
      new Map<ChangeStatusPopupEntityType, (info: ChangeStatusPopupEntityInfo) => FrontendQueryConfig>([
        [
          ChangeStatusPopupEntityType.MMR,
          (info: ChangeStatusPopupEntityInfo): FrontendQueryConfig => {
            if (info.type === ChangeStatusPopupEntityType.MMR) {
              const { projectId, projectEmployeeId, mmrId } = info;
              return getMmrQueryConfig({
                projectId,
                projectEmployeeId,
                mmrId,
                type: ChangeStatusPopupEntityType.MMR,
              });
            }
            throw new Error("Invalid entity type for MMR query config.");
          },
        ],
        [
          ChangeStatusPopupEntityType.TIMESHEET,
          (info: ChangeStatusPopupEntityInfo): FrontendQueryConfig => {
            if (info.type === ChangeStatusPopupEntityType.TIMESHEET) {
              const { projectId, timesheetGroupId, timesheetId } = info;
              return getTimesheetQueryConfig({
                projectId,
                timesheetGroupId,
                timesheetId,
                type: ChangeStatusPopupEntityType.TIMESHEET,
              });
            }
            throw new Error("Invalid entity type for Timesheet query config.");
          },
        ],
      ]),
    [getMmrQueryConfig, getTimesheetQueryConfig],
  );

  const queryConfig = useMemo(() => {
    const configFunction = queryConfigFunctions.get(entityInfo.type);
    if (configFunction) {
      return configFunction(entityInfo);
    }
  }, [entityInfo, queryConfigFunctions]);

  const queryResult = useQuery({
    queryKey: queryConfig!.queryKey,
    queryFn: queryConfig!.queryFn,
    enabled: queryConfig!.enabled,
    select: (data) =>
      entityInfo.type === ChangeStatusPopupEntityType.MMR ? (data as FrontendProjectEmployeeMmrDetailedType) : (data as FrontendTimesheetType),
  });

  const queryResultData = useMemo(() => {
    return queryResult.data;
  }, [queryResult.data]);

  const changeMmrStatusMutation = useUpdateMmrMutation(mmrInfo.projectId, mmrInfo.projectEmployeeId, mmrInfo.mmrId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "MMR Status updated successfully" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({
        queryKey: [QueryNames.ProjectEmployeeMmrItem, SubQueryNames.changeStatus, mmrInfo.projectId, mmrInfo.projectEmployeeId, mmrInfo.mmrId],
      });

      onCloseLoaderPopup();
    },
  });

  const changeTimesheetStatusMutation = useUpdateTimesheetMutation(
    timesheetInfo.projectId,
    timesheetInfo.timesheetGroupId,
    timesheetInfo.timesheetId,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Timesheet Status updated successfully" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        void queryClient.resetQueries({
          queryKey: [
            QueryNames.ProjectTimesheetGroupTimesheets,
            SubQueryNames.changeStatus,
            timesheetInfo.projectId,
            timesheetInfo.timesheetGroupId,
            timesheetInfo.timesheetId,
          ],
        });
        void queryClient.resetQueries({
          queryKey: [
            QueryNames.ProjectTimesheetGroupTimesheets,
            timesheetInfo.toString(),
            timesheetInfo.timesheetGroupId.toString(),
            timesheetInfo.timesheetId,
            DetailLevel.BASIC,
          ],
        });
        onCloseLoaderPopup();
      },
    },
  );

  const onChangeTimesheetStatus = useCallback(() => {
    changeTimesheetStatusMutation.mutate({
      timesheetStatus: statusSelected as FrontendTimesheetStatusEnum,
      timesheetEmployees: null,
    });
    onOpenLoaderPopup();
  }, [changeTimesheetStatusMutation, statusSelected, onOpenLoaderPopup]);

  const onCreateMmrStatus = useCallback(() => {
    changeMmrStatusMutation.mutate({
      status: convertMmrStatusToBackend(statusSelected as FrontendMmrStatusEnum),
      editDateTimestamp: customEditDate ? convertDateToMilSecs(customEditDate) : convertDateToMilSecs(new Date()),
    });
    onOpenLoaderPopup();
  }, [changeMmrStatusMutation, statusSelected, onOpenLoaderPopup]);

  const onConfirmStatusChange = useCallback(() => {
    if (entityInfo.type === ChangeStatusPopupEntityType.MMR) {
      onCreateMmrStatus();
    } else if (entityInfo.type === ChangeStatusPopupEntityType.TIMESHEET) {
      onChangeTimesheetStatus();
    }
  }, [entityInfo.type, onChangeTimesheetStatus, onCreateMmrStatus]);

  const invalidateQueries = useCallback(() => {
    if (entityInfo.type === ChangeStatusPopupEntityType.MMR) {
      const { projectId, projectEmployeeId, mmrId } = entityInfo;
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmr, projectId, projectEmployeeId] });
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
      void queryClient.resetQueries({ queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, projectId] });
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, projectEmployeeId, mmrId] });
      void queryClient.resetQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, projectEmployeeId, mmrId, MmrDateStatus.ACTIVE] });
      void queryClient.resetQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, SubQueryNames.list, projectId, projectEmployeeId, mmrId] });
    } else if (entityInfo.type === ChangeStatusPopupEntityType.TIMESHEET) {
      const { projectId, timesheetGroupId } = entityInfo as TimesheetInfo; // Type assertion if necessary

      void queryClient.resetQueries({
        queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId.toString(), timesheetInfo.timesheetGroupId.toString()],
      });
      void queryClient.resetQueries({
        queryKey: [
          QueryNames.ProjectTimesheetGroupTimesheets,
          projectId.toString(),
          timesheetInfo.timesheetGroupId.toString(),
          timesheetInfo.timesheetId,
        ],
      });

      void queryClient.resetQueries({
        queryKey: [
          QueryNames.ProjectTimesheetGroupTimesheets,
          projectId.toString(),
          timesheetInfo.timesheetGroupId.toString(),
          timesheetInfo.timesheetId,
        ],
      });

      void queryClient.invalidateQueries({
        queryKey: [
          QueryNames.ProjectTimesheetGroupTimesheets,
          projectId.toString(),
          timesheetInfo.timesheetGroupId.toString(),
          timesheetInfo.timesheetId,
          false,
        ],
      });

      void queryClient.invalidateQueries({
        queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, SubQueryNames.changeStatus, projectId, timesheetGroupId, timesheetInfo.timesheetId],
      });

      void queryClient.invalidateQueries({
        queryKey: [QueryNames.ProjectTimesheets, projectId],
      });

      void queryClient.resetQueries({ queryKey: [QueryNames.ProjectTimesheetGroupActiveMmrCtrCodes, projectId, timesheetGroupId] });
    }
  }, [queryClient, entityInfo, timesheetInfo.timesheetId, timesheetInfo.timesheetGroupId]);

  useEffect(() => {
    if (entityInfo.type === ChangeStatusPopupEntityType.MMR && queryResultData) {
      return () => {
        void queryClient
          .cancelQueries({
            queryKey: [
              QueryNames.ProjectEmployeeMmrItem,
              SubQueryNames.changeStatus,
              entityInfo.projectId,
              entityInfo.projectEmployeeId,
              entityInfo.mmrId,
            ],
          })
          .then(() => {
            console.log(
              `Query Name with key: ${QueryNames.ProjectEmployeeMmrItem} and key:${SubQueryNames.changeStatus}, 
               project ${entityInfo.projectId}, projectEmployee ${entityInfo.projectEmployeeId}, mmrId:${entityInfo.mmrId} has been cancelled`,
            );
          });
      };
    }
  }, [queryClient, entityInfo, queryResultData]);

  useEffect(() => {
    if (entityInfo.type === ChangeStatusPopupEntityType.TIMESHEET && queryResultData) {
      return () => {
        void queryClient
          .cancelQueries({
            queryKey: [
              QueryNames.ProjectTimesheetGroupTimesheets,
              SubQueryNames.changeStatus,
              entityInfo.projectId,
              entityInfo.timesheetGroupId,
              entityInfo.timesheetId,
            ],
          })
          .then(() => {
            console.log(
              `Query Name with key: ${QueryNames.ProjectTimesheetGroupTimesheets} and key: ${SubQueryNames.changeStatus}, ${entityInfo.projectId}, ${entityInfo.timesheetGroupId}, ${entityInfo.timesheetId} has been cancelled`,
            );
          });
      };
    }
  }, [queryClient, entityInfo, queryResultData]);

  useEffect(() => {
    if (queryResultData) {
      setStatusSelected(
        entityInfo.type === ChangeStatusPopupEntityType.MMR
          ? (queryResultData.status as FrontendMmrStatusEnum)
          : (queryResultData.status as FrontendTimesheetStatusEnum),
      );
    }
  }, [queryResultData, entityInfo.type]);

  return (
    <>
      <PopupStructure
        popupButtons={[
          {
            text: "Cancel",
            buttonType: PopupButtonTypeEnum.neutral,
            action: closeFn,
          },
          {
            text: "Confirm",
            buttonType: PopupButtonTypeEnum.main,
            disabled: statusSelected === queryResultData?.status,
            action: () => {
              onConfirmStatusChange();
            },
          },
        ]}
        isOpen={isOpen}
        closeFn={closeFn}
        headerText={headerText}
        overrideContentContainerStyleClass={classNames(GlobalStyles.flex, GlobalStyles.gap05, ChangeEntityStatusPopupStyles.contentContainer)}
        secondaryHeaderText={secondaryHeaderText}
      >
        {queryResult.isLoading && <LoaderPopup isOpen={true} closeFn={() => {}} />}
        {!queryResult.isLoading &&
          queryResultData &&
          STATUS_ARRAY.map((statusItem) => {
            const castedStatusItem =
              entityInfo.type === ChangeStatusPopupEntityType.MMR
                ? (statusItem as FrontendMmrStatusEnum)
                : (statusItem as FrontendTimesheetStatusEnum);

            if (entityInfo.type === ChangeStatusPopupEntityType.MMR) {
              const mmrData = queryResultData as FrontendProjectEmployeeMmrDetailedType; // Type assertion
              return (
                <div
                  key={statusItem.toString()}
                  className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}
                  onClick={() => setStatusSelected(castedStatusItem)}
                >
                  <ChangeMmrStatusContent
                    statusItem={statusItem as FrontendMmrStatusEnum}
                    projectId={mmrInfo.projectId}
                    projectEmployeeId={mmrInfo.projectEmployeeId}
                    mmrId={mmrInfo.mmrId}
                    currentStatus={mmrData.status as FrontendMmrStatusEnum}
                    statusSelected={statusSelected as FrontendMmrStatusEnum}
                    editDate={mmrData.editDate}
                    editorName={mmrData.editorName}
                    editorCode={mmrData.editorCode}
                    customEditDate={customEditDate}
                    setCustomEditDate={setCustomEditDate}
                  />
                </div>
              );
            } else {
              const timesheetData = queryResultData as FrontendTimesheetType; // Type assertion
              return (
                <div
                  key={statusItem.toString()}
                  className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}
                  onClick={() => setStatusSelected(castedStatusItem)}
                >
                  <ChangeTimesheetStatusContent
                    statusItem={statusItem as FrontendTimesheetStatusEnum}
                    timesheetItem={timesheetData}
                    statusSelected={statusSelected as FrontendTimesheetStatusEnum}
                  />
                </div>
              );
            }
          })}
        {isResponseAlertPopupOpen && responseType && responseObject && (
          <ResponseAlertPopup
            responseType={responseType}
            responseObject={responseObject}
            isOpen={isResponseAlertPopupOpen}
            closeFn={() => {
              initializeResponseAlertPopup();
              onCloseResponseAlertPopup();
              responseType === ApiResponseTypeEnum.success && invalidateQueries();
              responseType === ApiResponseTypeEnum.success && closeFn();
            }}
          />
        )}
        {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      </PopupStructure>
    </>
  );
};
export default ChangeEntityStatusPopup;
