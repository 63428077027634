import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config";
import { FrontendFileRetrievalResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectCtrMappingFile = async (id: number): Promise<FrontendFileRetrievalResponseType> => {
  const params = new URLSearchParams();

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/ctrs-mapping/export`,
    params: params,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const responseData = response.data;

  return {
    fileId: responseData,
    fileName: response.headers["content-disposition"]?.split('filename="')[1].split(".")[0] ?? `manpower-ctr-mapping-${id}.xlsx`,
  };
};
