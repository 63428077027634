export const DEFAULT_SUBSTRING_CHARS_NUM = 15;

export const capitalizeFirstLetter = (inputString: string): string => {
  return inputString ? inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase() : inputString;
};

export const capitalizeAllFirstLetters = (inputString: string, stringSeparator: string = " ", stringConcatenator: string = " "): string => {
  return inputString
    .split(stringSeparator)
    .map((wordItem) => capitalizeFirstLetter(wordItem))
    .join(stringConcatenator);
};

// The format is from full name to first letter of name followed by dot and then the surname
// e.g. Athanasios Mamalis to A.Mamalis
export const formatName = (inputString: string): string => {
  const stringSplit = inputString.split(" ");
  if (stringSplit.length !== 2) {
    return inputString;
  }
  return `${stringSplit[0].charAt(0).toUpperCase()}. ${stringSplit[1].charAt(0).toUpperCase()}${stringSplit[1].slice(1).toLowerCase()}`;
};

export const formatTimesheetActorName = (inputString: string): string => {
  const stringSplit = inputString.split(" ");
  if (stringSplit.length !== 2) {
    return inputString;
  }
  return `${stringSplit[0]} ${stringSplit[1].slice(0, 3)}.`;
};
export const getSubstringBasedOnChars = (inputString: string, limit: number = DEFAULT_SUBSTRING_CHARS_NUM): string => {
  return inputString.length < limit ? inputString : inputString.substring(0, limit) + "...";
};

export const getRouteWithId = (path: string, word: string, id: string): string => {
  const wordIndex = path.split("/").indexOf(word);
  if (wordIndex !== -1) {
    const updatedPath = path.split("/");
    updatedPath[wordIndex] = id;
    return updatedPath.join("/");
  }
  return path;
};

export const getInitials = (entity: string) => {
  return entity
    .split(/\s+/)
    .slice(0, 2)
    .map((word) => word.charAt(0))
    .join("");
};
