import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import TimesheetGroupSingleStyles from "./TimesheetGroupSingleStyles.module.css";
import { EPCMInfoContainerDiv } from "../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";

import plusIcon from "../../../../../assets/images/plus-icon-black.svg";
import plusIconHovered from "../../../../../assets/images/plus-icon-white.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import TimesheetsGroupSettingsIcon from "../../../../../assets/images/timesheet-group-settings-icon.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import TimesheetGroupSettingsPopup from "../../../popups/timesheetgroupsettingspopup/TimesheetGroupSettingsPopup.tsx";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { FrontendGroupsTimesheetModeEnum, FrontendTimesheetStatusEnum } from "../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";

import TimesheetGroupsXIcon from "../../../../../assets/images/timesheet-group-x-icon.svg";
import { AppRoutesPaths } from "../../../../../types/AppRoutesTypes.ts";

import TimesheetFormItem from "./timesheetformitem/TimesheetFormItem.tsx";
import { GroupTimesheetList } from "./grouptimesheetlist/GroupTimesheetsList.tsx";
import { capitalizeFirstLetter } from "../../../../../utils/StringManipulation.ts";
import { ProjectTimesheetTabStatusEnum, TimesheetGroupSingleButtonHoveredTypeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import TimesheetGroupSetNewTimesheetPopup from "../../../popups/timesheetgroupsetnewtimesheetpopup/TimesheetGroupSetNewTimesheetPopup.tsx";

import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useEpcmApiUsers } from "../../../../../apicalls/users/useEpcmApiUsers.ts";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import { DefaultAvatar } from "../../../../../ui/defaultavatar/DefaultAvatar.tsx";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import { useInView } from "react-intersection-observer";
import InfiniteScrollInViewElement from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import { useHandleUnauthorized } from "../../../../../utils/use-handle-unauthorized.ts";
import TimesheetEmptyListMsg from "./timesheetemptylistmsg/TimesheetEmptyListMsg.tsx";

const GROUP_EMPLOYEE_AVATARS_UPPER_LIMIT = 3;

const TimesheetGroupSingle = () => {
  const { projectId, groupId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getTimesheetGroup, getTimesheetGroupTimesheets } = useEpcmApiProjectsTimesheetsGroups();
  const { getUser } = useEpcmApiUsers();
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const [searchParams, setSearchParams] = useSearchParams();
  const [buttonHoveredType, setButtonHoveredType] = useState<TimesheetGroupSingleButtonHoveredTypeEnum | null>(null);
  const displayModeSearchQuery = searchParams.get("mode") || FrontendGroupsTimesheetModeEnum.VIEW;
  const groupTimesheetListStatusSearchQuery = searchParams.get("status") || ProjectTimesheetTabStatusEnum.pending;
  const timesheetIdSearchParam = searchParams.get("timesheetId");
  const isViewMode = useMemo(() => displayModeSearchQuery === FrontendGroupsTimesheetModeEnum.VIEW, [displayModeSearchQuery]);
  const isEditMode = useMemo(() => displayModeSearchQuery === FrontendGroupsTimesheetModeEnum.EDIT, [displayModeSearchQuery]);
  const noParamsGroupUrl = useMemo(() => {
    return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}/${groupId}`;
  }, [projectId, groupId]);

  const timesheetGroupQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetGroups, projectId, groupId],
    queryFn: () => getTimesheetGroup(parseInt(projectId!), parseInt(groupId!), DetailLevel.NORMAL),

    enabled: isAuthorized,
  });

  const groupTimesheetListStatus = useMemo(() => {
    return groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.pending
      ? [FrontendTimesheetStatusEnum.PENDING]
      : groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.prepared
        ? [FrontendTimesheetStatusEnum.PREPARED]
        : groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.reviewed
          ? [FrontendTimesheetStatusEnum.REVIEWED]
          : groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.checked
            ? [FrontendTimesheetStatusEnum.CHECKED]
            : groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.approved
              ? [FrontendTimesheetStatusEnum.APPROVED]
              : [FrontendTimesheetStatusEnum.PENDING];
  }, [groupTimesheetListStatusSearchQuery]);

  const groupTimesheetsQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupId, groupTimesheetListStatusSearchQuery],
    queryFn: ({ pageParam }) =>
      getTimesheetGroupTimesheets(parseInt(projectId!), parseInt(groupId!), groupTimesheetListStatus, PAGINATION_PAGE_SIZE, pageParam).catch(
        handleErrorRedirect,
      ),
    initialPageParam: 1,
    enabled: isAuthorized,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
  });

  const timesheetGroupData = useMemo(() => {
    return timesheetGroupQuery.data ?? undefined;
  }, [timesheetGroupQuery]);

  const groupTimesheetsData = useMemo(() => {
    return groupTimesheetsQuery.data?.pages.flatMap((page) => page.data);
  }, [groupTimesheetsQuery.data]);

  const sectionHeadUserQuery = useQuery({
    queryKey: [QueryNames.Users, timesheetGroupData?.sectionHeadCode],
    queryFn: () => getUser(timesheetGroupData!.sectionHeadCode!).catch(handleErrorRedirect),

    enabled: isAuthorized && !!timesheetGroupData && !!timesheetGroupData.sectionHeadCode,
  });

  const sectionHeadUserData = useMemo(() => {
    return sectionHeadUserQuery.data ?? undefined;
  }, [sectionHeadUserQuery]);

  const handleChangeStatus = useCallback(
    (newStatus: ProjectTimesheetTabStatusEnum) => {
      setSearchParams((currentState) => {
        currentState.set("status", newStatus);
        return currentState;
      });
    },
    [setSearchParams],
  );

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  const onOpenGroupSettings = useCallback(() => {
    onOpenPopup(ProjectsPagePopups.timesheetGroupSettings, popupHandler);
  }, [onOpenPopup, popupHandler]);

  const groupTimesheetListSkeletonLoader = (
    <div
      className={classNames(
        TimesheetGroupSingleStyles.groupTimesheetListSkeletonLoaderContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
      )}
    >
      {["timesheet1", "timesheet2", "timesheet3"].map((key) => (
        <Skeleton key={key} variant="rounded" height={75} width={"100%"} />
      ))}
    </div>
  );

  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);
  const canCreateTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListCreate);
  const canListTimesheetGroups = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupList);
  const canChangeStatusToPrepared = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
  // const canChangeStatusToReviewed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToReviewed);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId, groupId] })
        .then(() => console.log(`In timesheet group with id ${groupId}, ${QueryNames.ProjectTimesheetGroups} query canceled`));
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupId, groupTimesheetListStatusSearchQuery] })
        .then(() => console.log(`In timesheet group with id ${groupId}, ${QueryNames.ProjectTimesheetGroupTimesheets} query canceled`));
    };
  }, [groupId, projectId, queryClient, groupTimesheetListStatusSearchQuery]);

  useEffect(() => {
    return () => {
      timesheetGroupData?.sectionHeadCode &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.Users, timesheetGroupData?.sectionHeadCode] })
          .then(() => console.log(`In timesheet group with id ${groupId}, ${QueryNames.Users} query canceled`));
    };
  }, [groupId, queryClient, timesheetGroupData?.sectionHeadCode]);

  useEffect(() => {
    if (
      inView &&
      !groupTimesheetsQuery.isLoading &&
      !groupTimesheetsQuery.isFetching &&
      !groupTimesheetsQuery.isFetchingNextPage &&
      groupTimesheetsQuery.hasNextPage
    ) {
      void groupTimesheetsQuery.fetchNextPage();
    }
  }, [inView, groupTimesheetsQuery]);

  const handleOpenTimesheetPopup = () => {
    if (timesheetGroupData && timesheetGroupData.projectEmployees.length > 0) {
      onOpenPopup(ProjectsPagePopups.setNewTimesheet, popupHandler);
    }
  };

  return (
    <>
      {isViewMode && !timesheetIdSearchParam ? (
        <div
          className={classNames(
            TimesheetGroupSingleStyles.container,
            groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.pending &&
              groupTimesheetsData &&
              groupTimesheetsData.length >= 0 &&
              GlobalStyles.pendingStatusTimesheetBackground,
            groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.prepared &&
              groupTimesheetsData &&
              groupTimesheetsData.length >= 0 &&
              GlobalStyles.preparedStatusTimesheetBackground,
            groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.checked &&
              groupTimesheetsData &&
              groupTimesheetsData.length >= 0 &&
              GlobalStyles.checkedStatusTimesheetBackground,
            groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.reviewed &&
              groupTimesheetsData &&
              groupTimesheetsData.length >= 0 &&
              GlobalStyles.reviewedStatusTimesheetBackground,
            groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.approved &&
              groupTimesheetsData &&
              groupTimesheetsData.length >= 0 &&
              GlobalStyles.approvedStatusTimesheetBackground,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.overflowHiddenFullHeight,
          )}
        >
          <div className={classNames(TimesheetGroupSingleStyles.infoBar, GlobalStyles.flex, GlobalStyles.gap05)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
              <div
                className={classNames(
                  TimesheetGroupSingleStyles.groupInfoContainer,
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap05,
                )}
              >
                {timesheetGroupQuery.isLoading ? (
                  <>
                    <Skeleton variant={"rounded"} height={16} width={150} />
                    <Skeleton variant={"rounded"} height={16} width={100} />
                  </>
                ) : (
                  <>
                    <div className={classNames(TimesheetGroupSingleStyles.groupTitle)}>{timesheetGroupData?.name}</div>
                    <div className={classNames(TimesheetGroupSingleStyles.groupTitle)}>{"Timesheets"}</div>
                  </>
                )}
              </div>

              {canListTimesheets && (
                <div className={classNames(TimesheetGroupSingleStyles.radioBoxContainer, GlobalStyles.flex, GlobalStyles.gap05)}>
                  {
                    <Link
                      to={`${noParamsGroupUrl}?status=${ProjectTimesheetTabStatusEnum.pending}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}`}
                      className={classNames(
                        groupTimesheetListStatusSearchQuery == ProjectTimesheetTabStatusEnum.pending &&
                          TimesheetGroupSingleStyles.radioBoxItemSelected,
                        GlobalStyles.flex1,
                        GlobalStyles.centerVertical,
                      )}
                      onClick={() =>
                        groupTimesheetListStatusSearchQuery != ProjectTimesheetTabStatusEnum.pending &&
                        handleChangeStatus(ProjectTimesheetTabStatusEnum.pending)
                      }
                    >
                      {capitalizeFirstLetter(ProjectTimesheetTabStatusEnum.pending)}
                    </Link>
                  }

                  {
                    <Link
                      to={`${noParamsGroupUrl}?status=${ProjectTimesheetTabStatusEnum.prepared}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}`}
                      className={classNames(
                        groupTimesheetListStatusSearchQuery == ProjectTimesheetTabStatusEnum.prepared &&
                          TimesheetGroupSingleStyles.radioBoxItemSelected,
                        GlobalStyles.flex1,
                        GlobalStyles.centerVertical,
                      )}
                      onClick={() =>
                        groupTimesheetListStatusSearchQuery != ProjectTimesheetTabStatusEnum.prepared &&
                        handleChangeStatus(ProjectTimesheetTabStatusEnum.prepared)
                      }
                    >
                      {capitalizeFirstLetter(ProjectTimesheetTabStatusEnum.prepared)}
                    </Link>
                  }
                  {
                    <Link
                      to={`${noParamsGroupUrl}?status=${ProjectTimesheetTabStatusEnum.checked}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}`}
                      className={classNames(
                        groupTimesheetListStatusSearchQuery == ProjectTimesheetTabStatusEnum.checked &&
                          TimesheetGroupSingleStyles.radioBoxItemSelected,
                        GlobalStyles.flex1,
                        GlobalStyles.centerVertical,
                      )}
                      onClick={() =>
                        groupTimesheetListStatusSearchQuery != ProjectTimesheetTabStatusEnum.checked &&
                        handleChangeStatus(ProjectTimesheetTabStatusEnum.checked)
                      }
                    >
                      {capitalizeFirstLetter(ProjectTimesheetTabStatusEnum.checked)}
                    </Link>
                  }
                  {
                    <Link
                      to={`${noParamsGroupUrl}?status=${ProjectTimesheetTabStatusEnum.reviewed}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}`}
                      className={classNames(
                        groupTimesheetListStatusSearchQuery == ProjectTimesheetTabStatusEnum.reviewed &&
                          TimesheetGroupSingleStyles.radioBoxItemSelected,
                        GlobalStyles.flex1,
                        GlobalStyles.centerVertical,
                      )}
                      onClick={() =>
                        groupTimesheetListStatusSearchQuery != ProjectTimesheetTabStatusEnum.reviewed &&
                        handleChangeStatus(ProjectTimesheetTabStatusEnum.reviewed)
                      }
                    >
                      {capitalizeFirstLetter(ProjectTimesheetTabStatusEnum.reviewed)}
                    </Link>
                  }
                  {
                    <Link
                      to={`${noParamsGroupUrl}?status=${ProjectTimesheetTabStatusEnum.approved}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}`}
                      className={classNames(
                        groupTimesheetListStatusSearchQuery == ProjectTimesheetTabStatusEnum.approved &&
                          TimesheetGroupSingleStyles.radioBoxItemSelected,
                        GlobalStyles.flex1,
                        GlobalStyles.centerVertical,
                      )}
                      onClick={() =>
                        groupTimesheetListStatusSearchQuery != ProjectTimesheetTabStatusEnum.approved &&
                        handleChangeStatus(ProjectTimesheetTabStatusEnum.approved)
                      }
                    >
                      {capitalizeFirstLetter(ProjectTimesheetTabStatusEnum.approved)}
                    </Link>
                  }
                </div>
              )}
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(TimesheetGroupSingleStyles.buttonsPanel, GlobalStyles.flex, GlobalStyles.gap)}>
              {canCreateTimesheets && (
                <Tooltip
                  title={timesheetGroupData?.projectEmployees.length === 0 ? "No employees in group" : undefined}
                  placement="top"
                  TransitionComponent={Fade}
                  arrow
                >
                  <EPCMInfoContainerDiv
                    className={classNames(
                      groupTimesheetListStatusSearchQuery !== ProjectTimesheetTabStatusEnum.pending &&
                        TimesheetGroupSingleStyles.buttonsPanelButtonGrayBg,
                      timesheetGroupData?.projectEmployees.length === 0
                        ? TimesheetGroupSingleStyles.setNewTimesheetButtonDisabled
                        : TimesheetGroupSingleStyles.setNewTimesheetButton,
                      GlobalStyles.flex,
                      GlobalStyles.flexDirectionColumn,
                      GlobalStyles.gap075,
                      GlobalStyles.overflowHiddenFullHeight,
                    )}
                    onMouseEnter={() => setButtonHoveredType(TimesheetGroupSingleButtonHoveredTypeEnum.add)}
                    onMouseLeave={() => setButtonHoveredType(null)}
                    onClick={handleOpenTimesheetPopup}
                  >
                    <div className={classNames()}>
                      <img
                        className={classNames(TimesheetGroupSingleStyles.setNewTimesheetButtonIconImg)}
                        src={
                          buttonHoveredType === TimesheetGroupSingleButtonHoveredTypeEnum.add &&
                          timesheetGroupData &&
                          timesheetGroupData?.projectEmployees.length > 0
                            ? plusIconHovered
                            : plusIcon
                        }
                        alt="plus"
                      />
                    </div>
                    <div
                      className={classNames(
                        TimesheetGroupSingleStyles.largeButtonTextContainer,
                        GlobalStyles.flex,
                        GlobalStyles.flexDirectionColumn,
                        GlobalStyles.gap025,
                      )}
                    >
                      <div className={classNames(TimesheetGroupSingleStyles.largeButtonSmallerText)}>{"Set new"}</div>
                      <div className={classNames()}>{"Timesheet"}</div>
                    </div>
                  </EPCMInfoContainerDiv>
                </Tooltip>
              )}
              {canListTimesheetGroups && (
                <EPCMInfoContainerDiv
                  className={classNames(
                    groupTimesheetListStatusSearchQuery !== ProjectTimesheetTabStatusEnum.pending &&
                      TimesheetGroupSingleStyles.buttonsPanelButtonGrayBg,
                    GlobalStyles.flex,
                    GlobalStyles.flexDirectionColumn,
                    GlobalStyles.gap,
                    GlobalStyles.overflowHiddenFullHeight,
                    GlobalStyles.elementWithCursor,
                  )}
                  onClick={onOpenGroupSettings}
                >
                  <div className={classNames(TimesheetGroupSingleStyles.TimesheetGroupMembersContainer)}>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, TimesheetGroupSingleStyles.largeButtonBolderText)}>
                        {"Group Settings"}
                      </div>
                      <div className={classNames(GlobalStyles.centerVertical)}>
                        <img className={classNames(TimesheetGroupSingleStyles.largeButtonIconImg)} src={TimesheetsGroupSettingsIcon} alt="settings" />
                      </div>
                    </div>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap2)}>
                      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                        <div className={classNames(TimesheetGroupSingleStyles.largeButtonSmallerText)}>{"Section Head"}</div>
                        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                          {timesheetGroupQuery.isLoading || (timesheetGroupData?.sectionHeadCode && sectionHeadUserQuery.isLoading) ? (
                            <>
                              <Skeleton variant={"circular"} height={25} width={25} />
                              <div className={classNames(GlobalStyles.centerVertical)}>
                                <Skeleton variant={"rounded"} height={20} width={180} />
                              </div>
                            </>
                          ) : timesheetGroupData && !timesheetGroupData.sectionHeadCode ? (
                            <Tooltip TransitionComponent={Fade} title={"No section head"} placement="top" arrow>
                              <div className={classNames(GlobalStyles.emptyListMsgSmallNoPadding)}>{"- -"}</div>
                            </Tooltip>
                          ) : (
                            <>
                              <div className={classNames(TimesheetGroupSingleStyles.imgContainer)}>
                                <DefaultAvatar avatarSizeInEm={1.6} avatarImgSizeInEm={0.9} />
                              </div>
                              <div className={classNames(GlobalStyles.centerVertical)}>{sectionHeadUserData?.name}</div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                        <div className={classNames(TimesheetGroupSingleStyles.largeButtonSmallerText)}>{"Group members"}</div>
                        <div className={classNames(GlobalStyles.flex)}>
                          {timesheetGroupQuery.isLoading && <Skeleton variant={"rounded"} height={25} width={100} />}
                          {timesheetGroupData && timesheetGroupData.projectEmployees.length === 0 && (
                            <Tooltip TransitionComponent={Fade} title={"No employees"} placement="top" arrow>
                              <div className={classNames(GlobalStyles.emptyListMsgSmallNoPadding)}>{"- -"}</div>
                            </Tooltip>
                          )}
                          {timesheetGroupData &&
                            timesheetGroupData.projectEmployees.slice(0, GROUP_EMPLOYEE_AVATARS_UPPER_LIMIT).map((employeeItem, index) => {
                              const style = {
                                marginLeft: index > 0 ? "-5px" : "0",
                                zIndex: index,
                              };
                              return (
                                <div className={classNames(GlobalStyles.flex)} key={employeeItem.id} style={style}>
                                  <div className={classNames(TimesheetGroupSingleStyles.imgContainer)}>
                                    <DefaultAvatar avatarSizeInEm={1.6} avatarImgSizeInEm={0.9} />
                                  </div>
                                  {timesheetGroupData.projectEmployees.length > GROUP_EMPLOYEE_AVATARS_UPPER_LIMIT &&
                                    index === GROUP_EMPLOYEE_AVATARS_UPPER_LIMIT - 1 && (
                                      <div
                                        className={classNames(
                                          TimesheetGroupSingleStyles.remainingMembersDiv,
                                          TimesheetGroupSingleStyles.imgContainer,
                                          GlobalStyles.flex,
                                          GlobalStyles.centerHorizontal,
                                          GlobalStyles.centerVertical,
                                        )}
                                      >
                                        {`+${timesheetGroupData.projectEmployees.length - GROUP_EMPLOYEE_AVATARS_UPPER_LIMIT}`}
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </EPCMInfoContainerDiv>
              )}
              <Link
                to={`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}`}
              >
                <div
                  className={classNames(TimesheetGroupSingleStyles.closeContentButton, GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
                >
                  <img src={TimesheetGroupsXIcon} alt={"close-icon"}></img>
                </div>
              </Link>
            </div>
          </div>

          {groupTimesheetsQuery.isLoading
            ? groupTimesheetListSkeletonLoader
            : groupTimesheetsData && (
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight)}>
                  {(groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.pending && groupTimesheetsData.length > 0) ||
                  (groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.prepared && groupTimesheetsData.length > 0) ||
                  (groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.checked && groupTimesheetsData.length > 0) ||
                  (groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.reviewed && groupTimesheetsData.length > 0) ||
                  (groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.approved && groupTimesheetsData.length > 0) ? (
                    <>
                      <GroupTimesheetList timesheetListStatus={groupTimesheetListStatusSearchQuery} groupTimesheetList={groupTimesheetsData}>
                        <InfiniteScrollInViewElement
                          key={"InfiniteScrollInViewElement"}
                          reference={ref}
                          infiniteQueryResult={groupTimesheetsQuery}
                          loaderComponent={<Skeleton variant={"rounded"} height={50} width={"100%"}></Skeleton>}
                        />
                        <div className={classNames(GlobalStyles.flex1)} />
                      </GroupTimesheetList>
                    </>
                  ) : (groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.reviewed ||
                      groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.checked ||
                      groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.pending ||
                      groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.approved ||
                      groupTimesheetListStatusSearchQuery === ProjectTimesheetTabStatusEnum.prepared) &&
                    groupTimesheetsData.length === 0 ? (
                    <div
                      className={classNames(
                        GlobalStyles.flex,
                        GlobalStyles.flex1,
                        GlobalStyles.flexDirectionColumn,
                        GlobalStyles.centerVertical,
                        GlobalStyles.centerHorizontal,
                        GlobalStyles.gap3,
                      )}
                    >
                      <TimesheetEmptyListMsg timesheetListStatus={groupTimesheetListStatusSearchQuery} />
                    </div>
                  ) : (
                    <div
                      className={classNames(
                        TimesheetGroupSingleStyles.noPendingPanel,
                        GlobalStyles.flex,
                        GlobalStyles.flex1,
                        GlobalStyles.flexDirectionColumn,
                        GlobalStyles.centerVertical,
                        GlobalStyles.centerHorizontal,
                        GlobalStyles.gap3,
                      )}
                    >
                      {groupTimesheetListStatusSearchQuery === FrontendTimesheetStatusEnum.PENDING &&
                        canChangeStatusToPrepared &&
                        canCreateTimesheets && (
                          <>
                            <TimesheetEmptyListMsg timesheetListStatus={ProjectTimesheetTabStatusEnum.pending} />
                            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                              <div
                                className={classNames(TimesheetGroupSingleStyles.actionText, GlobalStyles.elementWithCursor)}
                                onClick={() => onOpenPopup(ProjectsPagePopups.setNewTimesheet, popupHandler)}
                              >
                                {"Create New"}
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  )}
                </div>
              )}

          {popupHandler.get(ProjectsPagePopups.setNewTimesheet)!.isOpen && (
            <TimesheetGroupSetNewTimesheetPopup
              projectId={Number(projectId)}
              groupId={Number(groupId)!}
              isOpen={popupHandler.get(ProjectsPagePopups.setNewTimesheet)!.isOpen}
              closeFn={() => onClosePopup(ProjectsPagePopups.setNewTimesheet, popupHandler)}
              headerText={popupHeaders.get(ProjectsPagePopups.setNewTimesheet)}
              secondaryHeaderText={timesheetGroupData?.name ?? ""}
            />
          )}
          {popupHandler.get(ProjectsPagePopups.timesheetGroupSettings)!.isOpen && (
            <TimesheetGroupSettingsPopup
              isOpen={popupHandler.get(ProjectsPagePopups.timesheetGroupSettings)!.isOpen}
              projectId={Number(projectId!)}
              timesheetGroupId={Number(groupId!)}
              closeFn={() => onClosePopup(ProjectsPagePopups.timesheetGroupSettings, popupHandler)}
              headerText={timesheetGroupData?.name ?? popupHeaders.get(ProjectsPagePopups.timesheetGroupSettings)}
              redirectLink={`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}`}
            />
          )}
        </div>
      ) : (isViewMode || isEditMode) && timesheetIdSearchParam ? (
        <div
          className={classNames(
            TimesheetGroupSingleStyles.container,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.overflowHiddenFullHeight,
          )}
        >
          <TimesheetFormItem timesheetItemId={parseInt(timesheetIdSearchParam)} />
        </div>
      ) : (
        <div className={classNames(GlobalStyles.flex)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.emptyListMsg)}>{"Something went wrong"}</div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
      )}
    </>
  );
};

export default TimesheetGroupSingle;
