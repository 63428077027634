import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App.tsx";
import { Employees } from "./pages/employees/Employees.tsx";
import { Projects } from "./pages/projects/Projects.tsx";
import { AppRoutesPaths, DEFAULT_ROUTE, TimesheetButtonsType, TimesheetsExtraRouteHandleData } from "./types/AppRoutesTypes.ts";
import { GlobalConfigurations } from "./pages/settings/globalconfigurations/GlobalConfigurations.tsx";
import { FileUploader } from "./fileuploader/FileUploader.tsx";
import { Project } from "./pages/projects/project/Project.tsx";
import { ProjectEmployees } from "./pages/projects/project/projectemployees/ProjectEmployees.tsx";
import { ManpowerPlan } from "./pages/projects/project/manpowerplan/ManpowerPlan.tsx";
import { Timesheets } from "./pages/projects/project/timesheets/Timesheets.tsx";
import TimesheetGroups from "./pages/projects/project/timesheets/timesheetgroups/TimesheetGroups.tsx";
import TimesheetGroupSingle from "./pages/projects/project/timesheets/timesheetgroupsingle/TimesheetGroupSingle.tsx";
import TimesheetPeriods from "./pages/projects/project/timesheets/timesheetsperiods/TimesheetPeriods.tsx";
import ConfigurationMenu from "./pages/projects/project/configurationmenu/ConfigurationMenu.tsx";

import { ProjectTabs } from "./pages/projects/project/ProjectTabs.tsx";
import TimesheetReports from "./pages/projects/project/timesheets/timesheetreports/TimesheetReports.tsx";

import { ConfigurationTitle } from "./pages/projects/project/configurationmenu/configurationtitle/ConfigurationTitle.tsx";
import { BackRouteType } from "./pages/projects/project/configurationmenu/configurationtitle/BackRouteType.tsx";
import ConfigurationProjectInfo from "./pages/projects/project/configurationprojectinfo/ConfigurationProjectInfo.tsx";
import ConfigurationTrades from "./pages/projects/project/configuration/configurationtrades/ConfigurationTrades.tsx";
import CtrMapping from "./pages/projects/project/configuration/ctrmanagement/ctrmapping/CtrMapping.tsx";
import ConfigurationPositions from "./pages/projects/project/configuration/configurationpositions/ConfigurationPositions.tsx";
import ConfigurationLocations from "./pages/projects/project/configuration/configurationlocations/ConfigurationLocations.tsx";
import ConfigurationCategories from "./pages/projects/project/configuration/configurationcategories/ConfigurationCategories.tsx";
import ConfigurationDisciplines from "./pages/projects/project/configuration/configurationdisciplines/ConfigurationDisciplines.tsx";
import ConfigurationManpowerPositions from "./pages/projects/project/configuration/configurationmanpowerpositions/ConfigurationManpowerPositions.tsx";
import CtrManagement from "./pages/projects/project/configuration/ctrmanagement/CtrManagement.tsx";
import ConfigurationCtrLibrary from "./pages/projects/project/configuration/ctrmanagement/configurationctrlibrary/ConfigurationCtrLibrary.tsx";
import GlobalConfigurationTitle from "./pages/settings/globalconfigurations/globalconfigurationtitle/GlobalConfigurationTitle.tsx";
import { GlobalConfigurationBackRouteType } from "./types/settings/GlobalConfigurationsBackRouteTypes.ts";
import GlobalConfigurationLocations from "./pages/settings/globalconfigurations/globalconfigurationlocations/GlobalConfigurationLocations.tsx";
import GlobalConfigurationPositions from "./pages/settings/globalconfigurations/globalconfigurationspositions/GlobalConfigurationPositions.tsx";
import GlobalConfigurationTrades from "./pages/settings/globalconfigurations/globalconfigurationtrades/GlobalConfigurationTrades.tsx";
import GlobalConfigurationCategories from "./pages/settings/globalconfigurations/globalconfigurationcategories/GlobalConfigurationCategories.tsx";
import GlobalConfigurationDisciplines from "./pages/settings/globalconfigurations/globalconfigurationsdisciplines/GlobalConfigurationDisciplines.tsx";
import GlobalConfigurationCtrLibrary from "./pages/settings/globalconfigurations/globalconfigurationctrlibrary/GlobalConfigurationCtrLibrary.tsx";
import { CalendarManagement } from "./pages/projects/project/configuration/calendarmanagement/CalendarManagement.tsx";
import CalendarManagementSingle from "./pages/projects/project/configuration/calendarmanagementsingle/CalendarManagementSingle.tsx";
import ProjectUserDocuments from "./pages/projects/project/projectuserdocuments/ProjectUserDocuments.tsx";
import ConfigurationPermissions from "./pages/projects/project/configuration/configurationpermissions/ConfigurationPermissions.tsx";
import GlobalConfigurationPermissions from "./pages/settings/globalconfigurations/globalconfigurationpermissions/GlobalConfigurationPermissions.tsx";
import ProjectRolesAuthenticator from "./auth/ProjectRolesAuthenticator.tsx";
import Unauthorized from "./pages/unauthorized/Unauthorized.tsx";
import { useRetrieveUserPermittedActions } from "./utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "./types/Roles.ts";
import { useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { EPCM_SENTRY_DSN, EPCM_SENTRY_ENVIRONMENT } from "./apicalls/config.ts";
import GlobalConfigurationHolidays from "./pages/settings/globalconfigurations/globalconfigurationholidays/GlobalConfigurationHolidays.tsx";
import ConfigurationHolidays from "./pages/projects/project/configuration/configurationholidays/ConfigurationHolidays.tsx";

export const EPCMApp = () => {
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const canListProjectEmployees = canPerformProjectAction(ProjectAction.ProjectEmployeeList);
  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);
  const canListManpowerPlan = canPerformProjectAction(ProjectAction.ProjectManpowerList);
  const canListConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canUpdateConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);
  const canUpdatePermissions = canPerformProjectAction(ProjectAction.ProjectEditPermissions);

  Sentry.init({
    environment: EPCM_SENTRY_ENVIRONMENT,
    dsn: EPCM_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    tracesSampleRate: 0.05, // Set tracesSampleRate to 0.05 to capture 5% of transactions

    tracePropagationTargets: ["localhost", "https://epcmnodetest.archirodon.net", "https://epcm.archirodon.net/"],
  });

  const projectHomePageTab: AppRoutesPaths | string = useMemo(() => {
    if (canListProjectEmployees) {
      return AppRoutesPaths.projectSingleEmployees;
    } else if (canListTimesheets) {
      return AppRoutesPaths.projectSingleTimesheets;
    } else if (canListManpowerPlan) {
      return AppRoutesPaths.projectSingleManpowerPlan;
    }
    if (canListConfigurations || canUpdateConfigurations || canUpdatePermissions) {
      return AppRoutesPaths.configurations;
    }

    return "/";
  }, [canListProjectEmployees, canListTimesheets, canListManpowerPlan]);

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      path: AppRoutesPaths.home,
      element: <App />,
      children: [
        {
          path: AppRoutesPaths.home,
          element: <Navigate to={DEFAULT_ROUTE} replace />,
        },
        {
          path: AppRoutesPaths.configurations,
          children: [
            {
              index: true,
              element: <GlobalConfigurations />,
            },
            {
              element: <GlobalConfigurationTitle routeType={GlobalConfigurationBackRouteType.BackToGlobalConfigurations} />,
              children: [
                {
                  path: AppRoutesPaths.configurationsLocations,
                  element: <GlobalConfigurationLocations />,
                },
                {
                  path: AppRoutesPaths.configurationsPositions,
                  element: <GlobalConfigurationPositions />,
                },
                {
                  path: AppRoutesPaths.configurationsTrades,
                  element: <GlobalConfigurationTrades />,
                },
                {
                  path: AppRoutesPaths.configurationsCategories,
                  element: <GlobalConfigurationCategories />,
                },
                {
                  path: AppRoutesPaths.configurationsDisciplines,
                  element: <GlobalConfigurationDisciplines />,
                },
                {
                  path: AppRoutesPaths.configurationsCtrLibrary,
                  element: <GlobalConfigurationCtrLibrary />,
                },
                {
                  path: AppRoutesPaths.configurationsPermissions,
                  element: <GlobalConfigurationPermissions />,
                },
                {
                  path: AppRoutesPaths.configurationsHolidays,
                  element: <GlobalConfigurationHolidays />,
                },
              ],
            },
          ],
        },
        {
          path: AppRoutesPaths.userPermissions,
          element: <GlobalConfigurationPermissions />,
        },
        {
          path: AppRoutesPaths.fileUploader,
          element: <FileUploader />,
        },

        {
          path: AppRoutesPaths.projects,
          children: [
            {
              index: true,
              element: <Projects />,
            },
            {
              path: AppRoutesPaths.projectSingle,
              element: (
                <ProjectRolesAuthenticator>
                  <Project />
                </ProjectRolesAuthenticator>
              ),
              children: [
                {
                  element: <ProjectTabs />,
                  children: [
                    {
                      index: true,
                      element: <Navigate to={projectHomePageTab} replace />,
                    },
                    {
                      path: AppRoutesPaths.projectSingleEmployees,
                      element: <ProjectEmployees />,
                      handle: { isProjectEmployees: true } as TimesheetsExtraRouteHandleData,
                    },
                    {
                      path: AppRoutesPaths.projectSingleManpowerPlan,
                      element: <ManpowerPlan />,
                      handle: { isManpowerPlan: true } as TimesheetsExtraRouteHandleData,
                    },
                    {
                      path: `${AppRoutesPaths.projectSingleTimesheets}/*`,
                      element: <Timesheets />,
                      handle: { isTimesheets: true } as TimesheetsExtraRouteHandleData,
                      children: [
                        {
                          index: true,
                          element: <TimesheetPeriods keepCurrentPeriodOnly={true} />,
                          handle: {
                            showTimesheetExtraComponents: TimesheetButtonsType.ListButtons,
                            isTimesheets: true,
                          } as TimesheetsExtraRouteHandleData,
                        },
                        {
                          path: AppRoutesPaths.projectSingleTimesheetsGroups,
                          element: <TimesheetGroups />,
                          handle: {
                            showTimesheetExtraComponents: TimesheetButtonsType.GroupButtons,
                            isTimesheets: true,
                          } as TimesheetsExtraRouteHandleData,
                          children: [
                            {
                              path: AppRoutesPaths.projectSingleTimesheetsGroupsSingle,
                              element: <TimesheetGroupSingle />,
                            },
                          ],
                        },
                        {
                          path: AppRoutesPaths.projectSingleTimesheetsReports,
                          handle: {
                            showTimesheetExtraComponents: TimesheetButtonsType.ReportButtons,
                            isTimesheets: true,
                          } as TimesheetsExtraRouteHandleData,
                          element: <TimesheetReports />,
                        },
                        {
                          path: AppRoutesPaths.projectSingleTimesheetsArchive,
                          handle: {
                            showTimesheetExtraComponents: TimesheetButtonsType.ListButtons,
                            isTimesheets: true,
                          } as TimesheetsExtraRouteHandleData,
                          element: <TimesheetPeriods keepCurrentPeriodOnly={false} />,
                        },
                      ],
                    },
                    {
                      path: AppRoutesPaths.projectSingleDocuments,
                      handle: { isDocuments: true } as TimesheetsExtraRouteHandleData,
                      element: <ProjectUserDocuments />,
                    },
                  ],
                },
                {
                  path: AppRoutesPaths.configurations,
                  children: [
                    {
                      index: true,
                      element: <ConfigurationMenu />,
                    },
                    {
                      element: <ConfigurationTitle routeType={BackRouteType.BackToConfig} />,
                      children: [
                        {
                          path: AppRoutesPaths.calendarManagement,
                          element: <CalendarManagement />,
                          children: [
                            {
                              path: AppRoutesPaths.calendarManagementSingle,
                              element: <CalendarManagementSingle />,
                            },
                          ],
                        },
                        {
                          path: AppRoutesPaths.projectConfigurationsMapping,
                          element: <CtrMapping />,
                        },

                        {
                          path: AppRoutesPaths.configurationsPositions,
                          element: <ConfigurationPositions />,
                        },
                        {
                          path: AppRoutesPaths.configurationsTrades,
                          element: <ConfigurationTrades />,
                        },
                        {
                          path: AppRoutesPaths.configurationsLocations,
                          element: <ConfigurationLocations />,
                        },
                        {
                          path: AppRoutesPaths.configurationsCategories,
                          element: <ConfigurationCategories />,
                        },
                        {
                          path: AppRoutesPaths.configurationsDisciplines,
                          element: <ConfigurationDisciplines />,
                        },
                        {
                          path: AppRoutesPaths.projectConfigurationsManpowerPositions,
                          element: <ConfigurationManpowerPositions />,
                        },
                        {
                          path: AppRoutesPaths.configurationsHolidays,
                          element: <ConfigurationHolidays />,
                        },
                        {
                          path: AppRoutesPaths.configurationsCtrManagement,
                          element: <CtrManagement />,
                          children: [
                            {
                              index: true,
                              element: <Navigate to={AppRoutesPaths.projectConfigurationsLibrary} replace />,
                            },
                            {
                              path: AppRoutesPaths.projectConfigurationsMapping,
                              element: <CtrMapping />,
                            },
                            {
                              path: AppRoutesPaths.projectConfigurationsLibrary,
                              element: <ConfigurationCtrLibrary />,
                            },
                          ],
                        },
                        {
                          path: AppRoutesPaths.configurationsPermissions,
                          element: <ConfigurationPermissions />,
                        },
                        {
                          path: AppRoutesPaths.projectConfigurationsInfo,
                          element: <ConfigurationProjectInfo />,
                        },
                        {
                          path: AppRoutesPaths.calendarManagement,
                          element: <CalendarManagement />,
                          children: [
                            {
                              path: AppRoutesPaths.calendarManagementSingle,
                              element: <CalendarManagementSingle />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: AppRoutesPaths.employees,
          element: <Employees />,
        },
        {
          path: AppRoutesPaths.unauthorized,
          element: <Unauthorized />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
