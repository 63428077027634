import classNames from "classnames";
import ConfigurationHolidaysStyles from "./ConfigurationHolidaysPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { convertDateToMilSecs } from "../../../../../../utils/DateManipulation.ts";

import { PopupButtonTypeEnum, PopupType } from "../../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { UiComponentGeneralEnum } from "../../../../../../types/UiComponentGeneralType.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import { FrontendProjectHolidayType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { ApiRequestBodyHolidayCreate, ApiRequestBodyHolidayUpdate } from "epcm-common/dist/Types/HolidayTypes";
import { PopupStructure } from "../../../../../../ui/popupstructure/PopupStructure.tsx";
import EpcmDatepickerMui from "../../../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { AntSwitch } from "../../../../../../ui/epcmswitch/EpcmSwitch.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";

interface ProjectConfigurationHolidaysPopupProps extends PopupType {
  projectHolidayItem?: FrontendProjectHolidayType;
  mode: configurationsPopupType;
}

const ProjectConfigurationHolidaysPopup: FC<ProjectConfigurationHolidaysPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  projectHolidayItem,
  mode,
}) => {
  const { projectId } = useParams();

  const [projectHolidayDescription, setProjectHolidayDescription] = useState<string>(projectHolidayItem?.description || "");
  const [projectHolidayStartDate, setProjectHolidayStartDate] = useState<Date | null>(projectHolidayItem?.startDate || null);
  const [projectHolidayEndDate, setProjectHolidayEndDate] = useState<Date | null>(projectHolidayItem?.endDate || null);
  const [projectHolidayIsRecurring, setProjectHolidayIsRecurring] = useState<boolean>(projectHolidayItem?.isRecurring || false);
  const [projectHolidayIsDefault, setProjectHolidayIsDefault] = useState<boolean>(projectHolidayItem?.setAsDefault || false);

  useEffect(() => {
    if (projectHolidayItem) {
      setProjectHolidayDescription(projectHolidayItem.description);
      setProjectHolidayStartDate(projectHolidayItem.startDate);
      setProjectHolidayEndDate(projectHolidayItem.endDate);
      setProjectHolidayIsRecurring(projectHolidayItem.isRecurring);
      setProjectHolidayIsDefault(projectHolidayItem.setAsDefault);
    }
  }, [
    projectHolidayItem,
    setProjectHolidayDescription,
    setProjectHolidayStartDate,
    setProjectHolidayEndDate,
    setProjectHolidayIsRecurring,
    setProjectHolidayIsDefault,
  ]);

  const { useUpdateProjectEntityMutation, useCreateProjectEntityMutation } = useEpcmApiProjectUtilsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createProjectHolidayMutation = useCreateProjectEntityMutation(Number(projectId!), FrontendProjectConfigurationsEntityEnum.Holiday, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Holiday was added successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateProjectHoliday = useCallback(() => {
    createProjectHolidayMutation.mutate({
      description: projectHolidayDescription,
      startDateTimestamp: convertDateToMilSecs(projectHolidayStartDate!),
      endDateTimestamp: projectHolidayEndDate ? convertDateToMilSecs(projectHolidayEndDate) : undefined,
      isRecurring: projectHolidayIsRecurring,
      setAsDefault: projectHolidayIsDefault,
    } satisfies ApiRequestBodyHolidayCreate);
    onOpenLoaderPopup();
  }, [
    createProjectHolidayMutation,
    projectHolidayDescription,
    projectHolidayStartDate,
    projectHolidayEndDate,
    projectHolidayIsRecurring,
    projectHolidayIsDefault,
    onOpenLoaderPopup,
  ]);

  const updateProjectHolidayMutation = useUpdateProjectEntityMutation(
    Number(projectId!),
    projectHolidayItem?.id ?? 0,
    FrontendProjectConfigurationsEntityEnum.Holiday,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Holiday was updated successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const onUpdateProjectHoliday = useCallback(() => {
    updateProjectHolidayMutation.mutate({
      description: projectHolidayDescription,
      startDateTimestamp: convertDateToMilSecs(projectHolidayStartDate!),
      endDateTimestamp: projectHolidayEndDate ? convertDateToMilSecs(projectHolidayEndDate) : undefined,
      isRecurring: projectHolidayIsRecurring,
      setAsDefault: projectHolidayIsDefault,
    } satisfies ApiRequestBodyHolidayUpdate);
    onOpenLoaderPopup();
  }, [
    updateProjectHolidayMutation,
    projectHolidayDescription,
    projectHolidayStartDate,
    projectHolidayEndDate,
    projectHolidayIsRecurring,
    projectHolidayIsDefault,
    onOpenLoaderPopup,
  ]);

  const invalidDates = useMemo(
    () => !!projectHolidayEndDate && !!projectHolidayStartDate && projectHolidayEndDate < projectHolidayStartDate,
    [projectHolidayEndDate, projectHolidayStartDate],
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectHolidays] });
  }, [queryClient]);

  const isEitherInputEmpty = useMemo(
    () => !projectHolidayDescription || !projectHolidayStartDate,
    [projectHolidayDescription, projectHolidayStartDate],
  );
  const hasChanges = useMemo(
    () =>
      projectHolidayDescription !== projectHolidayItem?.description ||
      projectHolidayStartDate?.getTime() !== projectHolidayItem?.startDate.getTime() ||
      projectHolidayEndDate?.getTime() !== projectHolidayItem?.endDate?.getTime() ||
      projectHolidayIsRecurring !== projectHolidayItem?.isRecurring ||
      projectHolidayIsDefault !== projectHolidayItem?.setAsDefault,
    [
      projectHolidayDescription,
      projectHolidayStartDate,
      projectHolidayEndDate,
      projectHolidayIsRecurring,
      projectHolidayIsDefault,
      projectHolidayItem?.description,
      projectHolidayItem?.startDate,
      projectHolidayItem?.endDate,
      projectHolidayItem?.isRecurring,
      projectHolidayItem?.setAsDefault,
    ],
  );

  useEffect(() => {
    if (projectHolidayStartDate && mode === configurationsPopupType.create && !projectHolidayEndDate) {
      setProjectHolidayEndDate(projectHolidayStartDate);
    }
    if (projectHolidayStartDate && projectHolidayEndDate && projectHolidayEndDate < projectHolidayStartDate) {
      setProjectHolidayEndDate(projectHolidayStartDate);
    }
  }, [projectHolidayStartDate, mode, projectHolidayEndDate]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationHolidaysStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Holiday" : "Create New Holiday",
          tooltipText: isEitherInputEmpty
            ? "Please fill all required fields"
            : !hasChanges
              ? "No changes"
              : invalidDates
                ? "End Date must be after Start Date"
                : "",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isEitherInputEmpty && onUpdateProjectHoliday();
            } else {
              !isEitherInputEmpty && onCreateProjectHoliday();
            }
          },
          disabled: isEitherInputEmpty || !hasChanges || invalidDates,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationHolidaysStyles.mainContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationHolidaysStyles.infoText)}>Holiday Description</div>
          <input
            placeholder={"Type a holiday description..."}
            className={classNames(ConfigurationHolidaysStyles.infoContainer, ConfigurationHolidaysStyles.infoContainerInput)}
            required
            value={projectHolidayDescription}
            onChange={(e) => setProjectHolidayDescription(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationHolidaysStyles.infoText)}>Holiday Start Date</div>
          <EpcmDatepickerMui
            label={""}
            placeholder={"Select Start Date"}
            date={projectHolidayStartDate}
            setDate={setProjectHolidayStartDate}
            theme={UiComponentGeneralEnum.SECONDARY}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationHolidaysStyles.infoText)}>Holiday End Date (Optional)</div>
          <Tooltip title={!projectHolidayStartDate ? "Cant select End Date before Start Date" : ""} placement="left" arrow>
            <div>
              <EpcmDatepickerMui
                label={""}
                placeholder={!projectHolidayStartDate ? "Select Start Date first" : "Select End Date"}
                disabled={!projectHolidayStartDate}
                date={projectHolidayEndDate}
                setDate={setProjectHolidayEndDate}
                theme={!projectHolidayStartDate ? UiComponentGeneralEnum.FOURTH : UiComponentGeneralEnum.SECONDARY}
                minDate={projectHolidayStartDate ?? undefined}
              />
            </div>
          </Tooltip>
        </div>

        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
            <div className={classNames(ConfigurationHolidaysStyles.infoText)}>Recurring</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

            <AntSwitch
              type={"checkbox"}
              className={classNames(ConfigurationHolidaysStyles.infoContainer, ConfigurationHolidaysStyles.antSwitchContainer)}
              checked={projectHolidayIsRecurring}
              onChange={() => setProjectHolidayIsRecurring((prev) => !prev)}
            ></AntSwitch>
          </div>
          <div className={classNames(ConfigurationHolidaysStyles.helperText)}>{`(Recurring holidays are repeated every year)`}</div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
            <div className={classNames(ConfigurationHolidaysStyles.infoText)}>Default</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            <AntSwitch
              type={"checkbox"}
              className={classNames(ConfigurationHolidaysStyles.infoContainer, ConfigurationHolidaysStyles.antSwitchContainer)}
              checked={projectHolidayIsDefault}
              onChange={() => setProjectHolidayIsDefault((prev) => !prev)}
            ></AntSwitch>
          </div>
          <div className={classNames(ConfigurationHolidaysStyles.helperText)}>
            {`(Default holidays are pre-selected in the calendar creation process)`}
          </div>
        </div>
      </div>

      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ProjectConfigurationHolidaysPopup;
