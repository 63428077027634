import { FC, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import GroupMemberItemStyles from "./GroupMemberItemStyles.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import TimesheetGroupSettingsRemoveMemberIcon from "../../../../../assets/images/timesheet-group-settings-remove-user-icon.svg";
import TimesheetGroupSettingsRemoveMemberHoveredIcon from "../../../../../assets/images/timesheet-group-settings-remove-user-hover-icon.svg";
//import TimesheetWrenchIcon from "../../../../../assets/images/timesheet-wrench-icon.svg";
//import TimesheetLocationIcon from "../../../../../assets/images/timesheet-location-icon.svg";
import { useEpcmApiProjectEmployee } from "../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendProjectEmployeeBasicType } from "../../../../../types/projects/ProjectEmployeesTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { Fade, Tooltip } from "@mui/material";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import EmployeeNameSkeletonLoader from "../loaders/EmployeeNameSkeletonLoader.tsx";
import CodeSkeletonLoader from "../loaders/CodeSkeletonLoader.tsx";
import TimesheetGroupMemberSectionGrayIcon from "../../../../../assets/images/timesheet-group-member-section-gray-icon.svg";
import { DefaultAvatar } from "../../../../../ui/defaultavatar/DefaultAvatar.tsx";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import { FrontendTimesheetGroupManagerialEntity, ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";

interface GroupMemberItemProps {
  projectEmployeeId: number;
  projectId: number;
  groupId: number;
  managerType?: FrontendTimesheetGroupManagerialEntity;
  setPopupMode?: (popupType: ProjectTimesheetGroupPopupModeEnum) => void;
  globalUserName?: string;
  globalUserCode?: string;
  canBeDeleted: boolean;
}

const GroupMemberItem: FC<GroupMemberItemProps> = ({
  projectEmployeeId,
  projectId,
  groupId,
  managerType,
  setPopupMode,
  globalUserName,
  globalUserCode,
  canBeDeleted,
}) => {
  const [isDeleteMemberButtonHovered, setIsDeleteMemberButtonHovered] = useState<boolean>(false);
  const { getSingleProjectEmployee } = useEpcmApiProjectEmployee();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const singleProjectEmployeeQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployee, projectEmployeeId, groupId],
    queryFn: () => getSingleProjectEmployee(projectId, projectEmployeeId, DetailLevel.BASIC),

    enabled: isAuthorized && !globalUserCode,
    select: (data) => data as FrontendProjectEmployeeBasicType,
  });

  const singleProjectEmployeeData = useMemo(() => {
    return singleProjectEmployeeQuery.data ? singleProjectEmployeeQuery.data : null;
  }, [singleProjectEmployeeQuery]);

  const canUpdateTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupUpdate);

  useEffect(() => {
    if (!globalUserCode) {
      return;
    }
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectEmployee, projectEmployeeId, groupId] })
        .then(() => console.log(`In project with id ${projectId}, ${QueryNames.ProjectEmployee} query canceled`));
    };
  }, [queryClient, globalUserCode, projectEmployeeId, projectId, groupId]);

  const { useDeleteTimesheetGroupEmployeeMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const deleteTimesheetGroupEmployeesMutation = useDeleteTimesheetGroupEmployeeMutation(projectId, groupId, projectEmployeeId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project Employee was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetGroupEmployees] });
    void queryClient.resetQueries({ queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, projectId, groupId] });
    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, SubQueryNames.list, projectId, groupId],
    });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId, groupId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId.toString()] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetGroupEmployees, projectId, groupId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetGroupEmployees, projectId, groupId, SubQueryNames.list] });
    void queryClient.invalidateQueries({ queryKey: [[QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, SubQueryNames.count]] });
  }, [queryClient, projectId, groupId]);

  return (
    <>
      <div key={projectEmployeeId} className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GroupMemberItemStyles.container)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap, GroupMemberItemStyles.mainContainer)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.centerVertical)}>
              <DefaultAvatar avatarImgSizeInEm={1.4} avatarSizeInEm={2.3} />
            </div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap2, GroupMemberItemStyles.infoContainerItem)}>
            {!managerType ? (
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerVertical,
                  GlobalStyles.gap025,
                  GroupMemberItemStyles.infoContainerItemName,
                )}
              >
                {singleProjectEmployeeData ? (
                  <div className={classNames(GroupMemberItemStyles.infoText)}>{singleProjectEmployeeData.name}</div>
                ) : (
                  <EmployeeNameSkeletonLoader />
                )}
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GroupMemberItemStyles.infoSubText)}>
                  <div className={classNames(GroupMemberItemStyles.idTextDiv)}>CODE</div>
                  {singleProjectEmployeeData ? (
                    <div className={classNames(GroupMemberItemStyles.idTextValue)}>{singleProjectEmployeeData?.code}</div>
                  ) : (
                    <CodeSkeletonLoader />
                  )}
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerVertical,
                  GlobalStyles.gap025,
                  GroupMemberItemStyles.infoContainerItemName,
                )}
              >
                <div className={classNames(GroupMemberItemStyles.infoText)}>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
                    <div>{globalUserName}</div>
                  </div>
                </div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GroupMemberItemStyles.infoSubText)}>
                  <div className={classNames(GroupMemberItemStyles.idTextDiv)}>CODE</div>
                  <div className={classNames(GroupMemberItemStyles.codeText)}>{globalUserCode}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        {managerType ? (
          <Tooltip
            title={managerType === FrontendTimesheetGroupManagerialEntity.sectionHead ? "Set Section Head" : "Set Department Manager"}
            placement="top"
            arrow
          >
            <div
              className={classNames(
                GroupMemberItemStyles.actionBtnContainer,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                setPopupMode && GlobalStyles.elementWithCursor,
              )}
              onClick={() => {
                if (setPopupMode) {
                  setPopupMode(
                    managerType === FrontendTimesheetGroupManagerialEntity.sectionHead
                      ? ProjectTimesheetGroupPopupModeEnum.addSection
                      : ProjectTimesheetGroupPopupModeEnum.addDepartmentManager,
                  );
                }
              }}
            >
              <img src={TimesheetGroupMemberSectionGrayIcon} alt="timesheet-group-member-action-icon" />
            </div>
          </Tooltip>
        ) : (
          <div
            className={classNames(
              GroupMemberItemStyles.deleteBtnContainer,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.centerVertical,
              canBeDeleted && canUpdateTimesheetGroup && GlobalStyles.elementWithCursor,
            )}
            onMouseEnter={() => setIsDeleteMemberButtonHovered(true)}
            onMouseLeave={() => setIsDeleteMemberButtonHovered(false)}
            onClick={(event) => {
              if (canBeDeleted) {
                event.stopPropagation();

                onOpenUtilPopup();
              }
            }}
          >
            {canBeDeleted && canUpdateTimesheetGroup ? (
              <Tooltip TransitionComponent={Fade} title={"Remove from group"} placement="top" arrow>
                <img
                  src={isDeleteMemberButtonHovered ? TimesheetGroupSettingsRemoveMemberHoveredIcon : TimesheetGroupSettingsRemoveMemberIcon}
                  alt="timesheet-group-member-action-icon"
                />
              </Tooltip>
            ) : (
              <div className={classNames(GroupMemberItemStyles.fillerDiv)}></div>
            )}
          </div>
        )}
      </div>
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteTimesheetGroupEmployeesMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </>
  );
};
export default GroupMemberItem;
