import { useMemo } from "react";
import { useEpcmApiProjectsUtils } from "../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { ConfigurationScreenType, ProjectConfigurationsFunctionMap } from "../types/projects/ConfigurationTypes.ts";
import { useEpcmApiConfigurations } from "../apicalls/configurations/useEpcmApiConfigurations.ts";
import { GlobalConfigurationScreenType, GlobalConfigurationsFunctionMap } from "../types/settings/GlobalConfigurationTypes.ts";
import { useEpcmApiProjectPermissions } from "../apicalls/projects/projectpermissions/useEpcmApiProjectPermissions.ts";
import { useEpcmApiConfigurationRoles } from "../apicalls/configurations/permissions/useEpcmApiConfigurationRoles.ts";

interface GlobalConfigurationScreenMap {
  functionMap: GlobalConfigurationsFunctionMap;
}

interface ProjectConfigurationScreenMap {
  functionMap: ProjectConfigurationsFunctionMap;
}

export function useConfigurationScreenMaps(): ProjectConfigurationScreenMap {
  const {
    getAllProjectPositions,
    getAllProjectCtrs,
    getAllProjectTrades,
    getAllProjectLocations,
    getAllProjectCategories,
    getAllProjectDisciplines,
    getAllProjectManpowerPositions,
    getAllProjectHolidays,
  } = useEpcmApiProjectsUtils();
  const { getProjectUsers } = useEpcmApiProjectPermissions();

  const functionMap = useMemo<ProjectConfigurationsFunctionMap>(
    () => ({
      [ConfigurationScreenType.positions]: getAllProjectPositions,
      [ConfigurationScreenType.ctrManagement]: getAllProjectCtrs,
      [ConfigurationScreenType.trades]: getAllProjectTrades,
      [ConfigurationScreenType.locations]: getAllProjectLocations,
      [ConfigurationScreenType.disciplines]: getAllProjectDisciplines,
      [ConfigurationScreenType.categories]: getAllProjectCategories,
      [ConfigurationScreenType.ctrLibrary]: getAllProjectCtrs,
      [ConfigurationScreenType.manpowerPositions]: getAllProjectManpowerPositions,
      [ConfigurationScreenType.permissions]: getProjectUsers,
      [ConfigurationScreenType.holidays]: getAllProjectHolidays,
    }),
    [
      getAllProjectPositions,
      getAllProjectManpowerPositions,
      getAllProjectCtrs,
      getAllProjectTrades,
      getAllProjectLocations,
      getAllProjectCategories,
      getAllProjectDisciplines,
      getProjectUsers,
    ],
  );

  return { functionMap };
}

export function useGlobalConfigurationScreenMaps(): GlobalConfigurationScreenMap {
  const {
    getAllGlobalPositions,
    getAllGlobalLocations,
    getAllGlobalTrades,
    getAllGlobalCategories,
    getAllGlobalDisciplines,
    getAllGlobalCtrs,
    getAllConfigurationHolidays,
  } = useEpcmApiConfigurations();

  const { getAllGlobalUserRoles } = useEpcmApiConfigurationRoles();

  const functionMap = useMemo<GlobalConfigurationsFunctionMap>(
    () => ({
      [GlobalConfigurationScreenType.positions]: getAllGlobalPositions,
      [GlobalConfigurationScreenType.locations]: getAllGlobalLocations,
      [GlobalConfigurationScreenType.trades]: getAllGlobalTrades,
      [GlobalConfigurationScreenType.categories]: getAllGlobalCategories,
      [GlobalConfigurationScreenType.disciplines]: getAllGlobalDisciplines,
      [GlobalConfigurationScreenType.ctrLibrary]: getAllGlobalCtrs,
      [GlobalConfigurationScreenType.permissions]: getAllGlobalUserRoles,
      [GlobalConfigurationScreenType.holidays]: getAllConfigurationHolidays,
    }),
    [
      getAllGlobalPositions,
      getAllGlobalLocations,
      getAllGlobalTrades,
      getAllGlobalCategories,
      getAllGlobalDisciplines,
      getAllGlobalCtrs,
      getAllGlobalUserRoles,
      getAllConfigurationHolidays,
    ],
  );

  return { functionMap };
}
