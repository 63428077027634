import { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";
import GroupsListStyles from "./GroupsListStyles.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { FrontendTimesheetGroupBasicType } from "../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import GroupsItem from "../groupsitem/GroupsItem.tsx";

interface GroupsListProps {
  projectId: number;
  groupsList: FrontendTimesheetGroupBasicType[];
  selectedGroupId: number | null;
  setSelectedGroupId: Dispatch<SetStateAction<number | null>>;
}

const GroupsList: FC<PropsWithChildren<GroupsListProps>> = ({ projectId, groupsList, selectedGroupId, setSelectedGroupId, children }) => {
  const handleRadioClick = (groupId: number) => {
    if (selectedGroupId === groupId) {
      setSelectedGroupId(null);
    } else {
      setSelectedGroupId(Number(groupId));
    }
  };

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn, GroupsListStyles.radioGroupContainer)}>
      {groupsList.map((groupItem) => (
        <div
          key={groupItem.id}
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap075,
            GroupsListStyles.radioContainer,
            GlobalStyles.centerHorizontal,
          )}
          onClick={() => handleRadioClick(groupItem.id)}
        >
          <GroupsItem
            projectId={projectId}
            groupItemId={groupItem.id}
            groupName={groupItem.name}
            sectionHeadCode={groupItem.sectionHeadCode ?? null}
          />
          <input
            type="radio"
            name="groupSelection"
            value={groupItem.id}
            checked={selectedGroupId === groupItem.id}
            className={classNames(GroupsListStyles.radioInput)}
          />
          {children}
        </div>
      ))}
    </div>
  );
};

export default GroupsList;
