import { AppRoutesPaths } from "../AppRoutesTypes.ts";
import { ProjectStatus } from "epcm-common/dist/Types/ProjectTypes";
import { FrontendProjectStatus } from "../apicallstypes/ProjectsApiTypes.ts";

export interface ProjectListHeaderType {
  title: string;
  icon: string;
  isSortable: boolean;
}

export enum ProjectMenuHeaderEnum {
  projectEmployees = "Project Employees",
  manpowerPlan = "Manpower Plan",
  timesheets = "Timesheets",
  documents = "Documents",
}

export enum ButtonsWithHoverEnum {
  IMPORT_BULK_MMR = "importBulkMmr",
  EXPORT_BULK_MMR = "downloadBulkMmr",
  EXPORT_MMR_REGISTRY = "exportMmrRegistry",
}

export enum CtrMappingButtonsEnum {
  IMPORT_CTR_MAPPING = "importCtrMapping",
  EXPORT_CTR_MAPPING = "exportCtrMapping",
}

export enum ProjectEmployeeOperationsEnum {
  ADD_EMPLOYEE = "addEmployee",
  MANAGE_OVERTIME = "manageOvertime",
  MANAGE_MMRS = "manageMmrs",
  CREATE_REPORT = "createReport",
}

export interface ProjectMenuItemType {
  title: ProjectMenuHeaderEnum;
  url: AppRoutesPaths;
}

export interface FrontendCodeNameType {
  code?: string;
  name?: string;
}

export interface FrontendProjectManagerType extends FrontendCodeNameType {
  position?: FrontendCodeNameType;
}

export const convertBackendProjectStatusToFrontend = (status: ProjectStatus): FrontendProjectStatus => {
  const statusMap: Map<ProjectStatus, FrontendProjectStatus> = new Map([
    [ProjectStatus.ACTIVE, FrontendProjectStatus.ACTIVE],
    [ProjectStatus.INACTIVE, FrontendProjectStatus.INACTIVE],
    [ProjectStatus.COMPLETED, FrontendProjectStatus.COMPLETED],
    [ProjectStatus.CANCELLED, FrontendProjectStatus.CANCELLED],
  ]);
  return statusMap.get(status)!;
};
export const convertFrontendProjectStatusToBackend = (status: FrontendProjectStatus): ProjectStatus => {
  const statusMap: Map<FrontendProjectStatus, ProjectStatus> = new Map([
    [FrontendProjectStatus.ACTIVE, ProjectStatus.ACTIVE],
    [FrontendProjectStatus.INACTIVE, ProjectStatus.INACTIVE],
    [FrontendProjectStatus.COMPLETED, ProjectStatus.COMPLETED],
    [FrontendProjectStatus.CANCELLED, ProjectStatus.CANCELLED],
  ]);
  return statusMap.get(status)!;
};
export const convertProjectTimesheetTabStatusToFrontend = (status: ProjectStatus): FrontendProjectStatus => {
  const statusMap: Map<ProjectStatus, FrontendProjectStatus> = new Map([
    [ProjectStatus.ACTIVE, FrontendProjectStatus.ACTIVE],
    [ProjectStatus.INACTIVE, FrontendProjectStatus.INACTIVE],
    [ProjectStatus.COMPLETED, FrontendProjectStatus.COMPLETED],
    [ProjectStatus.CANCELLED, FrontendProjectStatus.CANCELLED],
  ]);
  return statusMap.get(status)!;
};

export enum FrontendProjectFieldsEnum {
  PROJECT_NAME = "Project Name",
  PROJECT_CODE = "Project Code",
  PROJECT_STATUS = "Project Status",
  PROJECT_LOCATION = "Project Location",
  PROJECT_MANAGER = "Project Manager",
  PROJECT_CLIENT_SENIOR_MANAGER = "Senior Project Manager",
  PROJECT_COST_CONTROL_ENGINEER = "Cost Control Engineer",
  PROJECT_CONTROLS_MANAGER = "Project Controls Manager",
  AGREEMENT_NUMBER = "Agreement Number",
  MMR_PREFIX = "MMR Prefix Document",
  OVERTIME_PREFIX = "Overtime Prefix",
  DURATION = "Project Duration",
  DATE_OF_SIGN = "Date of Sign",
}

export enum ProjectManagerInputFieldsEnum {
  PROJECT_MANAGER = "Project Manager",
  PROJECT_COST_CONTROL_ENGINEER = "Cost Control Engineer",
  PROJECT_CONTROLS_MANAGER = "Project Controls Manager",
}
