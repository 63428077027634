import { FrontendTimesheetLimitedType, FrontendTimesheetStatusEnum } from "../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { FC, useMemo, useState } from "react";
import { ProjectAction } from "../../../../../../types/Roles.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
// import arrowDownFilledBlue from "../../../../../../assets/images/arrow-down-blue-fill.svg";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useParams } from "react-router-dom";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { useQueryClient } from "@tanstack/react-query";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { PopupButtonTypeEnum } from "../../../../../../types/PopupType.ts";
import { capitalizeFirstLetter } from "../../../../../../utils/StringManipulation.ts";
import { useFindNextTimesheetStatusStep } from "../../../../../../utils/findNextTimesheetStatusStep.ts";
import { TimesheetRevertActionEnum } from "../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import TimesheetStepAction from "./timesheetstepaction/TimesheetStepAction.tsx";

interface TimesheetStepButtonProps {
  timesheetListStatus: FrontendTimesheetStatusEnum;
  groupTimesheetItem: FrontendTimesheetLimitedType;
  timesheetId: number;
}

const TimesheetStepButton: FC<TimesheetStepButtonProps> = ({ timesheetListStatus, groupTimesheetItem, timesheetId }) => {
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { findNextTimesheetStatusStep } = useFindNextTimesheetStatusStep();
  const { projectId } = useParams();
  const { useUpdateTimesheetMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const canChangeTimesheetStatusFromReviewedToChecked = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToChecked);
  const canChangeTimesheetStatusfromApprovedToReviewed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromApprovedToReviewed);
  const canChangeAllTimesheetStatus = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeAllStatuses);
  const [revertActionSelected, setRevertActionSelected] = useState<TimesheetRevertActionEnum | null>(null);

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const {
    isResponseAlertPopupOpen: isWarningAlertPopupOpenModified,
    onOpenResponseAlertPopup: onOpenWarningAlertPopupModified,
    onCloseResponseAlertPopup: onCloseWarningAlertPopupModified,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const queryClient = useQueryClient();

  const changeTimesheetStatusMutation = useUpdateTimesheetMutation(parseInt(projectId!), groupTimesheetItem.timesheetGroupId, timesheetId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Timesheet Status updated successfully" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({
        queryKey: [
          QueryNames.ProjectTimesheetGroupTimesheets,
          SubQueryNames.changeStatus,
          projectId,
          groupTimesheetItem.timesheetGroupId,
          timesheetId,
        ],
      });
      void queryClient.invalidateQueries({
        queryKey: [
          QueryNames.ProjectTimesheetGroupTimesheets,
          projectId?.toString(),
          groupTimesheetItem.timesheetGroupId.toString(),
          timesheetId,
          DetailLevel.BASIC,
        ],
      });
      onCloseLoaderPopup();
    },
  });

  const canShowActionButton = useMemo(() => {
    if (timesheetListStatus === FrontendTimesheetStatusEnum.PENDING) {
      return canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
    }
    if (timesheetListStatus === FrontendTimesheetStatusEnum.PREPARED) {
      return canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToChecked);
    }
    if (timesheetListStatus === FrontendTimesheetStatusEnum.CHECKED) {
      return canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromCheckedToReviewed);
    }
    if (timesheetListStatus === FrontendTimesheetStatusEnum.REVIEWED) {
      return (
        canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToApproved) ||
        canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToChecked)
      );
    }

    return false;
  }, [timesheetListStatus, canPerformProjectAction]);

  const isCurrentStateApproved = useMemo(() => timesheetListStatus === FrontendTimesheetStatusEnum.APPROVED, [timesheetListStatus]);

  const handleAction = () => {
    const nextStepStatus = findNextTimesheetStatusStep(timesheetListStatus);

    if (nextStepStatus) {
      onOpenLoaderPopup();
      changeTimesheetStatusMutation.mutate({ timesheetStatus: nextStepStatus, timesheetEmployees: null });
    }
  };

  const sendBackToChecked = () => {
    onOpenLoaderPopup();
    changeTimesheetStatusMutation.mutate({ timesheetStatus: FrontendTimesheetStatusEnum.CHECKED, timesheetEmployees: null });
  };
  const sendBackToReviewed = () => {
    onOpenLoaderPopup();
    changeTimesheetStatusMutation.mutate({ timesheetStatus: FrontendTimesheetStatusEnum.REVIEWED, timesheetEmployees: null });
  };

  const canShowRevertButton = useMemo(() => {
    if (timesheetListStatus === FrontendTimesheetStatusEnum.REVIEWED && canChangeTimesheetStatusFromReviewedToChecked) {
      return canChangeTimesheetStatusFromReviewedToChecked;
    }
    if (
      timesheetListStatus === FrontendTimesheetStatusEnum.APPROVED &&
      (canChangeTimesheetStatusfromApprovedToReviewed || canChangeAllTimesheetStatus)
    ) {
      return canChangeTimesheetStatusfromApprovedToReviewed;
    }
    return false;
  }, [
    timesheetListStatus,
    canChangeTimesheetStatusFromReviewedToChecked,
    canChangeTimesheetStatusfromApprovedToReviewed,
    canChangeAllTimesheetStatus,
  ]);

  const invalidateQueries = () => {
    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupTimesheetItem.timesheetGroupId.toString()],
    });
    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupTimesheetItem.timesheetGroupId.toString(), timesheetId],
    });

    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupTimesheetItem.timesheetGroupId.toString(), timesheetId],
    });

    void queryClient.invalidateQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupTimesheetItem.timesheetGroupId.toString(), timesheetId, false],
    });

    void queryClient.invalidateQueries({
      queryKey: [
        QueryNames.ProjectTimesheetGroupTimesheets,
        SubQueryNames.changeStatus,
        projectId,
        groupTimesheetItem.timesheetGroupId.toString(),
        timesheetId,
      ],
    });

    void queryClient.invalidateQueries({
      queryKey: [QueryNames.ProjectTimesheets, projectId],
    });

    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupActiveMmrCtrCodes, projectId, groupTimesheetItem.timesheetGroupId.toString()],
    });
  };
  return (
    <>
      {canShowActionButton && (
        <TimesheetStepAction
          onActionClicked={() => {
            onOpenWarningAlertPopupModified();
          }}
          buttonLabel={`Mark as ${capitalizeFirstLetter(findNextTimesheetStatusStep(timesheetListStatus)!)}`}
        />
      )}
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
      {canShowRevertButton && (
        <TimesheetStepAction
          onActionClicked={() => {
            isCurrentStateApproved
              ? setRevertActionSelected(TimesheetRevertActionEnum.revertApproved)
              : setRevertActionSelected(TimesheetRevertActionEnum.revertReviewed);
            onOpenWarningAlertPopupModified();
          }}
          buttonLabel={isCurrentStateApproved ? "Cancel Approval" : "Revert To Checked"}
        />
      )}

      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isWarningAlertPopupOpenModified && (
        <ResponseAlertPopup
          responseType={ApiResponseTypeEnum.warning}
          responseObject={{
            status: 400,
            message: isCurrentStateApproved
              ? "Are you sure you want to cancel Approval?"
              : revertActionSelected === TimesheetRevertActionEnum.revertReviewed
                ? "Are you sure you want to send this timesheet back for Review?"
                : `Are you sure you want to mark this timesheet as ${capitalizeFirstLetter(findNextTimesheetStatusStep(timesheetListStatus)!)}?`,
          }}
          isOpen={isWarningAlertPopupOpenModified}
          closeFn={onCloseWarningAlertPopupModified}
          popupButtons={[
            {
              text: "Close",
              buttonType: PopupButtonTypeEnum.neutral,
              action: () => {
                setRevertActionSelected(null);
                onCloseWarningAlertPopupModified();
              },
            },
            {
              text: "Submit",
              buttonType: PopupButtonTypeEnum.main,
              action: () => {
                isCurrentStateApproved && revertActionSelected === TimesheetRevertActionEnum.revertApproved
                  ? sendBackToReviewed()
                  : revertActionSelected === TimesheetRevertActionEnum.revertReviewed
                    ? sendBackToChecked()
                    : handleAction();
                setRevertActionSelected(null);
                onCloseWarningAlertPopupModified();
              },
            },
          ]}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </>
  );
};

export default TimesheetStepButton;
