import classNames from "classnames";
import RevertButtonStyles from "./TimesheetStepAction.module.css";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import arrowDownBlue from "../../../../../../../assets/images/arrow-down-blue-fill.svg";
import { FC } from "react";

interface RevertStatusButtonProps {
  onActionClicked: () => void;
  buttonLabel: string;
}

const TimesheetStepAction: FC<RevertStatusButtonProps> = ({ onActionClicked, buttonLabel }) => {
  return (
    <div
      className={classNames(
        RevertButtonStyles.revertStatusButton,
        RevertButtonStyles.noWrapText,
        GlobalStyles.flex,
        GlobalStyles.gap05,
        GlobalStyles.elementWithCursor,
        GlobalStyles.centerBoth,
      )}
      onClick={() => {
        onActionClicked();
      }}
    >
      <div className={classNames(RevertButtonStyles.linkBlueText, GlobalStyles.centerVertical)}>{buttonLabel}</div>
      <div className={classNames(GlobalStyles.centerVertical)}>
        <img className={classNames(RevertButtonStyles.arrowRightBlueIconImg)} src={arrowDownBlue} alt="arrowDown" />
      </div>
    </div>
  );
};
export default TimesheetStepAction;
