import { useGlobalConfigurationScreenMaps } from "../useConfigurationScreenMap.ts";
import {
  GlobalCategoriesFunction,
  GlobalConfigurationScreenType,
  GlobalConfigurationsFunctionMap,
  GlobalCtrsFunction,
  GlobalDisciplinesFunction,
  GlobalHolidaysFunction,
  GlobalLocationsFunction,
  GlobalPermissionsFunction,
  GlobalPositionsFunction,
  GlobalTradesFunction,
} from "../../types/settings/GlobalConfigurationTypes.ts";

const useGlobalConfigurationFetchingFunctionMap = (): Record<
  GlobalConfigurationScreenType,
  GlobalConfigurationsFunctionMap[GlobalConfigurationScreenType]
> => {
  const { functionMap } = useGlobalConfigurationScreenMaps();

  return {
    [GlobalConfigurationScreenType.positions]: functionMap[GlobalConfigurationScreenType.positions] as GlobalPositionsFunction,
    [GlobalConfigurationScreenType.locations]: functionMap[GlobalConfigurationScreenType.locations] as GlobalLocationsFunction,
    [GlobalConfigurationScreenType.trades]: functionMap[GlobalConfigurationScreenType.trades] as GlobalTradesFunction,
    [GlobalConfigurationScreenType.categories]: functionMap[GlobalConfigurationScreenType.categories] as GlobalCategoriesFunction,
    [GlobalConfigurationScreenType.disciplines]: functionMap[GlobalConfigurationScreenType.disciplines] as GlobalDisciplinesFunction,
    [GlobalConfigurationScreenType.ctrLibrary]: functionMap[GlobalConfigurationScreenType.ctrLibrary] as GlobalCtrsFunction,
    [GlobalConfigurationScreenType.permissions]: functionMap[GlobalConfigurationScreenType.permissions] as GlobalPermissionsFunction,
    [GlobalConfigurationScreenType.holidays]: functionMap[GlobalConfigurationScreenType.holidays] as GlobalHolidaysFunction,
  };
};

export default useGlobalConfigurationFetchingFunctionMap;
