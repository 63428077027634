import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { FilesFilterEnum, FilesFilterType, FileSubFilterType, FileUploadStatus } from "../../../../types/projects/FileTypes.ts";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import ProjectFileFilterPopupStyles from "./ProjectFilesFilterPopup.module.css";
import arrowDownIcon from "../../../../assets/images/arrow-down-dark-blue-fill.svg";
import atLeastOneFilterIcon from "../../../../assets/images/orange-dot.svg";
import checkIcon from "../../../../assets/images/tick-white.svg";
import { capitalizeFirstLetter } from "../../../../utils/StringManipulation.ts";

// import { displayNumberWithSpecificNumberOfDigits } from "../../../../utils/NumberManipulation.ts";

export interface ProjectFilesFilterPopupProps extends PopupType {
  filterList: FilesFilterType[];
  onApplyFilters: (filterList: FilesFilterType[]) => void;
}

const ProjectFilesFilterPopup: FC<ProjectFilesFilterPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  filterList,
  onApplyFilters,
}) => {
  const [parent] = useAutoAnimate();

  const [openedFilterSectionsList, setOpenedFilterSectionsList] = useState<FilesFilterEnum[]>(filterList.map((filter) => filter.id));
  const [localFiltersList, setLocalFiltersList] = useState<FilesFilterType[]>(filterList);

  const deselectAllSubFilters = useCallback(() => {
    const updatedFiltersList = localFiltersList.map((filter) => ({
      ...filter,
      subFilters: filter.subFilters.map((subFilter) => ({
        ...subFilter,
        isActive: false,
      })),
    }));

    setLocalFiltersList(updatedFiltersList);
  }, [localFiltersList]);

  const onSubFilterToggle = useCallback(
    (filterItem: FilesFilterType, subFilterItem: FileSubFilterType) => {
      const toggleFilter = (subFilters: FileSubFilterType[]) => {
        return subFilters.map((subFilter) => {
          if (subFilter.id === subFilterItem.id) {
            return { ...subFilter, isActive: !subFilter.isActive };
          }

          return { ...subFilter };
        });
      };

      setLocalFiltersList((currentState: FilesFilterType[]) => {
        const updatedSubFilters: FilesFilterType[] = currentState.map((filter) => {
          if (filter.id === filterItem.id) {
            return {
              ...filter,
              subFilters: toggleFilter(filter.subFilters),
            };
          }
          return filter;
        });
        return updatedSubFilters;
      });
    },
    [onApplyFilters],
  );
  // const subFiltersCount = useMemo(
  //   () => localFiltersList.reduce((acc, filter) => acc + filter.subFilters.filter((subFilter) => subFilter.isActive).length, 0),
  //   [localFiltersList],
  // );

  const filterItemHasAtLeastOneSubFilter = useCallback(
    (filterItem: FilesFilterType) => {
      return (
        localFiltersList.filter((filter) => filter.id === filterItem.id && filter.subFilters.filter((subFilter) => subFilter.isActive).length > 0)
          .length > 0
      );
    },
    [localFiltersList],
  );

  const clearAllFilters = useCallback(() => {
    deselectAllSubFilters();
  }, [deselectAllSubFilters]);

  const areFiltersSameAsBefore = useMemo(() => {
    return JSON.stringify(localFiltersList) === JSON.stringify(filterList);
  }, [localFiltersList]);

  return (
    <PopupStructure
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.main,
          action: closeFn,
        },
        {
          text: "Apply filters",
          buttonType: PopupButtonTypeEnum.main,
          tooltipText: areFiltersSameAsBefore ? "No Changes" : "",
          disabled: areFiltersSameAsBefore,
          action: () => {
            onApplyFilters([...localFiltersList]);
            closeFn();
          },
        },
      ]}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
        <div
          className={classNames(
            ProjectFileFilterPopupStyles.filterListContainer,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap075,
          )}
        >
          {localFiltersList.map((filterItem) => (
            <div key={filterItem.id} ref={parent} className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div
                  className={classNames(GlobalStyles.flex, GlobalStyles.gap075, filterItem.subFilters.length > 0 && GlobalStyles.elementWithCursor)}
                  onClick={() =>
                    filterItem.subFilters.length > 0 &&
                    setOpenedFilterSectionsList((currentState) => {
                      if (currentState.includes(filterItem.id)) {
                        return currentState.filter((id) => id !== filterItem.id);
                      }
                      return [...currentState, filterItem.id];
                    })
                  }
                >
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
                    <div className={classNames()}>{filterItem.title}</div>
                  </div>
                  {filterItem.subFilters.length > 0 && (
                    <div className={classNames(GlobalStyles.centerVertical)}>
                      <img
                        className={classNames(
                          ProjectFileFilterPopupStyles.arrowIconImg,
                          openedFilterSectionsList.includes(filterItem.id) && ProjectFileFilterPopupStyles.arrowIconImgReverted,
                        )}
                        src={arrowDownIcon}
                        alt="arrow"
                      />
                    </div>
                  )}
                  {filterItemHasAtLeastOneSubFilter(filterItem) ? (
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerBoth)}>
                      <img
                        src={atLeastOneFilterIcon}
                        className={classNames(ProjectFileFilterPopupStyles.hasFiltersImgDimensions)}
                        alt={"orange-dot-icon"}
                      />
                    </div>
                  ) : (
                    <div className={classNames(ProjectFileFilterPopupStyles.hasFiltersImgDimensions, GlobalStyles.flex, GlobalStyles.centerBoth)}>
                      <div className={classNames(ProjectFileFilterPopupStyles.hideElement)}>-</div>
                    </div>
                  )}
                </div>
                <div className={classNames(GlobalStyles.flex1)} />
              </div>
              {openedFilterSectionsList.includes(filterItem.id) && (
                <div
                  className={classNames(
                    ProjectFileFilterPopupStyles.subFiltersContainer,
                    GlobalStyles.flex,
                    GlobalStyles.flexDirectionColumn,
                    GlobalStyles.gap05,
                  )}
                >
                  {filterItem.subFilters.map((subFilterItem) => (
                    <div
                      key={subFilterItem.title}
                      className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
                      onClick={() => {
                        onSubFilterToggle(filterItem, subFilterItem);
                      }}
                    >
                      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                        <div className={classNames(GlobalStyles.flex1)} />
                        <div
                          className={classNames(
                            ProjectFileFilterPopupStyles.subFiltersCheckBox,
                            subFilterItem.isActive && ProjectFileFilterPopupStyles.subFiltersCheckBoxChecked,
                            GlobalStyles.centerVertical,
                          )}
                        >
                          {subFilterItem.isActive && (
                            <div className={classNames(GlobalStyles.centerVertical)}>
                              <img className={classNames(ProjectFileFilterPopupStyles.checkIconImg)} src={checkIcon} alt="check" />
                            </div>
                          )}
                        </div>
                        <div className={classNames(GlobalStyles.flex1)} />
                      </div>
                      <div className={classNames(GlobalStyles.centerVertical)}>
                        {subFilterItem.title === FileUploadStatus.failed || subFilterItem.title === FileUploadStatus.completed
                          ? capitalizeFirstLetter(subFilterItem.title)
                          : subFilterItem.title}
                      </div>
                      <div className={classNames(GlobalStyles.flex1)} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          {/*<div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>*/}
          {/*  <div className={classNames()}>{`Applied Filters:`}</div>*/}
          {/*  <div className={classNames(ProjectFileFilterPopupStyles.selectedFiltersText)}>*/}
          {/*    {displayNumberWithSpecificNumberOfDigits(subFiltersCount)}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div
            className={classNames(
              ProjectFileFilterPopupStyles.clearAllButton,
              GlobalStyles.flex,
              GlobalStyles.centerBoth,
              GlobalStyles.elementWithCursor,
            )}
            onClick={clearAllFilters}
          >
            Clear All
          </div>
        </div>
      </div>
    </PopupStructure>
  );
};

export default ProjectFilesFilterPopup;
