import { SseRequestBasic, SseTransactionType } from "epcm-common/src/Types/SseTypes.ts";

export interface NotificationMessage {
  message?: string;
}

export enum FileUploadStatus {
  inProgress = "inProgress",
  completed = "completed",
  failed = "failed",
}

export enum FileTransactionType {
  imported = "Imported",
  exported = "Exported",
}

export const convertBackendFileTransactionTypeToFrontend = (type: SseTransactionType | undefined): FileTransactionType | undefined => {
  const typeMap: Map<SseTransactionType, FileTransactionType> = new Map([
    [SseTransactionType.IMPORT, FileTransactionType.imported],
    [SseTransactionType.EXPORT, FileTransactionType.exported],
  ]);
  return type ? typeMap.get(type) : undefined;
};

export interface ProjectFileNotificationMessage extends NotificationMessage {
  id: number;
  fileId: string;
  status: FileUploadStatus;
  progress: number;
  fileType: FileTypeOptions;
  fileName: string;
  projectCode: number;
  projectId: number;
  transactionType: SseTransactionType;
  sseRequestId?: number;
}

export enum FileTypeOptions {
  MANPOWER = "Manpower",
  OVERTIME = "Overtime",
  TIMESHEET = "Timesheet",
  MMR = "Mmr",
  MMR_REGISTER = "Mmr-Register",
  MMR_BULK = "Mmr-Bulk",
  REPORTS = "Reports",
  CTR_MAPPING = "Ctr-Mapping",
}

export interface FrontendFileType {
  creationDateTimestamp: Date;
  creationTime: string;
  fileId: string;
  eventId: number;
  status: FileUploadStatus;
  type: FileTypeOptions;
  userCode: string;
  errorDetails: string | null;
  fileName: string;
  id: number;
  transactionType: FileTransactionType | undefined;
}

export interface ExtendedFrontendFileType extends FrontendFileType {
  progress?: number;
}

export interface SseRequestExtended extends SseRequestBasic {
  fileName: string;
}

export enum FrontendFileAction {
  IMPORT = "IMPORT",
  EXPORT = "EXPORT",
}

export interface FileSubFilterType {
  id: number;
  title: string;
  isActive: boolean;
}

export enum FilesFilterEnum {
  fileType = "fileType",
  fileStatus = "fileStatus",
  fileTransactionType = "fileTransactionType",
}

export interface FilesFilterType {
  id: FilesFilterEnum;
  title: string;
  subFilters: FileSubFilterType[];
}

export enum FileTypeSubFilterIdEnum {
  manpower = 1,
  overtime = 2,
  timesheet = 3,
  mmr = 4,
  mmrRegister = 5,
  mmrBulk = 6,
  reports = 7,
  ctrMapping = 8,
}

export enum FileStatusSubFilterIdEnum {
  completed = 1,
  failed = 2,
}

export enum FileImportTypeEnum {
  MANPOWER = "Manpower",
  CTR_MAPPING = "Ctr-Mapping",
}

export enum FileTransactionTypeSubFilterIdEnum {
  imported = 1,
  exported = 2,
}

export const FileFiltersArray: FilesFilterType[] = [
  {
    id: FilesFilterEnum.fileType,
    title: "File Type",
    subFilters: [
      {
        id: FileTypeSubFilterIdEnum.manpower,
        title: FileTypeOptions.MANPOWER,
        isActive: false,
      },
      {
        id: FileTypeSubFilterIdEnum.overtime,
        title: FileTypeOptions.OVERTIME,
        isActive: false,
      },
      {
        id: FileTypeSubFilterIdEnum.timesheet,
        title: FileTypeOptions.TIMESHEET,
        isActive: false,
      },
      {
        id: FileTypeSubFilterIdEnum.mmr,
        title: FileTypeOptions.MMR,
        isActive: false,
      },
      {
        id: FileTypeSubFilterIdEnum.mmrRegister,
        title: FileTypeOptions.MMR_REGISTER,
        isActive: false,
      },
      {
        id: FileTypeSubFilterIdEnum.mmrBulk,
        title: FileTypeOptions.MMR_BULK,
        isActive: false,
      },
      {
        id: FileTypeSubFilterIdEnum.reports,
        title: FileTypeOptions.REPORTS,
        isActive: false,
      },
      {
        id: FileTypeSubFilterIdEnum.ctrMapping,
        title: FileTypeOptions.CTR_MAPPING,
        isActive: false,
      },
    ],
  },
  {
    id: FilesFilterEnum.fileStatus,
    title: "File Status",
    subFilters: [
      {
        id: FileStatusSubFilterIdEnum.completed,
        title: FileUploadStatus.completed,
        isActive: false,
      },
      {
        id: FileStatusSubFilterIdEnum.failed,
        title: FileUploadStatus.failed,
        isActive: false,
      },
    ],
  },
  {
    id: FilesFilterEnum.fileTransactionType,
    title: "File Transaction Type",
    subFilters: [
      {
        id: FileTransactionTypeSubFilterIdEnum.imported,
        title: FileTransactionType.imported,
        isActive: false,
      },
      {
        id: FileTransactionTypeSubFilterIdEnum.exported,
        title: FileTransactionType.exported,
        isActive: false,
      },
    ],
  },
];
