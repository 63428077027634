import { useRetrieveUserPermittedActions } from "./useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../types/Roles.ts";
import { ProjectTimesheetTabStatusEnum } from "../types/projects/ProjectTimesheetsTypes.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProfile } from "../apicalls/profile/useEpcmApiProfile.ts";
import { useImpersonationStore } from "../store/use-impersonation-store.ts";
import { QueryNames } from "../types/apicallstypes/queryCommons.ts";
import { useEffect, useMemo } from "react";

interface determineTimesheetNavigationListType {
  determineTimesheetNavigationStatus: (sectionHeadCode: string | null, departmentManagerCode: string | null) => ProjectTimesheetTabStatusEnum;
}

export const useDetermineTimesheetNavigationStatusList = (): determineTimesheetNavigationListType => {
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const canChangeTimesheetStatus = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatus);
  const canChangeAllTimesheetStatuses = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeAllStatuses);
  const canChangeToPrepared = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
  const canChangeToChecked = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToChecked);
  const canChangeToReviewed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromCheckedToReviewed);
  const canChangeToApproved = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToApproved);

  const queryClient = useQueryClient();
  const { getMe } = useEpcmApiProfile();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const userProfileQuery = useQuery({
    queryKey: [QueryNames.Profile],
    queryFn: () => getMe(),
    enabled: isAuthorized,
  });
  const userProfileCode = useMemo(() => {
    return userProfileQuery.data?.code;
  }, [userProfileQuery.data]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Profile] })
        .then(() => console.log(`In timesheet groups, ${QueryNames.Profile} query canceled`));
    };
  }, [queryClient]);

  const determineTimesheetNavigationStatus = (
    sectionHeadCode: string | null,
    departmentManagerCode: string | null,
  ): ProjectTimesheetTabStatusEnum => {
    if (canChangeTimesheetStatus && canChangeAllTimesheetStatuses) {
      return ProjectTimesheetTabStatusEnum.pending;
    }
    if (canChangeTimesheetStatus && canChangeToPrepared) {
      if (userProfileCode === sectionHeadCode) {
        return ProjectTimesheetTabStatusEnum.pending;
      }
    }
    if (canChangeTimesheetStatus && canChangeToChecked) {
      if (userProfileCode === departmentManagerCode) {
        return ProjectTimesheetTabStatusEnum.prepared;
      }
    }
    if (canChangeTimesheetStatus && canChangeToReviewed) {
      return ProjectTimesheetTabStatusEnum.checked;
    }
    if (canChangeTimesheetStatus && canChangeToApproved) {
      return ProjectTimesheetTabStatusEnum.reviewed;
    }
    return ProjectTimesheetTabStatusEnum.pending; // default for no permissions (e.g viewer)
  };

  return {
    determineTimesheetNavigationStatus: determineTimesheetNavigationStatus,
  };
};
