import classNames from "classnames";
import ProjectUserDocumentItemStyles from "./ProjectUserDocumentItem.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import userDocumentIcon from "../../../../../assets/images/user-documents-icon.svg";

import { formatDate } from "../../../../../utils/DateManipulation.ts";
import { Fade, LinearProgress, Skeleton, Tooltip } from "@mui/material";
import downloadIconHovered from "../../../../../assets/images/download-icon-green-bg.svg";
import downloadIcon from "../../../../../assets/images/download-icon-dark-blue-bg.svg";
import downloadDisabledIcon from "../../../../../assets/images/download-icon-gray-bg.svg";
import deleteDisabledIcon from "../../../../../assets/images/trashcan-icon-gray.svg";
import deleteIconHovered from "../../../../../assets/images/trashcan-icon-red.svg";
import deleteIcon from "../../../../../assets/images/trashcan-icon-dark-blue.svg";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { capitalizeFirstLetter } from "../../../../../utils/StringManipulation.ts";
import { ExtendedFrontendFileType, FileUploadStatus } from "../../../../../types/projects/FileTypes.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponseTypeEnum, QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { FrontendUserLimitedType } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useEpcmApiUsers } from "../../../../../apicalls/users/useEpcmApiUsers.ts";
import { useEpcmApiProjectFiles } from "../../../../../apicalls/projects/projectfiles/useEpcmApiProjectFiles.ts";
import { useParams } from "react-router-dom";
import { fileSave } from "browser-fs-access";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useEpcmApiProjectsMutations } from "../../../../../apicalls/projects/mutations/useEpcmApiProjectsMutations.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import { useEpcmApiProfile } from "../../../../../apicalls/profile/useEpcmApiProfile.ts";

interface ProjectUserDocumentItemProps {
  documentItem: ExtendedFrontendFileType;
}

const ProjectUserDocumentItem: FC<ProjectUserDocumentItemProps> = ({ documentItem }) => {
  const { projectId } = useParams();
  const [isItemDownloading, setIsItemDownloading] = useState<boolean>(false);
  const [isDownloadButtonHovered, setIsDownloadButtonHovered] = useState<boolean>(false);
  const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const { getMe } = useEpcmApiProfile();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    // initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const {
    isResponseAlertPopupOpen: isDeleteResponseAlertPopupOpen,
    onOpenResponseAlertPopup: onDeleteOpenResponseAlertPopup,
    onCloseResponseAlertPopup: onDeleteCloseResponseAlertPopup,
    responseType: deleteResponseType,
    setResponseType: deleteSetResponseType,
    responseObject: deleteResponseObject,
    setResponseObject: deleteSetResponseObject,
    initializeResponseAlertPopup: deleteInitializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();

  const { getUser } = useEpcmApiUsers();
  const { getProjectFile } = useEpcmApiProjectFiles();
  const { useDeleteProjectFileMutation } = useEpcmApiProjectsMutations();

  const isStatusCompleted = useMemo(() => documentItem.status === FileUploadStatus.completed, [documentItem.status]);
  const isStatusInProgress = useMemo(() => documentItem.status === FileUploadStatus.inProgress, [documentItem.status]);
  const isStatusFailed = useMemo(() => documentItem.status === FileUploadStatus.failed, [documentItem.status]);

  const creatorCodeQuery = useQuery({
    queryKey: [QueryNames.User, documentItem.userCode],
    queryFn: () => getUser(documentItem.userCode),
    enabled: isAuthorized && !!documentItem.userCode,
    select: (data) => data as FrontendUserLimitedType,
  });

  const userProfileQuery = useQuery({
    queryKey: [QueryNames.Profile],
    queryFn: () => getMe(),
    enabled: isAuthorized,
  });
  const userProfileData = useMemo(() => {
    return userProfileQuery.data;
  }, [userProfileQuery.data]);

  const creatorData = useMemo(() => {
    return creatorCodeQuery.data;
  }, [creatorCodeQuery.data]);

  const isUploadingItem = useMemo(() => {
    return !!documentItem.progress || documentItem.progress === 0;
  }, [documentItem.progress]);

  const deleteProjectFileMutation = useDeleteProjectFileMutation(parseInt(projectId!), documentItem.id, {
    onSuccessCallback: () => {
      deleteSetResponseType(ApiResponseTypeEnum.success);
      deleteSetResponseObject({ status: 200, message: "File deleted successfully!" });
      onDeleteOpenResponseAlertPopup();
    },
    onErrorCallback: (error) => {
      deleteSetResponseType(ApiResponseTypeEnum.error);
      deleteSetResponseObject(error.response.data);
      onDeleteOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const downloadFile = () => {
    setIsItemDownloading(true);
    getProjectFile(parseInt(projectId!), documentItem.id).then((response) => {
      fileSave(response.responseData, {
        fileName: documentItem.fileName,
        extensions: [".xlsx"],
        description: "Excel File",
        mimeTypes: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
      })
        .then(() => {
          onOpenResponseAlertPopup();
          setResponseType(ApiResponseTypeEnum.success);
          setResponseObject({ status: 200, message: "File saved successfully!" });
        })
        .catch((error) => {
          console.error("Error saving file:", error);
          setResponseType(ApiResponseTypeEnum.error);
          setResponseObject(error.response.data);
          onOpenResponseAlertPopup();
        })
        .finally(() => {
          setIsItemDownloading(false);
          setIsDownloadButtonHovered(false);
        });
    });
  };

  const disableDownload = useMemo(() => {
    return isItemDownloading || isUploadingItem || isStatusFailed;
  }, [isItemDownloading, isUploadingItem, isStatusFailed]);

  const isDownloading = useMemo(() => {
    return isItemDownloading;
  }, [isItemDownloading]);

  const isUploading = useMemo(() => {
    return isUploadingItem;
  }, [isUploadingItem]);

  const properTooltipMsg = useMemo(() => {
    if (isDownloading) {
      return "File is being downloaded";
    } else if (isUploading) {
      return "File is being uploaded";
    } else if (isStatusFailed) {
      return "File upload failed";
    } else {
      return "Download Version";
    }
  }, [isDownloading, isUploading, isStatusFailed]);

  const disableDeletion = useMemo(() => {
    return isItemDownloading || isUploadingItem || isStatusInProgress;
  }, [isItemDownloading, isUploadingItem, isStatusInProgress]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectFiles, projectId] });
  }, [queryClient, projectId]);

  const canDeleteDocument = canPerformProjectAction(ProjectAction.ProjectListDocumentDelete);
  const canDownloadDocument = canPerformProjectAction(ProjectAction.ProjectListDocumentDownload);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.Users, documentItem.userCode] }).then(() => {
        console.log("Cancelled query for user with code " + documentItem.userCode);
      });
    };
  }, [queryClient, documentItem.userCode]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.Profile] }).then(() => {
        console.log("Cancelled query for user profile");
      });
    };
  }, [queryClient]);

  return (
    <div
      className={classNames(
        ProjectUserDocumentItemStyles.documentItemContainer,
        isRowHovered && ProjectUserDocumentItemStyles.documentItemContainerHovered,
        GlobalStyles.flex,
        GlobalStyles.gap025,
      )}
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(ProjectUserDocumentItemStyles.documentItemDocIconImg)} src={userDocumentIcon} alt="doc" />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div
          className={classNames(
            ProjectUserDocumentItemStyles.fileName,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.centerVertical,
          )}
        >
          <div className={classNames()}>{documentItem.fileName}</div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Created on"}</div>
        <div
          className={classNames(
            ProjectUserDocumentItemStyles.createdOnContainer,
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap01,
            GlobalStyles.flexWrap,
          )}
        >
          <div className={classNames(ProjectUserDocumentItemStyles.detailsText)}>{formatDate(new Date(documentItem.creationDateTimestamp))}</div>
          <div className={classNames(ProjectUserDocumentItemStyles.detailsText)}>{documentItem.creationTime}</div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Created by"}</div>
        {isUploadingItem ? (
          userProfileData && <div className={classNames(ProjectUserDocumentItemStyles.detailsText)}>{userProfileData?.name}</div>
        ) : creatorData ? (
          <div className={classNames(ProjectUserDocumentItemStyles.detailsText)}>{creatorData.name}</div>
        ) : (
          <Skeleton variant={"text"} width={100} height={20} />
        )}
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Type"}</div>
        <div className={classNames(ProjectUserDocumentItemStyles.detailsText)}>{documentItem.type}</div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Transaction Type"}</div>
        <div className={classNames(ProjectUserDocumentItemStyles.detailsText)}>
          {documentItem.transactionType ? documentItem.transactionType : "-"}
        </div>
      </div>
      {documentItem.progress ? (
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.centerVertical,
            GlobalStyles.gap,
          )}
        >
          <div className={classNames()}>{"Status"}</div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
            <LinearProgress
              color={"success"}
              variant={"buffer"}
              valueBuffer={documentItem.progress}
              value={documentItem.progress}
              style={{
                display: "flex",
                flex: "1",
                height: "6px",
              }}
            />
            <div>{documentItem.progress}%</div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.centerVertical,
            GlobalStyles.gap075,
          )}
        >
          <div className={classNames()}>{"Status"}</div>
          <div
            className={classNames(
              ProjectUserDocumentItemStyles.detailsText,
              isStatusCompleted
                ? ProjectUserDocumentItemStyles.greenStatus
                : isStatusFailed
                  ? ProjectUserDocumentItemStyles.failedStatus
                  : isStatusInProgress
                    ? ProjectUserDocumentItemStyles.inProgressStatus
                    : "",
            )}
          >
            {documentItem.status === FileUploadStatus.inProgress ? "In Progress" : capitalizeFirstLetter(documentItem.status)}
          </div>
        </div>
      )}

      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex07, GlobalStyles.gap3)}>
        <div className={classNames(GlobalStyles.flex1)} />
        {
          <>
            {canDownloadDocument && (
              <div
                className={classNames(GlobalStyles.centerVertical, !disableDownload && GlobalStyles.elementWithCursor)}
                onMouseEnter={() => !disableDownload && setIsDownloadButtonHovered(true)}
                onMouseLeave={() => !disableDownload && setIsDownloadButtonHovered(false)}
                onClick={downloadFile}
              >
                <Tooltip TransitionComponent={Fade} title={properTooltipMsg} placement="top" arrow>
                  <img
                    className={classNames(
                      ProjectUserDocumentItemStyles.utilIconImg,
                      disableDownload && ProjectUserDocumentItemStyles.disabledOpacity,
                    )}
                    src={disableDownload ? downloadDisabledIcon : isDownloadButtonHovered ? downloadIconHovered : downloadIcon}
                    alt="download"
                  />
                </Tooltip>
              </div>
            )}
            {canDeleteDocument && (
              <div
                className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
                onMouseEnter={() => !disableDeletion && setIsDeleteButtonHovered(true)}
                onMouseLeave={() => !disableDeletion && setIsDeleteButtonHovered(false)}
                onClick={(event) => {
                  event.stopPropagation();

                  !disableDeletion && onOpenUtilPopup();
                }}
              >
                <Tooltip TransitionComponent={Fade} title={disableDeletion ? "" : "Delete File"} placement="top" arrow>
                  <img
                    className={classNames(
                      ProjectUserDocumentItemStyles.utilIconImg,
                      disableDeletion && ProjectUserDocumentItemStyles.disabledOpacity,
                    )}
                    src={disableDeletion ? deleteDisabledIcon : isDeleteButtonHovered ? deleteIconHovered : deleteIcon}
                    alt="delete"
                  />
                </Tooltip>
              </div>
            )}
          </>
        }

        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={onCloseResponseAlertPopup}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteProjectFileMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isDeleteResponseAlertPopupOpen && deleteResponseType && deleteResponseObject && (
        <ResponseAlertPopup
          responseType={deleteResponseType}
          responseObject={deleteResponseObject}
          isOpen={isDeleteResponseAlertPopupOpen}
          closeFn={() => {
            deleteInitializeResponseAlertPopup();
            onDeleteCloseResponseAlertPopup();
            onCloseUtilPopup();
            invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </div>
  );
};
export default ProjectUserDocumentItem;
