import classNames from "classnames";
import ConfigurationPositionsPopupStyles from "./ConfiguratioPositionsPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import BluePlusIcon from "../../../../../../assets/images/blue-plus-icon.svg";
import TimesheetGroupSettingsRemoveMemberHoveredIcon from "../../../../../../assets/images/timesheet-group-settings-remove-user-hover-icon.svg";
import TimesheetGroupSettingsRemoveMemberIcon from "../../../../../../assets/images/timesheet-group-settings-remove-user-icon.svg";
import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../../types/PopupType.ts";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { FrontendProjectPositionType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { ApiRequestBodySubPositionCreate } from "epcm-common/dist/Types/SubPositionTypes";

interface ConfigurationPositionsPopupProps extends PopupType {
  projectId: number;
  mode: configurationsPopupType;
  positionItem?: FrontendProjectPositionType;
}

const ConfigurationPositionsPopup: FC<ConfigurationPositionsPopupProps> = ({
  mode,
  positionItem,
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  projectId,
}) => {
  const [newPositionName, setNewPositionName] = useState<string>(positionItem?.description || "");
  const [newPositionCode, setNewPositionCode] = useState<string>(positionItem?.code || "");
  const [subpositions, setSubpositions] = useState<ApiRequestBodySubPositionCreate[]>([]);

  const { useCreateProjectEntityMutation, useUpdateProjectEntityMutation } = useEpcmApiProjectUtilsMutations();

  const queryClient = useQueryClient();
  //keep track of the hovered state of which delete subposition button

  useEffect(() => {
    if (mode === configurationsPopupType.edit && positionItem) {
      setNewPositionCode(positionItem.code);
      setNewPositionName(positionItem.description);
    }
  }, [mode, setNewPositionName, setNewPositionCode, positionItem]);

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createProjectPositionMutation = useCreateProjectEntityMutation(projectId, FrontendProjectConfigurationsEntityEnum.Position, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Position was added to project successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectPositions, projectId] });
      onCloseLoaderPopup();
    },
  });

  const updateProjectPositionMutation = useUpdateProjectEntityMutation(
    projectId,
    positionItem?.id ?? 0,
    FrontendProjectConfigurationsEntityEnum.Position,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Position was updated successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectPositions, projectId] });
        onCloseLoaderPopup();
      },
    },
  );
  const onUpdateProjectPosition = useCallback(() => {
    updateProjectPositionMutation.mutate({
      code: newPositionCode,
      description: newPositionName,
      //subPositions: subpositions,
    });

    onOpenLoaderPopup();
  }, [updateProjectPositionMutation, newPositionCode, newPositionName, onOpenLoaderPopup]);

  const onCreateProjectPosition = useCallback(() => {
    createProjectPositionMutation.mutate({
      code: newPositionCode,
      description: newPositionName,
      subPositions: subpositions,
    });
    onOpenLoaderPopup();
  }, [createProjectPositionMutation, newPositionCode, newPositionName, onOpenLoaderPopup, subpositions]);

  const [listOfSubpositionDeleteButtonHovered, setListOfSubpositionDeleteButtonHovered] = useState<boolean[]>(
    new Array(subpositions.length).fill(false),
  );

  const isSpecificSubpositionDeleteButtonHovered = (index: number) => listOfSubpositionDeleteButtonHovered[index];

  const handleSubpositionDeleteButtonHover = (index: number) => {
    const updatedListOfSubpositionDeleteButtonHovered = [...listOfSubpositionDeleteButtonHovered];
    updatedListOfSubpositionDeleteButtonHovered[index] = !updatedListOfSubpositionDeleteButtonHovered[index];
    setListOfSubpositionDeleteButtonHovered(updatedListOfSubpositionDeleteButtonHovered);
  };

  const handleAddSubposition = () => {
    setSubpositions([
      ...subpositions,
      {
        code: "",
        description: "",
      },
    ]);
  };

  const handleSubpositionChangeName = (index: number, value: string) => {
    const updatedSubpositions = [...subpositions];
    updatedSubpositions[index].description = value;
    setSubpositions(updatedSubpositions);
  };

  const handleSubpositionChangeCode = (index: number, value: string) => {
    const updatedSubpositions = [...subpositions];
    updatedSubpositions[index].code = value;
    setSubpositions(updatedSubpositions);
  };

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectPositions, projectId] });
  }, [queryClient, projectId]);

  const isEitherInputEmpty = useMemo(() => !newPositionName || !newPositionCode, [newPositionName, newPositionCode]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationPositionsPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Position" : "Create New Position",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !!newPositionCode && !!newPositionName && onUpdateProjectPosition();
            }
            if (mode === configurationsPopupType.create) {
              !!newPositionName && !!newPositionCode && onCreateProjectPosition();
            }
          },
          tooltipText: isEitherInputEmpty ? "Please enter both position code and description" : "",
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationPositionsPopupStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationPositionsPopupStyles.infoText)}>Position Code</div>
          <input
            placeholder={"Type a position code..."}
            className={classNames(ConfigurationPositionsPopupStyles.infoContainer, ConfigurationPositionsPopupStyles.infoContainerInput)}
            required
            value={newPositionCode}
            onChange={(e) => setNewPositionCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(ConfigurationPositionsPopupStyles.infoText)}>Position Name</div>
          <input
            placeholder={"Type a position name..."}
            className={classNames(ConfigurationPositionsPopupStyles.infoContainer, ConfigurationPositionsPopupStyles.infoContainerInput)}
            required
            value={newPositionName}
            onChange={(e) => setNewPositionName(e.target.value)}
          ></input>
        </div>
        {subpositions.map((subposition, index) => (
          <div key={index} className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <div className={classNames(ConfigurationPositionsPopupStyles.infoText)}>Subposition {index + 1}</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap, GlobalStyles.flex1)}>
                <input
                  placeholder={`Type a subposition code...`}
                  className={classNames(
                    GlobalStyles.flex,
                    GlobalStyles.flex1,
                    ConfigurationPositionsPopupStyles.subPositionInput,
                    ConfigurationPositionsPopupStyles.infoContainerInput,
                  )}
                  onChange={(e) => handleSubpositionChangeCode(index, e.target.value)}
                  value={subposition.code}
                ></input>
                <input
                  placeholder={`Type a subposition name...`}
                  className={classNames(
                    GlobalStyles.flex,
                    GlobalStyles.flex1,
                    ConfigurationPositionsPopupStyles.subPositionInput,
                    ConfigurationPositionsPopupStyles.infoContainerInput,
                  )}
                  onChange={(e) => handleSubpositionChangeName(index, e.target.value)}
                  value={subposition.description}
                ></input>
              </div>
              <div
                key={index}
                onMouseEnter={() => handleSubpositionDeleteButtonHover(index)}
                onMouseLeave={() => handleSubpositionDeleteButtonHover(index)}
                onClick={() => {
                  const updatedSubpositions = [...subpositions];
                  updatedSubpositions.splice(index, 1);
                  setSubpositions(updatedSubpositions);
                }}
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.centerVertical,
                  ConfigurationPositionsPopupStyles.deleteSubpositionButtonContainer,
                  listOfSubpositionDeleteButtonHovered[index] && ConfigurationPositionsPopupStyles.deleteSubpositionButtonContainerHovered,
                )}
              >
                <img
                  src={
                    isSpecificSubpositionDeleteButtonHovered(index)
                      ? TimesheetGroupSettingsRemoveMemberHoveredIcon
                      : TimesheetGroupSettingsRemoveMemberIcon
                  }
                  alt="timesheet-group-member-action-icon"
                />
              </div>
            </div>
          </div>
        ))}
        {mode === configurationsPopupType.create && (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            <div
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
              onClick={handleAddSubposition}
            >
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.centerVertical,
                  ConfigurationPositionsPopupStyles.plusIconContainer,
                )}
              >
                <img src={BluePlusIcon} alt={"Add Subposition"} />
              </div>
              <div className={classNames(ConfigurationPositionsPopupStyles.addGroupText)}>Add subposition</div>
            </div>
          </div>
        )}
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationPositionsPopup;
