import CtrMappingStyles from "./CtrMapping.module.css";
import classNames from "classnames";

import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";

import { useInView } from "react-intersection-observer";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import CtrItem from "./ctritem/CtrItem.tsx";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import DiscardChangesIcon from "../../../../../../assets/images/discard-changes-icon.svg";

import { Skeleton, Tooltip } from "@mui/material";
import { useEpcmApiProjectsMutations } from "../../../../../../apicalls/projects/mutations/useEpcmApiProjectsMutations.ts";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { useEpcmApiProjectCtrMapping } from "../../../../../../apicalls/projects/projectctrmapping/useEpcmApiProjectCtrMapping.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { CtrCanBeDeleted, FrontendCtrMappingApiRow, FrontendCtrMappingRow } from "../../../../../../types/apicallstypes/CtrMappingTypes.ts";
import { ApiRequestBodyCtrMap } from "epcm-common/dist/Types/CtrTypes";
import InfiniteScrollInViewElement from "../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";

import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import CtrMappingActions from "../../../../popups/ctrmappingactions/CtrMappingActions.tsx";
import { EPCMInfoContainerDiv } from "../../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import MMrWhiteIcon from "../../../../../../assets/images/mmr-icon-bulk.svg";
import MmrBlackIcon from "../../../../../../assets/images/active-mmr-icon-bulk.svg";

interface ToggledCells {
  [positionId: number]: { [ctCodeId: number]: boolean };
}

const CtrMapping = () => {
  const { currentProject, searchQuery } = useProjectContext();
  const queryClient = useQueryClient();
  const { inView: inViewCtr, ref: refCtr } = useInView();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectCtrs } = useEpcmApiProjectsUtils();
  const { getProjectCtrMapping } = useEpcmApiProjectCtrMapping();
  const { useCreateProjectCtrMappingMutation } = useEpcmApiProjectsMutations();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const [toggledCells, setToggledCells] = useState<ToggledCells>({});
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<number | null>(null);
  const [isSaveButtonHovered, setIsSaveButtonHovered] = useState<boolean>(false);
  const [isFileActionsButtonHovered, setIsFileActionsButtonHovered] = useState<boolean>(false);

  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canCreateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigCreate);
  const canUpdateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);

  const handleMouseEnter = (positionIndex: number, ctrIndex: number | null) => {
    setHoveredRow(positionIndex);
    setHoveredColumn(ctrIndex);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
    setHoveredColumn(null);
  };

  const projectCtrQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectCtrs, currentProject?.id, searchQuery],
    queryFn: ({ pageParam = 1 }) => getAllProjectCtrs(currentProject?.id, pageParam, searchQuery, 20),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const projectCtrData = useMemo(() => {
    return projectCtrQuery.data?.pages.flatMap((page) => page.data);
  }, [projectCtrQuery.data]);

  const projectCtrMappingQuery = useQuery({
    queryKey: [QueryNames.ProjectCtrMapping, currentProject?.id],
    queryFn: () => getProjectCtrMapping(currentProject?.id),
    enabled: isAuthorized,
    select: (data) => data as FrontendCtrMappingRow[],
  });

  const projectCtrMappingData = useMemo(() => {
    return projectCtrMappingQuery.data;
  }, [projectCtrMappingQuery.data]);

  const initialMarkedCtrCells = useMemo(() => {
    const projectCtrMap = new Map<number, CtrCanBeDeleted[]>();
    if (projectCtrMappingData && projectCtrMappingData?.length > 0) {
      projectCtrMappingData.forEach((row) => {
        projectCtrMap.set(row.manpowerPositionId, row.ctrs);
      });

      const initialToggledCells: { [positionId: number]: { [ctCodeId: number]: boolean } } = {};
      projectCtrMap.forEach((ctCodes, positionId) => {
        initialToggledCells[positionId] = {};

        ctCodes.length > 0 &&
          ctCodes.forEach((ctCodeItem) => {
            initialToggledCells[positionId][ctCodeItem.ctrId] = true;
          });
      });
      return initialToggledCells;
    } else {
      return {};
    }
  }, [projectCtrMappingData]);

  const hasChanges = useMemo(() => {
    return Object.entries(toggledCells).some(([positionIdStr, currentCtCodes]) => {
      const positionId = Number(positionIdStr);
      const initialCtCodes = initialMarkedCtrCells[positionId] || {};

      const hasCurrentChanges = Object.entries(currentCtCodes).some(([ctCodeIdStr, isToggled]) => {
        const ctCodeId = Number(ctCodeIdStr);
        // Assume false if not present in the initial state.
        const initialToggled = initialCtCodes[ctCodeId] === undefined ? false : initialCtCodes[ctCodeId];
        return isToggled !== initialToggled;
      });

      if (hasCurrentChanges) {
        return true;
      }

      const hasInitialChanges = Object.entries(initialCtCodes).some(([ctCodeIdStr, wasToggled]) => {
        const ctCodeId = Number(ctCodeIdStr);
        // Assume false if not present in the current state.
        const currentToggled = currentCtCodes[ctCodeId] === undefined ? false : currentCtCodes[ctCodeId];
        return wasToggled !== currentToggled;
      });

      return hasInitialChanges;
    });
  }, [toggledCells, initialMarkedCtrCells]);

  const canToggleDot = (positionId: number, ctCodeId: number) => {
    const position = projectCtrMappingData?.find((p) => p.manpowerPositionId === positionId);
    const ctr = position?.ctrs.find((c) => c.ctrId === ctCodeId);
    return ctr?.canBeDeleted === undefined ? undefined : ctr.canBeDeleted;
  };

  const toggleDot = (positionId: number, ctCodeId: number) => {
    if (canToggleDot(positionId, ctCodeId) || canToggleDot(positionId, ctCodeId) === undefined) {
      setToggledCells((prevToggledCells) => ({
        ...prevToggledCells,
        [positionId]: {
          ...prevToggledCells[positionId],
          [ctCodeId]: !prevToggledCells[positionId]?.[ctCodeId],
        },
      }));
    }
  };

  const convertToggledCellsToApiFormat = (toggledCells: ToggledCells): ApiRequestBodyCtrMap[] => {
    const ctrMapping: FrontendCtrMappingApiRow[] = [];
    for (const [positionId, ctCodes] of Object.entries(toggledCells)) {
      const ctrIds = Object.entries(ctCodes)
        .filter(([, value]) => value)
        .map(([ctCodeId]) => Number(ctCodeId));
      ctrMapping.push({ manpowerPositionId: Number(positionId), ctrIds });
    }
    return ctrMapping;
  };
  const createProjectCtrMappingMutation = useCreateProjectCtrMappingMutation(currentProject.id, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project Ctr Mapping was created successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateProjectCtrMapping = useCallback(() => {
    onOpenLoaderPopup();
    createProjectCtrMappingMutation.mutate(convertToggledCellsToApiFormat(toggledCells));
  }, [createProjectCtrMappingMutation, toggledCells, onOpenLoaderPopup]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectCtrMapping, currentProject?.id] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectCtrs, currentProject?.id] });
  }, [queryClient, currentProject?.id]);

  useEffect(() => {
    if (projectCtrMappingData) {
      setToggledCells(initialMarkedCtrCells); //Initial marked cells from backend data
    }
  }, [initialMarkedCtrCells, projectCtrMappingData]);

  useEffect(() => {
    //CTR INFINITE SCROLL
    if (
      inViewCtr &&
      !projectCtrQuery.isLoading &&
      !projectCtrQuery.isFetching &&
      !projectCtrQuery.isFetchingNextPage &&
      projectCtrQuery.hasNextPage
    ) {
      void projectCtrQuery.fetchNextPage();
    }
  }, [inViewCtr, projectCtrQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectPositions, currentProject?.id] })
        .then(() => console.log(`In project with id ${currentProject?.id}, ${QueryNames.ProjectPositions} query canceled`));
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectCtrs, currentProject?.id] })
        .then(() => console.log(`In project with id ${currentProject?.id}, ${QueryNames.ProjectCtrs} query canceled`));
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectCtrMapping, currentProject?.id] })
        .then(() => console.log(`In project with id ${currentProject?.id}, ${QueryNames.ProjectCtrMapping} query canceled`));
    };
  }, [queryClient, currentProject?.id]);

  const projectCtrLoader = (
    <div className={classNames(CtrMappingStyles.positionsLoader, GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025)}>
      <Skeleton variant={"rounded"} width={150} height={40}></Skeleton>
    </div>
  );

  const TooltipText: FC<{ description: string; canBeDeleted: boolean | undefined; ctrCode: string }> = ({ description, canBeDeleted, ctrCode }) => {
    return (
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
          <div>{description}</div>
          <div>/</div>
          <div>{ctrCode}</div>
        </div>
        {canBeDeleted === false && <div>This CTR cannot be deleted, as it is used in timesheet record</div>}
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(
          CtrMappingStyles.containerDiv,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.flex1,
          GlobalStyles.gap,
        )}
      >
        <div className={classNames(GlobalStyles.flex)}>
          <EPCMInfoContainerDiv
            className={classNames(
              CtrMappingStyles.fileActionsButton,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
            )}
            onMouseEnter={() => setIsFileActionsButtonHovered(true)}
            onMouseLeave={() => setIsFileActionsButtonHovered(false)}
            onClick={() => onOpenPopup(ProjectsPagePopups.ctrMappingActions, popupHandler)}
          >
            <div className={classNames()}>
              <img
                className={classNames(CtrMappingStyles.fileActionsButtonIconImg)}
                src={isFileActionsButtonHovered ? MMrWhiteIcon : MmrBlackIcon}
                alt="file"
              />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(CtrMappingStyles.fileActionsButtonTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
              <div className={classNames(CtrMappingStyles.fileActionsButtonSmallerText)}>{"Manage"}</div>
              <div className={classNames(CtrMappingStyles.fileActionsButtonBolderText)}>{"CTR Mapping"}</div>
            </div>
          </EPCMInfoContainerDiv>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div>
          <table className={classNames(CtrMappingStyles.table)}>
            <thead className={classNames(CtrMappingStyles.mappingHead)}>
              <tr>
                {canListConfiguration && <th className={classNames(CtrMappingStyles.header, CtrMappingStyles.positionCell)}>MP-POSITIONS</th>}
                <th className={CtrMappingStyles.spacer}></th>
                {projectCtrData && canListConfiguration ? (
                  projectCtrData.map((ctItem, index) => (
                    <th
                      key={index}
                      className={classNames(CtrMappingStyles.fadeIn, CtrMappingStyles.ctrCell, index != 0 && CtrMappingStyles.ctrCellBorder)}
                    >
                      <CtrItem projectId={currentProject.id} ctrId={ctItem.id} ctrCode={ctItem.code} />
                    </th>
                  ))
                ) : canListConfiguration ? (
                  <th>{projectCtrLoader}</th>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody className={classNames(CtrMappingStyles.tableBody)}>
              {canListConfiguration && projectCtrMappingData ? (
                projectCtrMappingData.length > 0 ? (
                  projectCtrMappingData.map((position, positionIndex) => (
                    <tr key={`${positionIndex} + ${position.manpowerPositionId}`} className={classNames(CtrMappingStyles.stickyRow)}>
                      <td className={classNames(CtrMappingStyles.positionCell, positionIndex > 0 && CtrMappingStyles.positionCellBorder)}>
                        {position.manpowerPositionCode}
                      </td>

                      <th className={CtrMappingStyles.spacer}></th>
                      {projectCtrData &&
                        projectCtrData.map((ctCode, ctIndex) => {
                          const canBeDeleted = canToggleDot(position.manpowerPositionId, ctCode.id);
                          return (
                            <Tooltip
                              key={ctIndex + ctCode.id}
                              title={<TooltipText canBeDeleted={canBeDeleted} description={position.manpowerPositionCode} ctrCode={ctCode.code} />}
                              placement="bottom"
                              arrow
                            >
                              <td
                                key={ctCode.id + ctIndex}
                                className={classNames(
                                  hoveredColumn !== null &&
                                    hoveredRow !== null &&
                                    canToggleDot(position.manpowerPositionId, ctCode.id) !== false && {
                                      [CtrMappingStyles.highlightedCell]:
                                        (hoveredRow === positionIndex && ctIndex <= hoveredColumn) ||
                                        (hoveredColumn === ctIndex && positionIndex <= hoveredRow),
                                    },
                                  CtrMappingStyles.tableCell,
                                  positionIndex > 0 && CtrMappingStyles.positionCellBorder,
                                  CtrMappingStyles.fadeIn,
                                  canBeDeleted === false && CtrMappingStyles.disabledCell,
                                  canUpdateConfiguration && canCreateConfiguration && GlobalStyles.elementWithCursor,
                                )}
                                onClick={() => canCreateConfiguration && toggleDot(position.manpowerPositionId, ctCode.id)}
                                onMouseEnter={() => handleMouseEnter(positionIndex, ctIndex)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <div>{toggledCells[position.manpowerPositionId]?.[ctCode.id] ? "•" : ""}</div>
                              </td>
                            </Tooltip>
                          );
                        })}

                      <td>
                        <InfiniteScrollInViewElement
                          key={`InfiniteScrollInViewElement`}
                          reference={refCtr}
                          infiniteQueryResult={projectCtrQuery}
                          loaderComponent={projectCtrLoader}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  projectCtrLoader
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, CtrMappingStyles.actionButtonsContainer)}>
        {canUpdateConfiguration && (
          <Tooltip title={hasChanges ? "" : "No changes to save"} placement="top" arrow>
            <div
              className={classNames(
                CtrMappingStyles.saveCtrMappingChangesButtonContainer,
                isSaveButtonHovered && hasChanges && CtrMappingStyles.saveCtrMappingChangesButtonContainerHovered,
                GlobalStyles.elementWithCursor,
                !hasChanges && CtrMappingStyles.saveCtrMappingChangesDisabled,
              )}
              onMouseEnter={() => setIsSaveButtonHovered(true)}
              onMouseLeave={() => setIsSaveButtonHovered(false)}
              onClick={hasChanges ? onCreateProjectCtrMapping : undefined}
            >
              Save Changes
            </div>
          </Tooltip>
        )}
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)} />
        {canUpdateConfiguration && (
          <Tooltip title={hasChanges ? "" : "No changes to discard"} placement="top" arrow>
            <div
              className={classNames(
                CtrMappingStyles.discardButtonContainer,
                !hasChanges && CtrMappingStyles.discardButtonDisabled,
                GlobalStyles.elementWithCursor,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.gap05,
              )}
              onClick={hasChanges ? () => setToggledCells(initialMarkedCtrCells) : undefined}
            >
              <div> Discard Changes</div>
              <div className={classNames(CtrMappingStyles.discardIcon)}>
                <img src={DiscardChangesIcon} alt="Discard Changes icon" />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
      {popupHandler.get(ProjectsPagePopups.ctrMappingActions) && (
        <CtrMappingActions
          isOpen={popupHandler.get(ProjectsPagePopups.ctrMappingActions)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.ctrMappingActions, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.ctrMappingActions)}
          secondaryHeaderText={`Project ID: ${currentProject.id}`}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </>
  );
};
export default CtrMapping;
