import classNames from "classnames";
import TimesheetItemActorDetailsStyles from "./TimesheetItemActorDetails.module.css";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import editCalendarIcon from "../../../../../../assets/images/edit-calendar-small-icon.svg";
import { formatDate } from "../../../../../../utils/DateManipulation.ts";
import userIcon from "../../../../../../assets/images/profile-blue-lines.svg";
import { FC } from "react";
import { FrontendTimesheetActorType } from "../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";

interface TimesheetItemActorDetailsProps {
  timesheetFlowActor: FrontendTimesheetActorType | null;
}

const TimesheetItemActorDetails: FC<TimesheetItemActorDetailsProps> = ({ timesheetFlowActor }) => {
  return (
    <div className={classNames(TimesheetItemActorDetailsStyles.selectedStatusInfoContainer, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img src={editCalendarIcon} alt="fileUploadStatus" className={classNames(TimesheetItemActorDetailsStyles.selectedStatusInfoImg)} />
        </div>

        <div className={classNames(GlobalStyles.centerVertical)}>
          {timesheetFlowActor?.actionDate ? formatDate(timesheetFlowActor?.actionDate) : "-"}
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex2, GlobalStyles.gap025)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img src={userIcon} alt="user" className={classNames(TimesheetItemActorDetailsStyles.selectedStatusInfoImg)} />
        </div>

        <div className={classNames(GlobalStyles.centerVertical)}>{timesheetFlowActor?.user.name ?? "-"}</div>
      </div>
    </div>
  );
};

export default TimesheetItemActorDetails;
