import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import projectInfoImg from "../../../../assets/images/configuration/project-info.svg";
import locationTypesImg from "../../../../assets/images/configuration/location-types.svg";
import positionCategoriesImg from "../../../../assets/images/configuration/position-categories.svg";
import positionTradesImg from "../../../../assets/images/configuration/position-trades.svg";
import disciplinesImg from "../../../../assets/images/configuration/disciplines.svg";
import positionsImg from "../../../../assets/images/configuration/positions.svg";
import manpowerPositionsImg from "../../../../assets/images/configuration/manpower-positions.svg";
import ctrManagementImg from "../../../../assets/images/configuration/ctr-management.svg";
import configCalendarImg from "../../../../assets/images/configuration/configurationcalendar.svg";

import calendarManagementImg from "../../../../assets/images/configuration/calendar-management.svg";
import permissionsBlackImg from "../../../../assets/images/configuration/permission-icon-black.svg";
import ConfigurationMenuItem from "./configurationmenuitem/ConfigurationMenuItem.tsx";
import { AppRoutesPaths } from "../../../../types/AppRoutesTypes.ts";
import { ConfigurationTitle } from "./configurationtitle/ConfigurationTitle.tsx";
import ConfigurationMenuStyles from "./ConfigurationMenu.module.css";
import { BackRouteType } from "./configurationtitle/BackRouteType.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useNavigate, useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsUtils } from "../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { useEffect, useMemo } from "react";
import { frontendConfigurationStatus } from "../../../../types/projects/ConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";

export const ConfigurationMenu = () => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getProjectMissingInfo } = useEpcmApiProjectsUtils();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const navigate = useNavigate();

  const canViewConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canEditPermissions = canPerformProjectAction(ProjectAction.ProjectEditPermissions);

  useEffect(() => {
    if (!canViewConfigurations) {
      navigate(`/${AppRoutesPaths.unauthorized}`);
    }
  }, [canViewConfigurations, navigate]);

  const missingProjectConfigurationQuery = useQuery({
    queryKey: [QueryNames.ProjectConfigurationsMissingInfo, projectId],
    queryFn: () => getProjectMissingInfo(parseInt(projectId!)),
    enabled: isAuthorized,
  });
  const missingProjectConfigurationData = useMemo(() => missingProjectConfigurationQuery.data, [missingProjectConfigurationQuery.data]);

  const missingCalendarInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.calendarConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingTradeInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.tradeConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingDisciplineInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.disciplineConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingCategoryInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.categoryConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingLocationInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.locationConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingPositionInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.positionConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingCtrMappingInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.ctrMappingConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingLocationCalendarMappingInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.locationCalendarMappingConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingCtrInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.ctrConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  const missingManpowerPositionInfo = useMemo(() => {
    if (missingProjectConfigurationData) {
      return missingProjectConfigurationData.manpowerPositionConfiguration === frontendConfigurationStatus.MISSING;
    }
    return false;
  }, [missingProjectConfigurationData]);

  useEffect(() => {
    return () => {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectConfigurationsMissingInfo, projectId] });
    };
  }, [queryClient, projectId]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowAutoFullHeight)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
        <ConfigurationTitle routeType={BackRouteType.ExitConfiguration} />
        <div className={classNames(ConfigurationMenuStyles.descriptionText)}>
          Please, select from the below categories to configure the project. You can also just view a setting category for advice or edit it
          individually according to project needs.
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, ConfigurationMenuStyles.overflowContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexWrap, ConfigurationMenuStyles.menuItemContainer)}>
          <ConfigurationMenuItem
            img={projectInfoImg}
            title={"Project Info"}
            text={"View or edit basic information related to the project, such as Project Code, Contract Number, Date of contract sign, etc..."}
            to={AppRoutesPaths.projectConfigurationsInfo}
            hasMissingInfo={false}
          />
          <ConfigurationMenuItem
            img={locationTypesImg}
            title={"Location Types"}
            text={"List all the different locations where employees may be based as per contract. Click to edit, add or delete locations of project."}
            to={AppRoutesPaths.configurationsLocations}
            hasMissingInfo={missingLocationInfo}
          />
          <ConfigurationMenuItem
            img={positionCategoriesImg}
            title={"Position Categories"}
            text={
              "Position Categories is a classification entity required to group data in the manpower plan. Configure in order to edit, add or delete categories."
            }
            to={AppRoutesPaths.configurationsCategories}
            hasMissingInfo={missingCategoryInfo}
          />
          <ConfigurationMenuItem
            img={positionTradesImg}
            title={"Position Trades"}
            text={
              "Position Trades is a classification entity required to group data in the manpower plan. Configure in order to edit, add or delete Position Trades."
            }
            to={AppRoutesPaths.configurationsTrades}
            hasMissingInfo={missingTradeInfo}
          />
          <ConfigurationMenuItem
            img={disciplinesImg}
            title={"Disciplines"}
            text={
              "Disciplines is a classification entity required to group data in the manpower plan. Configure in order to edit, add or delete disciplines."
            }
            to={AppRoutesPaths.configurationsDisciplines}
            hasMissingInfo={missingDisciplineInfo}
          />
          <ConfigurationMenuItem
            img={positionsImg}
            title={"Positions"}
            text={
              "View or edit positions and sub-positions that are required for the project. Define set of positions and sub-positions for the specific project."
            }
            to={AppRoutesPaths.configurationsPositions}
            hasMissingInfo={missingPositionInfo}
          />
          <ConfigurationMenuItem
            img={manpowerPositionsImg}
            title={"Manpower Positions"}
            text={"View, add or edit unique combination of entries based on the requirements defined in the project’ contract with the client."}
            to={AppRoutesPaths.projectConfigurationsManpowerPositions}
            hasMissingInfo={missingManpowerPositionInfo}
          />
          <ConfigurationMenuItem
            img={ctrManagementImg}
            title={"CTR Management"}
            text={"Configure to to track the activity of an employee against time. Create CTR library to be utilized while filling timesheets."}
            to={AppRoutesPaths.configurationsCtrManagement}
            hasMissingInfo={missingCtrInfo || missingCtrMappingInfo}
          />
          <ConfigurationMenuItem
            img={configCalendarImg}
            title={"Holidays"}
            text={"Configure to add,edit or delete holidays for the project. Holidays are used to calculate the working days for the employees."}
            to={AppRoutesPaths.configurationsHolidays}
            hasMissingInfo={false} //TODO add missing info
          />
          <ConfigurationMenuItem
            img={calendarManagementImg}
            title={"Calendar Management"}
            text={"This entity is required to setup specific calendar months as per contract. Calendar specify working/holiday date per location."}
            to={AppRoutesPaths.calendarManagement}
            hasMissingInfo={missingCalendarInfo || missingLocationCalendarMappingInfo}
          />
          {canEditPermissions && (
            <ConfigurationMenuItem
              img={permissionsBlackImg}
              title={"Roles and Permissions"}
              to={AppRoutesPaths.configurationsPermissions}
              text={
                "View or edit roles and configuration permissions for the project users. Define set of roles and configuration permissions for the specific project."
              }
            ></ConfigurationMenuItem>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationMenu;
