import { GlobalConfigurationScreenType } from "../../types/settings/GlobalConfigurationTypes.ts";
import { ConfigurationScreenType } from "../../types/projects/ConfigurationTypes.ts";

export const shouldHaveActionsClass = (
  configurationScreenType?: ConfigurationScreenType,
  globalConfigurationScreenType?: GlobalConfigurationScreenType,
): boolean => {
  const configurationTypesWithActions: Array<ConfigurationScreenType> = [
    ConfigurationScreenType.positions,
    ConfigurationScreenType.trades,
    ConfigurationScreenType.locations,
    ConfigurationScreenType.categories,
    ConfigurationScreenType.ctrLibrary,
    ConfigurationScreenType.disciplines,
    ConfigurationScreenType.manpowerPositions,
    ConfigurationScreenType.permissions,
    ConfigurationScreenType.holidays,
  ];

  const globalConfigurationTypesWithActions: Array<GlobalConfigurationScreenType> = [
    GlobalConfigurationScreenType.locations,
    GlobalConfigurationScreenType.positions,
    GlobalConfigurationScreenType.trades,
    GlobalConfigurationScreenType.categories,
    GlobalConfigurationScreenType.disciplines,
    GlobalConfigurationScreenType.ctrLibrary,
    GlobalConfigurationScreenType.permissions,
    GlobalConfigurationScreenType.holidays,
  ];

  if (!configurationScreenType && !globalConfigurationScreenType) {
    return false;
  }

  return <boolean>(
    ((configurationScreenType && configurationTypesWithActions.includes(configurationScreenType)) ||
      (globalConfigurationScreenType && globalConfigurationTypesWithActions.includes(globalConfigurationScreenType)))
  );
};
