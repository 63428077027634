import { EpcmMutationOptionsType } from "../../config.ts";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { createProject } from "./createProject.ts";
import { ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../types/apicallstypes/queryCommons.ts";
import { ApiRequestBodyProjectCreate } from "epcm-common/dist/Types/ProjectTypes.ts";
import { ApiRequestProjectEmployeeCreate } from "epcm-common/dist/Types/ProjectEmployeeTypes.ts";
import { createProjectEmployees } from "./createProjectEmployee.ts";
import { deleteProjectEmployees } from "./deleteProjectEmployee.ts";
import { createOvertimeRequest } from "./createOvertimeRequest.ts";
import { useMemo } from "react";
import { ApiRequestBodyCtrMap } from "epcm-common/dist/Types/CtrTypes.ts";
import { createCtrMapping } from "./createCtrMapping.ts";
import { ApiRequestBodyProjectUpdate } from "epcm-common/dist/Types/ProjectTypes";
import { updateProject } from "./updateProject.ts";
import { deleteProjectFile } from "./documentsmutations/deleteDocument.ts";
import { importCtrMappingFile } from "./importCtrMappingFile.ts";

interface ApiDefinition {
  useCreateProjectMutation: (
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyProjectCreate>;

  useUpdateProjectMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyProjectUpdate>;

  useCreateProjectEmployeesMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestProjectEmployeeCreate>;
  useDeleteProjectEmployeesMutation: (
    projectId: number,
    projectEmployeeId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;
  useCreateOvertimeRequestMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, FormData>;
  useCreateProjectCtrMappingMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyCtrMap[]>;

  useImportCtrMappingFileMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, FormData>;
  useDeleteProjectFileMutation: (
    projectId: number,
    sseRequestId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;
}

export const useEpcmApiProjectsMutations = (): ApiDefinition => {
  const queryClient = useQueryClient();
  return useMemo(() => {
    const useCreateProjectMutation = (mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyProjectCreate>({
        mutationFn: async (newProject: ApiRequestBodyProjectCreate) => {
          return createProject(newProject);
        },
        onSuccess: (data) => {
          console.log("on post create project mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post create project mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          void queryClient.invalidateQueries({ queryKey: [QueryNames.Projects] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };

    const useCreateProjectEmployeesMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestProjectEmployeeCreate>({
        mutationFn: async (newProjectEmployees: ApiRequestProjectEmployeeCreate) => {
          return createProjectEmployees(projectId, newProjectEmployees);
        },
        onSuccess: (data) => {
          console.log("on post create project employees mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post create project employees mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };

    const useDeleteProjectEmployeesMutation = (projectId: number, projectEmployeeId: number, mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
        mutationFn: async () => {
          return deleteProjectEmployees(projectId, projectEmployeeId);
        },
        onSuccess: (data) => {
          console.log("on post delete project employees mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post delete project employees mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };

    const useCreateOvertimeRequestMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, FormData>({
        mutationFn: async (overtimeFile: FormData) => {
          return createOvertimeRequest(projectId, overtimeFile);
        },
        onSuccess: (data) => {
          console.log("on post create overtime request mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post create overtime request mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };

    const useCreateProjectCtrMappingMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyCtrMap[]>({
        mutationFn: async (ctrMappingData: ApiRequestBodyCtrMap[]) => {
          return createCtrMapping(projectId, ctrMappingData);
        },
        onSuccess: (data) => {
          console.log("on post create project ctr mapping mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post create project ctr mapping mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          void queryClient.invalidateQueries({ queryKey: [QueryNames.Projects] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };

    const useImportCtrMappingFileMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, FormData>({
        mutationFn: async (ctrMappingFile: FormData) => {
          return importCtrMappingFile(projectId, ctrMappingFile);
        },
        onSuccess: (data) => {
          console.log("on post import ctr mapping file mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post import ctr mapping file mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          void queryClient.invalidateQueries({ queryKey: [QueryNames.Projects] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };
    const useUpdateProjectMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyProjectUpdate>({
        mutationFn: async (updatedProjectData: ApiRequestBodyProjectUpdate) => {
          return updateProject(projectId, updatedProjectData);
        },
        onSuccess: (data) => {
          console.log("on post update project mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post update project mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          //void queryClient.invalidateQueries({ queryKey: [QueryNames.Projects] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };

    const useDeleteProjectFileMutation = (projectId: number, sseRequestId: number, mutationOptions: EpcmMutationOptionsType) => {
      return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
        mutationFn: async () => {
          return deleteProjectFile(projectId, sseRequestId);
        },
        onSuccess: (data) => {
          console.log("on post delete project file mutation success: ", data);
          mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
        },
        onError: (err) => {
          console.log("on post delete project file mutation error: ", err);
          mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
        },
        onSettled: () => {
          // void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectFiles, projectId] });
          mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
        },
      });
    };

    return {
      useCreateProjectMutation,
      useCreateProjectEmployeesMutation,
      useDeleteProjectEmployeesMutation,
      useCreateOvertimeRequestMutation,
      useCreateProjectCtrMappingMutation,
      useImportCtrMappingFileMutation,
      useUpdateProjectMutation,
      useDeleteProjectFileMutation,
    };
  }, [queryClient]);
};
