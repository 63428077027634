import {
  FrontendTimesheetActorType,
  FrontendTimesheetBasicType,
  FrontendTimesheetCtrRecordType,
  FrontendTimesheetEmployeeLimitedType,
  FrontendTimesheetFormHeaderDateType,
  FrontendTimesheetGroupBasicType,
  FrontendTimesheetGroupBasicWithHasPendingType,
  FrontendTimesheetGroupLimitedType,
  FrontendTimesheetGroupNormalType,
  FrontendTimesheetLimitedType,
  FrontendTimesheetProjectEmployeeActiveMMRType,
  FrontendTimesheetRecordBodyType,
  FrontendTimesheetRecordType,
  FrontendTimesheetType,
} from "../../../../ProjectTimesheetsApiTypes.ts";
import { convertProjectEmployeesLimitedData } from "../../projectemployees/ProjectEmployeesApiConverter.ts";
import {
  calendarProjectStatusToFrontend,
  convertProjectTimesheetStatusToFrontend,
  projectTimesheetStatusToFrontend,
} from "../ProjectTimesheetsApiConverters.ts";
import { convertCalendarMMRToFrontend } from "../../../CalendarApiConverter.ts";
import {
  ApiResponseBodyTimesheetRecord,
  ApiResponseCtrTimesheetRecords,
  ApiResponseTimesheetRecordsCalendarDayInfo,
  CalendarDayRecords,
  isApplicableState,
} from "epcm-common/dist/Types/TimesheetRecordTypes";
import { convertCtrBasicFrontend } from "../../../CtrApiConverter.ts";
import { CalendarInRange } from "epcm-common/dist/Types/CalendarTypes";
import { TimesheetEmployeeLimited } from "epcm-common/dist/Types/TimesheetEmployeeTypes";
import { TimesheetGroupGetAll, TimesheetGroupNormal } from "epcm-common/dist/Types/TimesheetGroupTypes";

import {
  ApiResponseTimesheetCountersDetailed,
  TimesheetApprover,
  TimesheetBasic,
  TimesheetChecker,
  TimesheetLimited,
  TimesheetNormal,
  TimesheetReviewer,
  TimesheetSubmitter,
} from "epcm-common/dist/Types/TimesheetTypes";
import { ApiResponseGetActiveMmrs } from "epcm-common/dist/Types/MmrTypes";
import { FrontendCalendarDayRecords } from "../../../../CalendarApiTypes.ts";
import { FrontendProjectTimesheetCountersType } from "../../../../../projects/ProjectTimesheetsTypes.ts";

export const convertTimesheetsGroupLimitedData = (timesheetGroupData: FrontendTimesheetGroupLimitedType): FrontendTimesheetGroupLimitedType => {
  return {
    id: timesheetGroupData.id,
    name: timesheetGroupData.name,
  };
};

export const convertTimesheetsCountersDetailedData = (
  timesheetsDetailedData: ApiResponseTimesheetCountersDetailed,
): FrontendProjectTimesheetCountersType => {
  return {
    totalCount: timesheetsDetailedData.totalCount,
    statusCounters: timesheetsDetailedData.statusCounters.map((statusCounter) => {
      return {
        status: convertProjectTimesheetStatusToFrontend(statusCounter.status),
        count: statusCounter.count,
      };
    }),
  };
};

export const convertTimesheetsGroupBasicData = (
  timesheetGroupData: Pick<TimesheetGroupNormal, "id" | "name" | "sectionHeadCode" | "departmentManagerCode">,
): FrontendTimesheetGroupBasicType => {
  return {
    ...convertTimesheetsGroupLimitedData(timesheetGroupData),
    sectionHeadCode: timesheetGroupData.sectionHeadCode,
    departmentManagerCode: timesheetGroupData.departmentManagerCode,
  };
};

export const convertTimesheetsGroupNormalData = (timesheetGroupData: TimesheetGroupNormal): FrontendTimesheetGroupNormalType => {
  return {
    ...convertTimesheetsGroupBasicData(timesheetGroupData),
    projectEmployees: convertProjectEmployeesLimitedData(timesheetGroupData.projectEmployees),
  };
};

export const convertTimesheetsGroupsData = (timesheetGroupData: TimesheetGroupGetAll[]): FrontendTimesheetGroupBasicWithHasPendingType[] => {
  return timesheetGroupData.map((timesheetGroupItem): FrontendTimesheetGroupBasicWithHasPendingType => {
    return {
      ...convertTimesheetsGroupBasicData(timesheetGroupItem),
      hasPending: timesheetGroupItem.hasPending,
    };
  });
};

export const convertTimesheetsGroupTimesheetActorData = (
  timesheetActorData: TimesheetReviewer | TimesheetSubmitter | TimesheetChecker | TimesheetApprover | null,
): FrontendTimesheetActorType | null => {
  if (!timesheetActorData) {
    return null;
  }
  return {
    user: {
      name: `${timesheetActorData.user.surname} ${timesheetActorData.user.name}`,
      code: timesheetActorData.user.code,
      email: timesheetActorData.user.email,
    },
    actionDate:
      "reviewedDate" in timesheetActorData
        ? new Date(timesheetActorData.reviewedDate)
        : "submittedDate" in timesheetActorData
          ? new Date(timesheetActorData.submittedDate)
          : "checkedDate" in timesheetActorData
            ? new Date(timesheetActorData.checkedDate)
            : new Date(timesheetActorData.approvedDate),
  };
};

export const convertTimesheetsGroupTimesheetBasicData = (timesheetGroupTimesheetData: TimesheetBasic): FrontendTimesheetBasicType => {
  return {
    id: timesheetGroupTimesheetData.id,
    status: projectTimesheetStatusToFrontend.get(timesheetGroupTimesheetData.status)!,
    periodStart: new Date(timesheetGroupTimesheetData.periodStart),
    periodEnd: new Date(timesheetGroupTimesheetData.periodEnd),
    deadline: new Date(timesheetGroupTimesheetData.deadline),
  };
};

export const convertTimesheetsGroupTimesheetLimitedData = (timesheetGroupTimesheetData: TimesheetLimited): FrontendTimesheetLimitedType => {
  return {
    id: timesheetGroupTimesheetData.id,
    status: projectTimesheetStatusToFrontend.get(timesheetGroupTimesheetData.status)!,
    timesheetGroupId: timesheetGroupTimesheetData.timesheetGroupId,
  };
};

export const convertTimesheetsGroupTimesheetNormalData = (timesheetGroupTimesheetData: TimesheetNormal): FrontendTimesheetType => {
  return {
    ...convertTimesheetsGroupTimesheetBasicData(timesheetGroupTimesheetData),
    timesheetGroup: convertTimesheetsGroupLimitedData(timesheetGroupTimesheetData.timesheetGroup),
    reviewer: convertTimesheetsGroupTimesheetActorData(timesheetGroupTimesheetData.reviewer),
    submitter: convertTimesheetsGroupTimesheetActorData(timesheetGroupTimesheetData.submitter),
    approver: convertTimesheetsGroupTimesheetActorData(timesheetGroupTimesheetData.approver),
    checker: convertTimesheetsGroupTimesheetActorData(timesheetGroupTimesheetData.checker),
    editDate: new Date(timesheetGroupTimesheetData.editDate),
    isValid: timesheetGroupTimesheetData.isValid,
    editor: {
      code: timesheetGroupTimesheetData.editor.code,
      email: timesheetGroupTimesheetData.editor.email,
      name: `
        ${timesheetGroupTimesheetData.editor.surname} ${timesheetGroupTimesheetData.editor.name}
        `,
    },
  };
};

export const convertTimesheetsGroupTimesheetsLimitedData = (timesheetGroupTimesheetsData: TimesheetLimited[]): FrontendTimesheetLimitedType[] => {
  return timesheetGroupTimesheetsData.map((timesheetItem): FrontendTimesheetLimitedType => {
    return {
      ...convertTimesheetsGroupTimesheetLimitedData(timesheetItem),
    };
  });
};

export const convertGroupTimesheetEmployeesLimitedData = (
  groupTimesheetEmployeesData: TimesheetEmployeeLimited[],
): FrontendTimesheetEmployeeLimitedType[] => {
  return groupTimesheetEmployeesData.map((timesheetEmployeeItem): FrontendTimesheetEmployeeLimitedType => {
    return {
      timesheetEmployeeId: timesheetEmployeeItem.timesheetEmployeeId,
      projectEmployeeId: timesheetEmployeeItem.projectEmployeeId,
      employeeId: timesheetEmployeeItem.employeeId,
    };
  });
};

export const convertTimesheetProjectEmployeeActiveMMRsData = (
  timesheetProjectEmployeeActiveMMRsData: ApiResponseGetActiveMmrs[],
): FrontendTimesheetProjectEmployeeActiveMMRType[] => {
  return timesheetProjectEmployeeActiveMMRsData.map((timesheetProjectEmployeeActiveMMRItem): FrontendTimesheetProjectEmployeeActiveMMRType => {
    return {
      mmrId: timesheetProjectEmployeeActiveMMRItem.mmrId,
      calendars: timesheetProjectEmployeeActiveMMRItem.calendars.map((calendarMMR) => convertCalendarMMRToFrontend(calendarMMR)),
    };
  });
};

export const convertGroupTimesheetDatesData = (timesheetDatesData: CalendarInRange[]): FrontendTimesheetFormHeaderDateType[] => {
  return timesheetDatesData.map((calendarItem): FrontendTimesheetFormHeaderDateType => {
    return {
      id: calendarItem.id,
      date: new Date(calendarItem.date),
      dayName: calendarItem.dayName,
      calendarProjectStatus: calendarProjectStatusToFrontend.get(calendarItem.calendarProjectStatus)!,
    };
  });
};

export const convertGroupTimesheetRecordBodyData = (
  timesheetDateRecordBodyData: ApiResponseBodyTimesheetRecord[],
): FrontendTimesheetRecordBodyType[] => {
  return timesheetDateRecordBodyData.map((recordBodyItem): FrontendTimesheetRecordBodyType => {
    return {
      ctr: convertCtrBasicFrontend(recordBodyItem.ctr),
      workedHours: recordBodyItem.workedHours,
    };
  });
};

export const convertGroupTimesheetRecordData = (timesheetDateRecordData: ApiResponseTimesheetRecordsCalendarDayInfo): FrontendTimesheetRecordType => {
  return {
    maxHours: timesheetDateRecordData.maxHours,
    overtimeHours: timesheetDateRecordData.overtimeExtraHours,
    locationWorkingHours: timesheetDateRecordData.locationWorkingHours,
    isApplicable:
      timesheetDateRecordData.isApplicable === isApplicableState.notSet ? true : timesheetDateRecordData.isApplicable === isApplicableState.active,
  };
};

export const convertGroupTimesheetCtrRecordData = (timesheetCtrRecordData: CalendarDayRecords): FrontendCalendarDayRecords => {
  return {
    calendarDayId: timesheetCtrRecordData.calendarDayId,
    workedHours: timesheetCtrRecordData.workedHours,
  };
};
export const convertGroupTimesheetCtrItemRecordData = (
  timesheetCtrItemRecordData: ApiResponseCtrTimesheetRecords,
): FrontendTimesheetCtrRecordType => {
  return {
    records: timesheetCtrItemRecordData.records.map((recordItem) => {
      return convertGroupTimesheetCtrRecordData(recordItem);
    }),
  };
};
