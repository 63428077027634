import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const importCtrMappingFile = async (projectId: number, ctrMappingFile: FormData) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/ctrs-mapping/import`,
    method: "POST",
    data: ctrMappingFile,
  };

  return epcmAxiosInstance(config);
};
