import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ConfirmAddEmployeesToGroupPopupStyles from "./ConfirmAddManagerialEntityToGroupPopupStyles.module.css";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";

import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  ApiRequestBodyTimesheetGroupAddDepartmentManager,
  ApiRequestBodyTimesheetGroupAddSectionHead,
} from "epcm-common/dist/Types/TimesheetGroupTypes";
import { useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { FrontendTimesheetGroupManagerialEntity, ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";

interface ConfirmAddSectionHeadToGroupPopupProps extends PopupType {
  managerialEntityCode: string;
  newManagerialEntityName: string;
  projectId: number;
  timesheetGroupId: number;
  setNewManagerialEntityName: Dispatch<SetStateAction<string>>;
  setNewManagerialEntity: Dispatch<SetStateAction<string>>;
  setPopupMode: Dispatch<SetStateAction<ProjectTimesheetGroupPopupModeEnum>>;
  managerialEntityType: FrontendTimesheetGroupManagerialEntity;
}

const ConfirmAddManagerialEntityToGroupPopup: FC<ConfirmAddSectionHeadToGroupPopupProps> = ({
  managerialEntityCode,
  projectId,
  timesheetGroupId,
  isOpen,
  closeFn,
  secondaryHeaderText,
  headerText,
  newManagerialEntityName,
  setNewManagerialEntity,
  setNewManagerialEntityName,
  setPopupMode,
  managerialEntityType,
}) => {
  const { useCreateTimesheetGroupSectionHeadMutation, useCreateTimesheetGroupDepartmentManagerMutation } =
    useEpcmApiProjectTimesheetsGroupsMutations();
  const queryClient = useQueryClient();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const isSectionHead = useMemo(() => managerialEntityType === FrontendTimesheetGroupManagerialEntity.sectionHead, [managerialEntityType]);

  const createTimesheetGroupSectionHeadMutation = useCreateTimesheetGroupSectionHeadMutation(projectId, timesheetGroupId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Section Head was added to group successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onConfirmAddSectionHeadToGroup = useCallback(() => {
    createTimesheetGroupSectionHeadMutation.mutate({ sectionHeadCode: managerialEntityCode } satisfies ApiRequestBodyTimesheetGroupAddSectionHead);
    onOpenLoaderPopup();
  }, [createTimesheetGroupSectionHeadMutation, onOpenLoaderPopup, managerialEntityCode]);

  const createTimesheetGroupDepartmentManagerMutation = useCreateTimesheetGroupDepartmentManagerMutation(projectId, timesheetGroupId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Department Manager was added to group successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onConfirmAddDepartmentManagerToGroup = useCallback(() => {
    createTimesheetGroupDepartmentManagerMutation.mutate({
      departmentManagerCode: managerialEntityCode,
    } satisfies ApiRequestBodyTimesheetGroupAddDepartmentManager);
    onOpenLoaderPopup();
  }, [createTimesheetGroupDepartmentManagerMutation, onOpenLoaderPopup, managerialEntityCode]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetGroupSectionHead] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.Users] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.User] });
  }, [queryClient]);

  return (
    <PopupStructure
      popupButtons={[
        { text: "Back to selection", buttonType: PopupButtonTypeEnum.neutral, action: closeFn },
        {
          text: "Proceed",
          disabled: !managerialEntityCode,
          buttonType: PopupButtonTypeEnum.main,
          action: () => (isSectionHead ? onConfirmAddSectionHeadToGroup() : onConfirmAddDepartmentManagerToGroup()),
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      overrideContentContainerStyleClass={""}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.centerVertical,
          GlobalStyles.centerHorizontal,
          ConfirmAddEmployeesToGroupPopupStyles.content,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
          <div>{`New ${isSectionHead ? "Section Head" : "Department Manager"} with name `}</div>
          <div className={classNames(ConfirmAddEmployeesToGroupPopupStyles.codeText)}> {newManagerialEntityName}</div>
          <div>{`and code`}</div>
          <div className={classNames(ConfirmAddEmployeesToGroupPopupStyles.codeText)}> {managerialEntityCode}</div>
          <div>{`will be appointed to the group`}</div>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            invalidateQueries();
            setNewManagerialEntity("");
            setNewManagerialEntityName("");
            setPopupMode(ProjectTimesheetGroupPopupModeEnum.view);
            closeFn();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfirmAddManagerialEntityToGroupPopup;
