import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import { PopupType } from "../../../../types/PopupType.ts";
import { FC, useState } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import TimesheetGroupSettingsPopupStyles from "./TimesheetGroupSettingsPopupStyles.module.css";

import TimesheetGroupSettingsMainIcon from "../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";

import { FrontendTimesheetGroupManagerialEntity, ProjectTimesheetGroupPopupModeEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";

import ViewSettingsOptions from "./viewsettingsoptions/ViewSettingsOptions.tsx";
import AddGroupMember from "./addgroupmember/AddGroupMember.tsx";
import AddTimesheetGroupManagerialEntity from "./addsectionhead/AddTimesheetGroupManagerialEntity.tsx";
import { useParams } from "react-router-dom";

import TimesheetGroupMembersList from "./timesheetgroupmemberslist/TimesheetGroupMembersList.tsx";
import TimesheetGroupManagerialEntity from "./timesheetgroupmanagerialentity/TimesheetGroupManagerialEntity.tsx";
import SectionHeadItemStyles from "./timesheetgroupmanagerialentity/TimesheetGroupManagerialEntityStyles.module.css";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";

interface TimesheetGroupSettingsPopupProps extends PopupType {
  projectId: number;
  timesheetGroupId?: number;
  redirectLink?: string;
}

const TimesheetGroupSettingsPopup: FC<TimesheetGroupSettingsPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  projectId,
  timesheetGroupId,
  redirectLink,
}) => {
  const [popupMode, setPopupMode] = useState<ProjectTimesheetGroupPopupModeEnum>(ProjectTimesheetGroupPopupModeEnum.view);
  const [newGroupMembersLength, setNewGroupMembersLength] = useState<number>(0);
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { groupId } = useParams<{ groupId: string }>();

  // const canDeleteTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupDelete);
  const canUpdateTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupUpdate);
  const canListTimesheetGroups = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupList);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(TimesheetGroupSettingsPopupStyles.popupContainer)}
      popupButtons={[]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      {canListTimesheetGroups && (
        <div
          className={classNames(GlobalStyles.flex, TimesheetGroupSettingsPopupStyles.mainContainer, GlobalStyles.overflowHiddenFullHeight)}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              popupMode === ProjectTimesheetGroupPopupModeEnum.view ? GlobalStyles.gap : GlobalStyles.gap2,
              TimesheetGroupSettingsPopupStyles.contentContainer,
            )}
          >
            <div className={classNames(TimesheetGroupSettingsPopupStyles.contentTitle)}>Group Overview</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
              {popupMode === ProjectTimesheetGroupPopupModeEnum.view && (
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
                  {projectId && (groupId || timesheetGroupId) && (
                    <>
                      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
                        <div className={classNames(SectionHeadItemStyles.subsectionTitleBold)}> SECTION HEAD</div>
                        <TimesheetGroupManagerialEntity
                          popupMode={popupMode}
                          setPopupMode={setPopupMode}
                          projectId={projectId}
                          groupId={timesheetGroupId ?? Number(groupId)}
                          timesheetGroupManagerialEntity={FrontendTimesheetGroupManagerialEntity.sectionHead}
                        />
                      </div>
                      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
                        <div className={classNames(SectionHeadItemStyles.subsectionTitleBold)}> DEPARTMENT MANAGER</div>
                        <TimesheetGroupManagerialEntity
                          popupMode={popupMode}
                          setPopupMode={setPopupMode}
                          projectId={projectId}
                          groupId={timesheetGroupId ?? Number(groupId)}
                          timesheetGroupManagerialEntity={FrontendTimesheetGroupManagerialEntity.departmentManager}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              {popupMode === ProjectTimesheetGroupPopupModeEnum.addSection && canUpdateTimesheetGroup && (
                <>
                  <div className={classNames(TimesheetGroupSettingsPopupStyles.subsectionTitle)}>
                    By setting a new section head, the current one will be replaced. You cannot have more than one section head, neither none.
                  </div>
                  <TimesheetGroupManagerialEntity
                    popupMode={popupMode}
                    setPopupMode={setPopupMode}
                    projectId={projectId}
                    groupId={timesheetGroupId ?? Number(groupId)}
                    timesheetGroupManagerialEntity={FrontendTimesheetGroupManagerialEntity.sectionHead}
                  />
                </>
              )}
              {popupMode === ProjectTimesheetGroupPopupModeEnum.addDepartmentManager && canUpdateTimesheetGroup && (
                <>
                  <div className={classNames(TimesheetGroupSettingsPopupStyles.subsectionTitle)}>
                    By setting a new department manager, the current one will be replaced. You cannot have more than one department manager, neither
                    none.
                  </div>
                  <TimesheetGroupManagerialEntity
                    popupMode={popupMode}
                    setPopupMode={setPopupMode}
                    projectId={projectId}
                    groupId={timesheetGroupId ?? Number(groupId)}
                    timesheetGroupManagerialEntity={FrontendTimesheetGroupManagerialEntity.departmentManager}
                  />
                </>
              )}
              {popupMode === ProjectTimesheetGroupPopupModeEnum.addGroupMember && canUpdateTimesheetGroup && (
                <div
                  className={classNames(
                    TimesheetGroupSettingsPopupStyles.subsectionTitle,
                    GlobalStyles.flex,
                    GlobalStyles.centerHorizontal,
                    GlobalStyles.gap025,
                  )}
                >
                  <div className={classNames(TimesheetGroupSettingsPopupStyles.membersCountText)}>{newGroupMembersLength}</div>
                  <div> new group employees will be added</div>
                </div>
              )}
            </div>

            {popupMode !== ProjectTimesheetGroupPopupModeEnum.addSection && popupMode !== ProjectTimesheetGroupPopupModeEnum.addDepartmentManager && (
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  TimesheetGroupSettingsPopupStyles.groupMembersContainer,
                  GlobalStyles.gap075,
                )}
              >
                {popupMode !== ProjectTimesheetGroupPopupModeEnum.addGroupMember && (
                  <div className={classNames(TimesheetGroupSettingsPopupStyles.subsectionTitleBold)}>GROUP EMPLOYEES</div>
                )}
                <TimesheetGroupMembersList
                  setPopupMode={setPopupMode}
                  projectId={projectId}
                  groupId={timesheetGroupId ?? Number(groupId)}
                  popupMode={popupMode}
                ></TimesheetGroupMembersList>
              </div>
            )}
          </div>

          <div
            className={classNames(
              TimesheetGroupSettingsPopupStyles.contentContainer,
              TimesheetGroupSettingsPopupStyles.rightContent,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
            )}
          >
            {popupMode == ProjectTimesheetGroupPopupModeEnum.view && canUpdateTimesheetGroup && (
              <ViewSettingsOptions
                projectId={projectId}
                groupId={timesheetGroupId ? timesheetGroupId : Number(groupId)}
                setPopupMode={setPopupMode}
                timesheetGroupName={headerText!}
                redirectLink={redirectLink}
              />
            )}
            {popupMode == ProjectTimesheetGroupPopupModeEnum.addGroupMember && (
              <AddGroupMember
                setPopupMode={setPopupMode}
                projectId={projectId}
                groupId={timesheetGroupId ? timesheetGroupId : Number(groupId)}
                setNewGroupMembersLength={setNewGroupMembersLength}
              />
            )}
            {popupMode == ProjectTimesheetGroupPopupModeEnum.addSection && (
              <AddTimesheetGroupManagerialEntity
                groupId={timesheetGroupId ?? Number(groupId)}
                projectId={projectId}
                setPopupMode={setPopupMode}
                managerialEntityType={FrontendTimesheetGroupManagerialEntity.sectionHead}
              />
            )}
            {popupMode == ProjectTimesheetGroupPopupModeEnum.addDepartmentManager && (
              <AddTimesheetGroupManagerialEntity
                groupId={timesheetGroupId ?? Number(groupId)}
                projectId={projectId}
                setPopupMode={setPopupMode}
                managerialEntityType={FrontendTimesheetGroupManagerialEntity.departmentManager}
              />
            )}
          </div>
        </div>
      )}
    </PopupStructure>
  );
};

export default TimesheetGroupSettingsPopup;
