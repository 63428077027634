import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiResponseBodyTimesheetGroupGetDepartmentManager } from "epcm-common/dist/Types/TimesheetGroupTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getTimesheetGroupDepartmentManager = async (
  id: number,
  timesheetGroupId: number,
): Promise<ApiResponseBodyTimesheetGroupGetDepartmentManager> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/department-manager`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  const responseData = response.data as ApiResponseBodyTimesheetGroupGetDepartmentManager;
  // const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { departmentManager: responseData.departmentManager };
};
