import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import GlobalConfigurationHolidaysStyles from "./GlobalConfigurationHolidays.module.css";
import ConfigurationEntityTableManager from "../../../projects/project/configuration/configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import { ConfigurationTargetType, GlobalConfigurationScreenType } from "../../../../types/settings/GlobalConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import NewDisciplineIcon from "../../../../assets/images/configuration/new-discipline-icon.svg";
import { configurationsPopupType } from "../../../../types/projects/ConfigurationTypes.ts";
import GlobalConfigurationDisciplinesStyles from "../globalconfigurationsdisciplines/GlobalConfigurationDisciplines.module.css";
import SearchBarSubHeaderStyles from "../../../projects/project/configuration/searchbarsubheader/SearchBarSubHeader.module.css";
import GlobalConfigurationHolidaysPopup from "../popups/globalconfigurationholidayspopup/GlobalConfigurationHolidaysPopup.tsx";
import { SettingsPagePopups, useSettingsPopups } from "../../use-settings-pupups.ts";

const GlobalConfigurationHolidays = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canCreateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigCreate);
  return (
    <div
      className={classNames(
        GlobalConfigurationHolidaysStyles.paddingContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap3,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
          GlobalConfigurationHolidaysStyles.contentContainer,
        )}
      >
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
            GlobalStyles.overflowAutoFullHeight,
            GlobalConfigurationDisciplinesStyles.nestedContainerPadding,
          )}
        >
          <div className={classNames(SearchBarSubHeaderStyles.ctrTitleItem)}>{" Holidays"}</div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap,
                GlobalConfigurationHolidaysStyles.mainContent,
              )}
            >
              <ConfigurationEntityTableManager
                searchQuery={""}
                globalConfigurationScreenType={GlobalConfigurationScreenType.holidays}
                target={ConfigurationTargetType.global}
              />
            </div>
          </div>
        </div>

        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalConfigurationHolidaysStyles.actionBtnContainer)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          {canCreateConfiguration && (
            <div
              onClick={() => onOpenPopup(SettingsPagePopups.addGlobalHoliday, popupHandler)}
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
            >
              <div>Add Holiday</div>
              <div className={classNames(GlobalConfigurationHolidaysStyles.actionBtnImage)}>
                <img src={NewDisciplineIcon} alt="Add Holiday" />
              </div>
            </div>
          )}
        </div>
      </div>
      {popupHandler.get(SettingsPagePopups.addGlobalHoliday)!.isOpen && (
        <GlobalConfigurationHolidaysPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(SettingsPagePopups.addGlobalHoliday, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.addGlobalHoliday)!.isOpen}
          secondaryHeaderText={""}
          headerText={popupHeaders.get(SettingsPagePopups.addGlobalHoliday)}
        />
      )}
    </div>
  );
};

export default GlobalConfigurationHolidays;
