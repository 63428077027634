import { PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import CtrMappingActionsPopupStyles from "./CtrMappingActionsPopup.module.css";
import classNames from "classnames";
import downloadTemplateIcon from "../../../../assets/images/spreadsheet-download.svg";
import uploadTemplateIcon from "../../../../assets/images/spreadsheet-upload.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import useEpcmProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";
import { FileImportTypeEnum, FrontendFileAction } from "../../../../types/projects/FileTypes.ts";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { ApiResponseTypeEnum } from "../../../../types/apicallstypes/queryCommons.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { CtrMappingButtonsEnum } from "../../../../types/projects/ProjectsTypes.ts";
import InfoMessageIcon from "../../../../assets/images/info-message-icon.svg";
import { Fade, Tooltip } from "@mui/material";
import { useEpcmApiProjectCtrMapping } from "../../../../apicalls/projects/projectctrmapping/useEpcmApiProjectCtrMapping.ts";
import { UploadFilePopup } from "../uploadfilepopup/UploadFilePopup.tsx";

export interface CtrMappingActionsProps extends PopupType {}

export const CtrMappingActions: FC<CtrMappingActionsProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText }) => {
  const [currentlyHovered, setCurrentlyHovered] = useState<CtrMappingButtonsEnum | null>(null);
  const { projectId } = useParams();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { addNotification, updateNotification } = useNotificationContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getProjectCtrMappingExportedFile } = useEpcmApiProjectCtrMapping();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canUpdateCtrMapping = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { processFileEvents: processFileEventsCtrMappingExport } = useEpcmProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );

  const startFileEventsListenerCtrMapping = async (fileId: string) => {
    await processFileEventsCtrMappingExport(fileId);
  };

  const exportCtrMappingToFile = useCallback(async () => {
    getProjectCtrMappingExportedFile(parseInt(projectId!))
      .then((value) => {
        void startFileEventsListenerCtrMapping(value.fileId);
      })
      .catch((error) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response);
        onOpenResponseAlertPopup();
      });
  }, [
    projectId,
    addNotification,
    getProjectCtrMappingExportedFile,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
    startFileEventsListenerCtrMapping,
  ]);

  const CTR_MAPPING_BUTTONS_DESCRIPTIONS: Readonly<Record<CtrMappingButtonsEnum, string>> = {
    [CtrMappingButtonsEnum.IMPORT_CTR_MAPPING]:
      "Upload a file with data regarding the mapping between Manpower Positions and CTR codes. Creates new mapping records if not found.",
    [CtrMappingButtonsEnum.EXPORT_CTR_MAPPING]:
      "Download a template to enter mapping relations between Manpower Positions and CTR codes. Add entries to register new mapping records.",
  } as const;

  return (
    <PopupStructure popupButtons={[]} isOpen={isOpen} closeFn={closeFn} headerText={headerText} secondaryHeaderText={secondaryHeaderText}>
      <div className={classNames(CtrMappingActionsPopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.gap)}>
        {canUpdateCtrMapping && (
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
            onMouseEnter={() => setCurrentlyHovered(CtrMappingButtonsEnum.IMPORT_CTR_MAPPING)}
            onMouseLeave={() => setCurrentlyHovered(null)}
            onClick={() => {
              onOpenPopup(ProjectsPagePopups.uploadFile, popupHandler);
            }}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(CtrMappingActionsPopupStyles.templateIconImg)} src={uploadTemplateIcon} alt="downloadTemplate" />
            </div>
            <div
              className={classNames(
                CtrMappingActionsPopupStyles.actionText,
                currentlyHovered === CtrMappingButtonsEnum.IMPORT_CTR_MAPPING && CtrMappingActionsPopupStyles.actionTextHovered,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.gap05,
              )}
            >
              {"Import CTR Mapping"}
              <Tooltip title={CTR_MAPPING_BUTTONS_DESCRIPTIONS[CtrMappingButtonsEnum.IMPORT_CTR_MAPPING]} arrow TransitionComponent={Fade}>
                <div className={classNames(CtrMappingActionsPopupStyles.infoIcon, GlobalStyles.flex, GlobalStyles.centerVertical)}>
                  <img src={InfoMessageIcon} className={classNames(CtrMappingActionsPopupStyles.infoImg)} alt="info message icon" />
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setCurrentlyHovered(CtrMappingButtonsEnum.EXPORT_CTR_MAPPING)}
          onMouseLeave={() => setCurrentlyHovered(null)}
          onClick={() => {
            if (isAuthorized) {
              void exportCtrMappingToFile();
            }
          }}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(CtrMappingActionsPopupStyles.templateIconImgRotated)} src={downloadTemplateIcon} alt="downloadTemplate" />
          </div>
          <div
            className={classNames(
              CtrMappingActionsPopupStyles.actionText,
              currentlyHovered === CtrMappingButtonsEnum.EXPORT_CTR_MAPPING && CtrMappingActionsPopupStyles.actionTextHovered,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap05,
            )}
          >
            {"Export CTR Mapping Template "}
            <Tooltip title={CTR_MAPPING_BUTTONS_DESCRIPTIONS[CtrMappingButtonsEnum.EXPORT_CTR_MAPPING]} arrow TransitionComponent={Fade}>
              <div className={classNames(CtrMappingActionsPopupStyles.infoIcon)}>
                <img src={InfoMessageIcon} className={classNames(CtrMappingActionsPopupStyles.infoImg)} alt="info message icon" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.uploadFile)!.isOpen && (
        <UploadFilePopup
          isOpen={popupHandler.get(ProjectsPagePopups.uploadFile)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.uploadFile, popupHandler);
          }}
          uploadFileType={FileImportTypeEnum.CTR_MAPPING}
          headerText={popupHeaders.get(ProjectsPagePopups.uploadFile)}
          secondaryHeaderText={"Please, select an action"}
        />
      )}

      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();

            if (responseType === ApiResponseTypeEnum.success) {
              closeFn();
            }
          }}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </PopupStructure>
  );
};

export default CtrMappingActions;
