import UserRolesListStyles from "./UserRolesList.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FrontendRoleNormal } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutesPaths } from "../../../../../types/AppRoutesTypes.ts";

interface UserRolesListProps {
  rolesList: FrontendRoleNormal[];

  locallySelectedRoles: number[];
  setLocallySelectedRoles: Dispatch<SetStateAction<number[]>>;
  isScopeProject: boolean;
}

const UserRolesList: FC<UserRolesListProps> = ({ rolesList, locallySelectedRoles, setLocallySelectedRoles, isScopeProject }) => {
  const nonSpecialRoles = rolesList.filter((role) => role.isEligible);
  const specialRoles = rolesList.filter((role) => !role.isEligible);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const isRoleInTheSelectedRoles = (roleId: number) => {
    return locallySelectedRoles.includes(roleId);
  };
  const handleRadioChange = (roleId: number) => {
    if (isRoleInTheSelectedRoles(roleId)) {
      setLocallySelectedRoles(locallySelectedRoles.filter((role) => role !== roleId));
    } else {
      setLocallySelectedRoles([...locallySelectedRoles, roleId]);
    }
  };
  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.gap,
        GlobalStyles.flexDirectionColumn,
        UserRolesListStyles.radioGroupContainer,
        GlobalStyles.gap2,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
        {nonSpecialRoles.map((role) => (
          <div
            key={role.id}
            onClick={() => {
              handleRadioChange(role.id);
            }}
            className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.centerHorizontal, GlobalStyles.elementWithCursor)}
          >
            <input
              type="radio"
              value={role.id}
              className={classNames(UserRolesListStyles.radioInput, GlobalStyles.elementWithCursor)}
              checked={isRoleInTheSelectedRoles(role.id)}
              onChange={() => handleRadioChange(role.id)}
            />
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025)}>
              <div className={classNames(UserRolesListStyles.roleName)}>{role.name}</div>
              {role.description && <div className={classNames(UserRolesListStyles.roleDescription)}>{`(${role.description})`}</div>}
            </div>
          </div>
        ))}
      </div>
      {isScopeProject && (
        <>
          <div className={classNames(UserRolesListStyles.separator)}></div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
              <div
                className={classNames(UserRolesListStyles.projectInfoLink)}
                onClick={() =>
                  navigate(`/${AppRoutesPaths.projects}/${projectId!}/${AppRoutesPaths.configurations}/${AppRoutesPaths.projectConfigurationsInfo}`)
                }
              >
                You can only change these roles from Project Info
              </div>
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
              {specialRoles.map((role) => (
                <div key={role.id} className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.centerHorizontal)}>
                  <input
                    type="radio"
                    disabled
                    value={role.id}
                    className={classNames(
                      UserRolesListStyles.radioInput,
                      UserRolesListStyles.disabledInput,
                      !isRoleInTheSelectedRoles(role.id) && UserRolesListStyles.invisibleInput,
                    )}
                    checked={isRoleInTheSelectedRoles(role.id)}
                  />
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025)}>
                    <div className={classNames(UserRolesListStyles.roleName)}>{role.name}</div>
                    {role.description && <div className={classNames(UserRolesListStyles.roleDescription)}>{`(${role.description})`}</div>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default UserRolesList;
