import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetFormItemStyles from "./TimesheetFormItemStyles.module.css";
import { FrontendGroupsTimesheetModeEnum, FrontendTimesheetLimitedType } from "../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { FC, useEffect, useMemo } from "react";

import TimesheetForm from "./timesheetform/TimesheetForm.tsx";
import { Link, useLocation, useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames, SubQueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { Skeleton } from "@mui/material";
import { GroupTimesheetListItem } from "../grouptimesheetlist/grouptimesheetslistitem/GroupTimesheetsListItem.tsx";
import ArrowUpWhite from "../../../../../../assets/images/arrow-up-white.svg";
import { AppRoutesPaths } from "../../../../../../types/AppRoutesTypes.ts";
import { convertFrontendTimesheetStatusToProjectTimesheetTabStatusEnum } from "../../../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/ProjectTimesheetsApiConverters.ts";

interface TimesheetFormItemProps {
  timesheetItemId: number;
}

const TimesheetFormItem: FC<TimesheetFormItemProps> = ({ timesheetItemId }) => {
  const { projectId, groupId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getTimesheetGroupTimesheet } = useEpcmApiProjectsTimesheetsGroups();
  const queryClient = useQueryClient();
  const location = useLocation();

  const groupTimesheetQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupId, timesheetItemId, DetailLevel.LIMITED],
    queryFn: () => getTimesheetGroupTimesheet(parseInt(projectId!), parseInt(groupId!), timesheetItemId, DetailLevel.LIMITED),

    enabled: isAuthorized,
    select: (data) => data as FrontendTimesheetLimitedType,
  });

  const groupTimesheetData = useMemo(() => {
    return groupTimesheetQuery.data ?? undefined;
  }, [groupTimesheetQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId, groupId, timesheetItemId, DetailLevel.LIMITED] })
        .then(() => console.log(`In timesheet form with id ${timesheetItemId}, ${QueryNames.ProjectTimesheetGroupTimesheets} query canceled`));
    };
  }, [queryClient, projectId, groupId, timesheetItemId]);

  const backToListLink = useMemo(() => {
    if (groupTimesheetData) {
      if (location.state?.from !== SubQueryNames.timesheetList) {
        return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${
          AppRoutesPaths.projectSingleTimesheetsGroups
        }/${groupId}?status=${convertFrontendTimesheetStatusToProjectTimesheetTabStatusEnum(
          groupTimesheetData.status,
        )}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}`;
      } else {
        return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}`;
      }
    }
    return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}`;
  }, [groupTimesheetData, projectId, groupId, location.state]);

  return groupTimesheetQuery.isLoading ? (
    <Skeleton variant="rounded" height={75} width={"100%"} />
  ) : (
    !groupTimesheetQuery.isLoading && groupTimesheetData && (
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.gap)}>
        <div className={classNames(TimesheetFormItemStyles.timesheetItemContainer, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <GroupTimesheetListItem
            timesheetListStatus={convertFrontendTimesheetStatusToProjectTimesheetTabStatusEnum(groupTimesheetData.status)}
            inList={false}
            groupTimesheetItem={groupTimesheetData}
            hideActions={true}
          />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <Link to={backToListLink} className={classNames(TimesheetFormItemStyles.fillInButton, GlobalStyles.flex, GlobalStyles.gap05)}>
              <div>{"Close"}</div>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img src={ArrowUpWhite} className={classNames()} alt={"arrow"} />
              </div>
            </Link>
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.overflowHiddenFullHeight)}>
          {groupTimesheetData && (
            <TimesheetForm groupTimesheet={groupTimesheetData} backToListLink={backToListLink} timesheetStatus={groupTimesheetData.status} />
          )}
        </div>
      </div>
    )
  );
};
export default TimesheetFormItem;
