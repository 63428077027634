import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { HolidayLimited } from "epcm-common/dist/Types/HolidayTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectHolidays = async (
  id: number,
  pageOffset?: number,
  searchFilter?: string,
  pageSize = PAGINATION_PAGE_SIZE,
): Promise<QueryModelWithPagination<HolidayLimited>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/holidays`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const responseData = response.data as QueryModelWithPagination<HolidayLimited>;

  return { data: responseData.data, nextPage: null, totalCount: responseData.totalCount };
};
