import { FC } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetGroupSingleStyles from "../TimesheetGroupSingleStyles.module.css";
import TimesheetGroupItemClapping from "../../../../../../assets/images/timesheet-group-item-clapping.svg";
import { ProjectTimesheetTabStatusEnum } from "../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { capitalizeFirstLetter } from "../../../../../../utils/StringManipulation.ts";

interface TimesheetEmptyListMsgProps {
  timesheetListStatus: ProjectTimesheetTabStatusEnum;
}

const TimesheetEmptyListMsg: FC<TimesheetEmptyListMsgProps> = ({ timesheetListStatus }) => {
  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.centerVertical,
        GlobalStyles.centerHorizontal,
        GlobalStyles.gap,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          <img className={classNames(TimesheetGroupSingleStyles.clappingIconImg)} src={TimesheetGroupItemClapping} alt={"clapping-icon"} />
        </div>
        <div className={classNames(TimesheetGroupSingleStyles.extraLargeScaleText)}>{"Splendid!"}</div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        <div
          className={classNames(TimesheetGroupSingleStyles.largeScaleText)}
        >{`There are no ${capitalizeFirstLetter(timesheetListStatus)} timesheets`}</div>
        {/*<div className={classNames(TimesheetGroupSingleStyles.subText)}>{"Stay around once a new timesheet arrives you'll be notified"}</div>*/}
      </div>
    </div>
  );
};

export default TimesheetEmptyListMsg;
