import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";

import GroupMemberItem from "../groupmemberitem/GroupMemberItem.tsx";

import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";

import AddEntityActionButton from "../addentityactionbutton/AddEntityActionButton.tsx";
import TimesheetGroupMemberSectionGrayIcon from "../../../../../assets/images/timesheet-group-member-section-gray-icon.svg";
import { FrontendTimesheetGroupManagerialEntity, ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { Skeleton } from "@mui/material";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { useHandleUnauthorized } from "../../../../../utils/use-handle-unauthorized.ts";
import classNames from "classnames";

interface TimesheetGroupManagerialEntityProps {
  projectId: number;
  groupId: number;
  setPopupMode: Dispatch<SetStateAction<ProjectTimesheetGroupPopupModeEnum>>;
  popupMode: ProjectTimesheetGroupPopupModeEnum;
  timesheetGroupManagerialEntity: FrontendTimesheetGroupManagerialEntity;
}

const TimesheetGroupManagerialEntity: FC<TimesheetGroupManagerialEntityProps> = ({
  projectId,
  groupId,
  popupMode,
  setPopupMode,
  timesheetGroupManagerialEntity,
}) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getTimesheetGroupSectionHead, getTimesheetGroupDepartmentManager } = useEpcmApiProjectsTimesheetsGroups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { handleErrorRedirect } = useHandleUnauthorized();

  const isSectionHeadComponent = useMemo(
    () => timesheetGroupManagerialEntity === FrontendTimesheetGroupManagerialEntity.sectionHead,
    [timesheetGroupManagerialEntity],
  );

  const isDepartmentManagerComponent = useMemo(
    () => timesheetGroupManagerialEntity === FrontendTimesheetGroupManagerialEntity.departmentManager,
    [timesheetGroupManagerialEntity],
  );

  const canUpdateTimesheetGroups = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupUpdate);

  const timesheetGroupSectionHeadQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId],
    queryFn: () => getTimesheetGroupSectionHead(Number(projectId), groupId).catch(handleErrorRedirect),
    enabled: isAuthorized && isSectionHeadComponent,
  });
  const timesheetGroupSectionHeadData = useMemo(() => {
    return timesheetGroupSectionHeadQuery.data ? timesheetGroupSectionHeadQuery.data : null;
  }, [timesheetGroupSectionHeadQuery]);

  const timesheetGroupDepartmentManagerQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupDepartmentManager, projectId, groupId],
    queryFn: () => getTimesheetGroupDepartmentManager(Number(projectId), groupId).catch(handleErrorRedirect),
    enabled: isAuthorized && isDepartmentManagerComponent,
  });

  const timesheetGroupDepartmentManagerData = useMemo(() => {
    return timesheetGroupDepartmentManagerQuery.data ? timesheetGroupDepartmentManagerQuery.data : null;
  }, [timesheetGroupDepartmentManagerQuery]);

  const globalUserName = useMemo(() => {
    if (isSectionHeadComponent) {
      return timesheetGroupSectionHeadData?.sectionHead?.name;
    } else if (isDepartmentManagerComponent) {
      return timesheetGroupDepartmentManagerData?.departmentManager?.name;
    }
    return "";
  }, [isSectionHeadComponent, isDepartmentManagerComponent, timesheetGroupSectionHeadData, timesheetGroupDepartmentManagerData]);

  const globalUserCode = useMemo(() => {
    if (isSectionHeadComponent) {
      return timesheetGroupSectionHeadData?.sectionHead?.code;
    } else if (isDepartmentManagerComponent) {
      return timesheetGroupDepartmentManagerData?.departmentManager?.code;
    }
    return "";
  }, [isSectionHeadComponent, isDepartmentManagerComponent, timesheetGroupSectionHeadData, timesheetGroupDepartmentManagerData]);

  const globalUserId = useMemo(() => {
    if (isSectionHeadComponent) {
      return Number(timesheetGroupSectionHeadData?.sectionHead?.code);
    } else if (isDepartmentManagerComponent) {
      return Number(timesheetGroupDepartmentManagerData?.departmentManager?.code);
    }
    return 0;
  }, [isSectionHeadComponent, isDepartmentManagerComponent, timesheetGroupSectionHeadData, timesheetGroupDepartmentManagerData]);

  useEffect(() => {
    return () => {
      if (isSectionHeadComponent) {
        queryClient
          .cancelQueries({ queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId] })
          .then(() => console.log(`Query ${QueryNames.TimesheetGroupSectionHead} cancelled`));
      } else if (isDepartmentManagerComponent) {
        queryClient
          .cancelQueries({ queryKey: [QueryNames.TimesheetGroupDepartmentManager, projectId, groupId] })
          .then(() => console.log(`Query ${QueryNames.TimesheetGroupDepartmentManager} cancelled`));
      }
    };
  }, [queryClient, projectId, groupId, isSectionHeadComponent, isDepartmentManagerComponent]);

  return (
    <>
      <div>
        {(isSectionHeadComponent && timesheetGroupSectionHeadData?.sectionHead) ||
        (isDepartmentManagerComponent && timesheetGroupDepartmentManagerData?.departmentManager) ? (
          <GroupMemberItem
            projectId={projectId}
            groupId={groupId}
            managerType={timesheetGroupManagerialEntity}
            setPopupMode={setPopupMode}
            projectEmployeeId={globalUserId}
            globalUserName={globalUserName}
            globalUserCode={globalUserCode}
            canBeDeleted={false}
          />
        ) : timesheetGroupSectionHeadQuery.isLoading ? (
          <Skeleton variant="rounded" width={495} height={60} />
        ) : popupMode === ProjectTimesheetGroupPopupModeEnum.view && canUpdateTimesheetGroups ? (
          <AddEntityActionButton
            icon={TimesheetGroupMemberSectionGrayIcon}
            text={
              `Set a ${isSectionHeadComponent ? "Section Head" : "Department Manager"} from the available users.` + `\n` + "You can replace it later"
            }
            actionBtnText={"Add"}
            actionFn={() =>
              isDepartmentManagerComponent
                ? setPopupMode(ProjectTimesheetGroupPopupModeEnum.addDepartmentManager)
                : setPopupMode(ProjectTimesheetGroupPopupModeEnum.addSection)
            }
          />
        ) : (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerBoth, GlobalStyles.emptyListMsgSmall)}>{"Not set"}</div>
        )}
      </div>
    </>
  );
};
export default TimesheetGroupManagerialEntity;
