import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiRequestBodyTimesheetGroupAddDepartmentManager } from "epcm-common/dist/Types/TimesheetGroupTypes";

const API = `${EPCM_API_URLS.projects}`;

export const createTimesheetGroupDepartmentManager = async (
  projectId: number,
  timesheetGroupId: number,
  departmentManagerCode: ApiRequestBodyTimesheetGroupAddDepartmentManager,
) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups/${timesheetGroupId}/department-manager`,
    method: "POST",
    data: departmentManagerCode,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
