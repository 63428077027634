import { QueryModelWithPagination, QueryNames } from "../apicallstypes/queryCommons.ts";
import {
  FrontendGlobalCategoryType,
  FrontendGlobalCtrType,
  FrontendGlobalDisciplineType,
  FrontendGlobalHolidayType,
  FrontendGlobalLocationType,
  FrontendGlobalPositionType,
  FrontendGlobalTradeType,
  GlobalConfigurationEndpointType,
} from "../apicallstypes/ConfigurationsTypes.ts";
import { GlobalCategoryLimited } from "epcm-common/dist/Types/GlobalCategoryTypes";
import { GlobalCtrLimited } from "epcm-common/dist/Types/GlobalCtrTypes";
import { GlobalDisciplineLimited } from "epcm-common/dist/Types/GlobalDisciplineTypes";
import { GlobalLocationLimited } from "epcm-common/dist/Types/GlobalLocation";
import { GlobalPositionLimited } from "epcm-common/dist/Types/GlobalPositionTypes";
import { GlobalTradeLimited } from "epcm-common/dist/Types/GlobalTradeTypes";
import { FrontendGlobalUserTypeWithRoleIds } from "../projects/PermissionTypes.ts";

export enum GlobalConfigurationScreenType {
  positions = "positions",
  locations = "locations",
  trades = "trades",
  disciplines = "disciplines",
  categories = "categories",
  ctrLibrary = "ctr-library",
  permissions = "permissions",
  holidays = "holidays",
}

export enum ConfigurationTargetType {
  global = "global",
  project = "project",
}

export const globalConfigurationScreenGetQueryNameMap = new Map<GlobalConfigurationScreenType, string>([
  [GlobalConfigurationScreenType.positions, QueryNames.GlobalPositions],
  [GlobalConfigurationScreenType.locations, QueryNames.GlobalLocations],
  [GlobalConfigurationScreenType.trades, QueryNames.GlobalTrades],
  [GlobalConfigurationScreenType.categories, QueryNames.GlobalCategories],
  [GlobalConfigurationScreenType.disciplines, QueryNames.GlobalDisciplines],
  [GlobalConfigurationScreenType.ctrLibrary, QueryNames.GlobalCtrs],
  [GlobalConfigurationScreenType.permissions, QueryNames.GlobalPermissions],
  [GlobalConfigurationScreenType.holidays, QueryNames.GlobalHolidays],
]);

export type GlobalPositionsFunction = (
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalPositionType>>;

export type GlobalLocationsFunction = (
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalLocationType>>;

export type GlobalTradesFunction = (
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalTradeType>>;

export type GlobalCategoriesFunction = (
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalCategoryType>>;

export type GlobalDisciplinesFunction = (
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalDisciplineType>>;

export type GlobalCtrsFunction = (
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalCtrType>>;

export type GlobalPermissionsFunction = (
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalUserTypeWithRoleIds>>;

export type GlobalHolidaysFunction = (
  pageOffset?: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendGlobalHolidayType>>;

export type GlobalConfigurationFunctionType =
  | GlobalPositionsFunction
  | GlobalLocationsFunction
  | GlobalTradesFunction
  | GlobalCategoriesFunction
  | GlobalDisciplinesFunction
  | GlobalCtrsFunction
  | GlobalPermissionsFunction
  | GlobalHolidaysFunction;

export enum GlobalConfigurationTableColumns {
  positions = "positions",
  ctrManagement = "ctr-management",
  trades = "trades",
  locations = "locations",
  categories = "categories",
  ctrLibrary = "ctr-library",
  disciplines = "disciplines",
  permissions = "permissions",
  holidays = "holidays",
}

export const globalConfigurationColumnDefinitions = {
  [GlobalConfigurationTableColumns.positions]: [
    { key: "code", header: "POSITION CODE" },
    { key: "description", header: "POSITION DESCRIPTION" },
    { key: "subpositions", header: "" },
    { key: "actions", header: "ACTIONS" },
  ],
  [GlobalConfigurationTableColumns.ctrManagement]: [
    { key: "code", header: "CODE" },
    { key: "description", header: "DESCRIPTION" },
  ],
  [GlobalConfigurationTableColumns.trades]: [
    { key: "code", header: "TRADE CODE" },
    { key: "title", header: "TRADE DESCRIPTION" },
    { key: "actions", header: "ACTIONS" },
  ],
  [GlobalConfigurationTableColumns.locations]: [
    { key: "code", header: "LOCATION CODE" },
    { key: "description", header: "LOCATION DESCRIPTION" },
    { key: "worked-hours", header: "WORKED HOURS" },
    { key: "actions", header: "ACTIONS" },
  ],
  [GlobalConfigurationTableColumns.ctrLibrary]: [
    { key: "code", header: "CTR CODE" },
    { key: "description", header: "CTR DESCRIPTION" },
    { key: "type", header: "TYPE" },
    { key: "category", header: "CATEGORY" },
    { key: "actions", header: "ACTIONS" },
  ],
  [GlobalConfigurationTableColumns.categories]: [
    { key: "code", header: "CATEGORY CODE" },
    { key: "title", header: "CATEGORY TITLE" },
    { key: "actions", header: "ACTIONS" },
  ],
  [GlobalConfigurationTableColumns.disciplines]: [
    { key: "code", header: "DISCIPLINE CODE" },
    { key: "title", header: "DISCIPLINE TITLE" },
    { key: "actions", header: "ACTIONS" },
  ],
  [GlobalConfigurationTableColumns.permissions]: [
    { key: "code", header: "CODE   " },
    { key: "name", header: "NAME" },
    { key: "role", header: "ROLE" },
    { key: "actions", header: "ACTIONS" },
  ],
  [GlobalConfigurationTableColumns.holidays]: [
    { key: "description", header: "DESCRIPTION" },
    { key: "startDate", header: "START DATE" },
    { key: "endDate", header: "END DATE" },
    { key: "duration", header: "DURATION" },
    { key: "isRecurring", header: "RECURRING" },
    { key: "setAsDefault", header: "DEFAULT" },
    { key: "actions", header: "ACTIONS" },
  ],
};

export interface GlobalConfigurationsFunctionMap {
  [GlobalConfigurationScreenType.positions]: GlobalPositionsFunction;
  [GlobalConfigurationScreenType.locations]: GlobalLocationsFunction;
  [GlobalConfigurationScreenType.trades]: GlobalTradesFunction;
  [GlobalConfigurationScreenType.categories]: GlobalCategoriesFunction;
  [GlobalConfigurationScreenType.disciplines]: GlobalDisciplinesFunction;
  [GlobalConfigurationScreenType.ctrLibrary]: GlobalCtrsFunction;
  [GlobalConfigurationScreenType.permissions]: GlobalPermissionsFunction;
  [GlobalConfigurationScreenType.holidays]: GlobalHolidaysFunction;
  // [ConfigurationScreenType.trades]: void;
}

export const getColSpanByGlobalConfigurationScreen = new Map<GlobalConfigurationScreenType, number>([
  [GlobalConfigurationScreenType.positions, 3],
  [GlobalConfigurationScreenType.trades, 3],
  [GlobalConfigurationScreenType.locations, 4],
  [GlobalConfigurationScreenType.categories, 3],
  [GlobalConfigurationScreenType.ctrLibrary, 5],
  [GlobalConfigurationScreenType.disciplines, 3],
  [GlobalConfigurationScreenType.permissions, 4],
  [GlobalConfigurationScreenType.holidays, 7],
]);

export type globalConfigurationRowItemType =
  | FrontendGlobalLocationType
  | FrontendGlobalPositionType
  | FrontendGlobalTradeType
  | FrontendGlobalCategoryType
  | FrontendGlobalDisciplineType
  | FrontendGlobalCtrType
  | FrontendGlobalUserTypeWithRoleIds
  | FrontendGlobalHolidayType;

export type GlobalConfigurationSingleResponseType<T extends GlobalConfigurationEndpointType> = T extends GlobalConfigurationEndpointType.Categories
  ? GlobalCategoryLimited
  : T extends GlobalConfigurationEndpointType.Ctrs
    ? GlobalCtrLimited
    : T extends GlobalConfigurationEndpointType.Disciplines
      ? GlobalDisciplineLimited
      : T extends GlobalConfigurationEndpointType.Locations
        ? GlobalLocationLimited
        : T extends GlobalConfigurationEndpointType.Positions
          ? GlobalPositionLimited
          : T extends GlobalConfigurationEndpointType.Trades
            ? GlobalTradeLimited
            : T extends GlobalConfigurationEndpointType.Permissions
              ? FrontendGlobalUserTypeWithRoleIds
              : T extends GlobalConfigurationEndpointType.Holidays
                ? FrontendGlobalHolidayType
                : never;

export enum JustificationForRequestEnum {
  Mobilization = "Mobilization",
  Demobilization = "Demobilization",
  ChangeOfPosition = "Change of Position",
}

export const JustificationForRequestOptionsArray = [
  JustificationForRequestEnum.Mobilization,
  JustificationForRequestEnum.Demobilization,
  JustificationForRequestEnum.ChangeOfPosition,
];
