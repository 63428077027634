import { FrontendUserRolesType } from "./apicallstypes/UsersApiTypes.ts";

export enum GlobalRole {
  Admin = "Admin",
  User = "User",
  ConfigurationAdmin = "Configuration Admin",
  EmployeeRegistration = "Employee Registration",
  ProjectCreator = "Project Creator",
  Viewer = "Viewer",
}

export enum ProjectRole {
  Admin = "Admin",
  ConfigurationAdmin = "Configuration Admin",
  User = "User",
  ProjectManager = "Project Manager",
  EmployeeRegistration = "Employee Registration",
  ManpowerPlanner = "Manpower Planner",
  TimesheetAdmin = "Timesheet Admin",
  SectionHead = "Section Head",
  Viewer = "Viewer",
  DepartmentManager = "Department Manager",
  CostControlEngineer = "Cost Control Engineer",
  ProjectControlManager = "Project Control Manager",
}

const backendGlobalRolesMapToFrontend: { [key: string]: GlobalRole } = {
  Admin: GlobalRole.Admin,
  User: GlobalRole.User,
  "Configuration Admin": GlobalRole.ConfigurationAdmin,
  "Employee Registration": GlobalRole.EmployeeRegistration,
  "Project Creator": GlobalRole.ProjectCreator,
  Viewer: GlobalRole.Viewer,
};
const backendProjectRolesMapToFrontend: { [key: string]: ProjectRole } = {
  Admin: ProjectRole.Admin,
  "Configuration Admin": ProjectRole.ConfigurationAdmin,
  User: ProjectRole.User,
  "Project Manager": ProjectRole.ProjectManager,
  "Employee Registration": ProjectRole.EmployeeRegistration,
  "Manpower Planner": ProjectRole.ManpowerPlanner,
  "Timesheet Admin": ProjectRole.TimesheetAdmin,
  "Section Head": ProjectRole.SectionHead,
  Viewer: ProjectRole.Viewer,
  "Department Manager": ProjectRole.DepartmentManager,
  "Cost Control Engineer": ProjectRole.CostControlEngineer,
  "Project Control Manager": ProjectRole.ProjectControlManager,
};
export const convertBackendGlobalRoleListToFrontend = (backendGlobalRolesList: FrontendUserRolesType[]): GlobalRole[] => {
  return backendGlobalRolesList.map((role) => backendGlobalRolesMapToFrontend[role.role.name]);
};

export const convertBackendProjectRoleListToFrontend = (backendProjectRolesList: FrontendUserRolesType[]): ProjectRole[] => {
  return backendProjectRolesList.map((role) => backendProjectRolesMapToFrontend[role.role.name]);
};

// Enum for Global Actions
export enum GlobalAction {
  ConfigCreate = "config_create",
  ConfigList = "config_list",
  ConfigUpdate = "config_update",
  ConfigDelete = "config_delete",
  EmployeeCreate = "employee_create",
  EmployeeList = "employee_list",
  EmployeeUpdate = "employee_update",
  EmployeeDelete = "employee_delete",
  UserUpdateRoles = "user_update_roles",
  ProjectCreate = "project_create",
  ProjectList = "project_list",
}

export const globalRoleActions: { [key in GlobalRole]: GlobalAction[] } = {
  [GlobalRole.Admin]: Object.values(GlobalAction), // All actions for Admin
  [GlobalRole.User]: [GlobalAction.ProjectList], // No actions for User
  [GlobalRole.ConfigurationAdmin]: [
    GlobalAction.ProjectList,
    GlobalAction.ConfigCreate,
    GlobalAction.ConfigList,
    GlobalAction.ConfigUpdate,
    GlobalAction.ConfigDelete,
  ],
  [GlobalRole.EmployeeRegistration]: [
    GlobalAction.ProjectList,
    GlobalAction.EmployeeList,
    GlobalAction.EmployeeCreate,
    GlobalAction.EmployeeUpdate,
    GlobalAction.EmployeeDelete,
  ],
  [GlobalRole.ProjectCreator]: [GlobalAction.ProjectCreate, GlobalAction.ProjectList],
  [GlobalRole.Viewer]: [GlobalAction.ConfigList, GlobalAction.EmployeeList, GlobalAction.ProjectList], // View-only permissions
};

// Enum for Project Actions
export enum ProjectAction {
  ProjectConfigCreate = "project_config_create",
  ProjectConfigList = "project_config_list",
  ProjectConfigUpdate = "project_config_update",
  ProjectConfigDelete = "project_config_delete",
  ProjectEditPermissions = "project_edit_permissions",
  ProjectEmployeeCreate = "project_employee_create",
  ProjectEmployeeList = "project_employee_list",
  ProjectEmployeeUpdate = "project_employee_update",
  ProjectEmployeeDelete = "project_employee_delete",
  ProjectManpowerCreate = "project_manpower_create",
  ProjectManpowerList = "project_manpower_list",
  ProjectManpowerUpdate = "project_manpower_update",
  ProjectManpowerDelete = "project_manpower_delete",
  ProjectTimesheetGroupCreate = "project_timesheet_group_create",
  ProjectTimesheetGroupList = "project_timesheet_group_list",
  ProjectTimesheetGroupUpdate = "project_timesheet_group_update",
  ProjectTimesheetGroupRename = "project_timesheet_group_rename",
  ProjectTimesheetGroupDelete = "project_timesheet_group_delete",
  ProjectTimesheetAddToGroup = "project_timesheet_add_to_group",
  ProjectTimesheetListList = "project_timesheet_list_list",
  ProjectTimesheetListDelete = "project_timesheet_list_delete",
  ProjectTimesheetListCreate = "project_timesheet_list_create",
  ProjectTimesheetStatisticsList = "project_timesheet_statistics_list",
  ProjectTimesheetFillData = "project_timesheet_fill_data",
  ProjectTimesheetChangeStatus = "project_timesheet_change_status",
  ProjectTimesheetChangeAllStatuses = "project_timesheet_change_all_statuses",
  ProjectTimesheetChangeStatusFromPendingToPrepared = "project_timesheet_change_status_from_pending_to_prepared",
  ProjectTimesheetChangeStatusFromPreparedToChecked = "project_timesheet_change_status_from_prepared_to_checked",
  ProjectTimesheetChangeStatusFromCheckedToReviewed = "project_timesheet_change_status_from_checked_to_reviewed",
  ProjectTimesheetChangeStatusFromReviewedToApproved = "project_timesheet_change_status_from_reviewed_to_approved",
  ProjectTimesheetChangeStatusFromReviewedToChecked = "project_timesheet_change_status_from_reviewed_to_checked",
  ProjectTimesheetChangeStatusFromApprovedToReviewed = "project_timesheet_change_status_from_approved_to_reviewed",
  ProjectTimesheetChangeStatusFromPreparedToReviewed = "project_timesheet_change_status-prepared_to_reviewed",
  ProjectTimesheetChangeStatusFromReviewedToPrepared = "project_timesheet_change_status-reviewed_to_prepared",
  ProjectTimesheetChangeStatusFromPreparedToPending = "project_timesheet_change_status-prepared_to_pending",
  ProjectTimesheetGenerateReport = "project_timesheet_generate_report",
  ProjectListDocuments = "project_list_documents",
  ProjectListDocumentDownload = "project_list_document_download",
  ProjectListDocumentDelete = "project_list_document_delete",
}

export const projectViewingActions: ProjectAction[] = [
  ProjectAction.ProjectConfigList,
  ProjectAction.ProjectEmployeeList,
  ProjectAction.ProjectManpowerList,
  ProjectAction.ProjectTimesheetGroupList,
  ProjectAction.ProjectTimesheetListList,
  ProjectAction.ProjectTimesheetStatisticsList,
  ProjectAction.ProjectListDocuments,
  ProjectAction.ProjectListDocumentDownload,
];

export const projectRoleActions: { [key in ProjectRole]: ProjectAction[] } = {
  [ProjectRole.Admin]: Object.values(ProjectAction), // All actions for Admin
  [ProjectRole.ConfigurationAdmin]: [
    ProjectAction.ProjectConfigCreate,
    ProjectAction.ProjectConfigList,
    ProjectAction.ProjectConfigUpdate,
    ProjectAction.ProjectConfigDelete,
  ],
  [ProjectRole.ProjectManager]: [], // View-only permissions

  [ProjectRole.User]: [], // No actions for User
  [ProjectRole.EmployeeRegistration]: [
    ProjectAction.ProjectEmployeeCreate,
    ProjectAction.ProjectEmployeeList,
    ProjectAction.ProjectEmployeeUpdate,
    ProjectAction.ProjectEmployeeDelete,
    ProjectAction.ProjectListDocuments,
  ],
  [ProjectRole.ManpowerPlanner]: [
    ProjectAction.ProjectManpowerCreate,
    ProjectAction.ProjectManpowerList,
    ProjectAction.ProjectManpowerUpdate,
    ProjectAction.ProjectManpowerDelete,
    ProjectAction.ProjectListDocumentDownload,
    ProjectAction.ProjectListDocumentDelete,
    ProjectAction.ProjectListDocuments,
  ],
  [ProjectRole.TimesheetAdmin]: [
    ProjectAction.ProjectTimesheetGroupCreate,
    ProjectAction.ProjectTimesheetGroupList,
    ProjectAction.ProjectTimesheetGroupUpdate,
    ProjectAction.ProjectTimesheetGroupDelete,
    ProjectAction.ProjectTimesheetGroupRename,
    ProjectAction.ProjectTimesheetListList,
    ProjectAction.ProjectTimesheetListCreate,
    ProjectAction.ProjectTimesheetListDelete,
    ProjectAction.ProjectTimesheetFillData,
    ProjectAction.ProjectTimesheetAddToGroup,
    ProjectAction.ProjectTimesheetStatisticsList,
    ProjectAction.ProjectTimesheetGenerateReport,
    ProjectAction.ProjectTimesheetChangeStatus,
    ProjectAction.ProjectTimesheetChangeAllStatuses,
    ProjectAction.ProjectListDocuments,
    ProjectAction.ProjectListDocumentDownload,
    ProjectAction.ProjectListDocumentDelete,
  ],

  [ProjectRole.CostControlEngineer]: [
    ProjectAction.ProjectTimesheetListList,
    ProjectAction.ProjectTimesheetGroupList,
    ProjectAction.ProjectTimesheetStatisticsList,
    ProjectAction.ProjectTimesheetGenerateReport,
    ProjectAction.ProjectTimesheetFillData,
    ProjectAction.ProjectTimesheetChangeStatus,
    ProjectAction.ProjectTimesheetChangeStatusFromCheckedToReviewed,
    ProjectAction.ProjectListDocuments,
    ProjectAction.ProjectListDocumentDownload,
  ],

  [ProjectRole.ProjectControlManager]: [
    ProjectAction.ProjectTimesheetListList,
    ProjectAction.ProjectTimesheetGroupList,
    ProjectAction.ProjectTimesheetStatisticsList,
    ProjectAction.ProjectTimesheetGenerateReport,
    ProjectAction.ProjectTimesheetFillData,
    ProjectAction.ProjectTimesheetChangeStatus,
    ProjectAction.ProjectTimesheetChangeStatusFromReviewedToApproved,
    ProjectAction.ProjectTimesheetChangeStatusFromReviewedToChecked,
    ProjectAction.ProjectTimesheetChangeStatusFromApprovedToReviewed,
    ProjectAction.ProjectListDocuments,
    ProjectAction.ProjectListDocumentDownload,
  ],

  [ProjectRole.SectionHead]: [
    ProjectAction.ProjectTimesheetGroupList,
    ProjectAction.ProjectTimesheetGroupRename,
    ProjectAction.ProjectTimesheetListList,
    ProjectAction.ProjectTimesheetListCreate,
    ProjectAction.ProjectTimesheetListDelete,
    ProjectAction.ProjectTimesheetStatisticsList,
    ProjectAction.ProjectTimesheetAddToGroup,
    ProjectAction.ProjectTimesheetFillData,
    ProjectAction.ProjectTimesheetChangeStatus,
    ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared,
    ProjectAction.ProjectListDocuments,
    ProjectAction.ProjectListDocumentDownload,
  ],
  [ProjectRole.DepartmentManager]: [
    ProjectAction.ProjectTimesheetGroupList,
    // ProjectAction.ProjectTimesheetGroupCreate,
    // ProjectAction.ProjectTimesheetGroupUpdate,
    // ProjectAction.ProjectTimesheetGroupDelete,
    ProjectAction.ProjectTimesheetGroupRename,
    ProjectAction.ProjectTimesheetListList,
    ProjectAction.ProjectTimesheetStatisticsList,
    ProjectAction.ProjectTimesheetAddToGroup,
    ProjectAction.ProjectTimesheetFillData,
    ProjectAction.ProjectTimesheetChangeStatus,
    ProjectAction.ProjectTimesheetChangeStatusFromPreparedToChecked,
    ProjectAction.ProjectListDocuments,
    ProjectAction.ProjectListDocumentDownload,
  ],
  [ProjectRole.Viewer]: [
    ProjectAction.ProjectConfigList,
    ProjectAction.ProjectConfigList,
    ProjectAction.ProjectEmployeeList,
    ProjectAction.ProjectManpowerList,
    ProjectAction.ProjectTimesheetGroupList,
    ProjectAction.ProjectTimesheetListList,
    ProjectAction.ProjectTimesheetStatisticsList,
    ProjectAction.ProjectListDocuments,
    ProjectAction.ProjectListDocumentDownload,
  ], // View-only permissions
};
