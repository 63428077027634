import { FrontendTimesheetStatusEnum, FrontendTimesheetType } from "../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import classNames from "classnames";
import ChangeTimesheetStatusContentStyles from "./ChangeTimesheetStatusContent.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import checkIcon from "../../../../../assets/images/tick-white.svg";
import { capitalizeFirstLetter } from "../../../../../utils/StringManipulation.ts";
// import pendingIconTimesheetStatus from "../../../../../assets/images/pending-timesheet-icon-status.svg";
// import checkCircledIcon from "../../../../../assets/images/tick-circled.svg";
// import xMarkCircledIcon from "../../../../../assets/images/x-mark-circled.svg";

import { FC, useMemo } from "react";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import TimesheetItemActorDetails from "./timesheetitemactordetails/TimesheetItemActorDetails.tsx";
import { findTimesheetStatusActor } from "../../../../../utils/findTimesheetActorByStatus.ts";

interface ChangeTimesheetStatusPopupProps {
  timesheetItem: FrontendTimesheetType;
  statusItem: FrontendTimesheetStatusEnum;
  statusSelected: FrontendTimesheetStatusEnum;
}

const ChangeTimesheetStatusContent: FC<ChangeTimesheetStatusPopupProps> = ({ timesheetItem, statusSelected, statusItem }) => {
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canChangeAllTimesheetStatuses = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeAllStatuses);
  const isChangeStatusFromPendingToPreparedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
  const isChangeStatusFromPreparedToPendingAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToPending);
  const isChangeStatusToReviewedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToReviewed);
  const isChangeStatusBackToPreparedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToPrepared);
  const isChangeStatusFromPreparedToCheckedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToChecked);
  const isChangeStatusFromCheckedToReviewedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromCheckedToReviewed);
  const isChangeStatusFromReviewedToApprovedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToApproved);
  const isChangeStatusFromApprovedToReviewedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromApprovedToReviewed);

  const canShowTheStatus = useMemo(() => {
    if (canChangeAllTimesheetStatuses) {
      return true;
    }
    return (
      (isChangeStatusFromPendingToPreparedAllowed && statusItem === FrontendTimesheetStatusEnum.PENDING) ||
      (isChangeStatusFromPreparedToPendingAllowed && statusItem === FrontendTimesheetStatusEnum.PREPARED) ||
      (isChangeStatusFromPreparedToCheckedAllowed && statusItem === FrontendTimesheetStatusEnum.PREPARED) ||
      (isChangeStatusFromCheckedToReviewedAllowed && statusItem === FrontendTimesheetStatusEnum.CHECKED) ||
      (isChangeStatusFromReviewedToApprovedAllowed && statusItem === FrontendTimesheetStatusEnum.REVIEWED) ||
      (isChangeStatusFromApprovedToReviewedAllowed && statusItem === FrontendTimesheetStatusEnum.APPROVED)
    );
  }, [
    statusItem,
    canChangeAllTimesheetStatuses,
    isChangeStatusFromPendingToPreparedAllowed,
    isChangeStatusFromPreparedToPendingAllowed,
    isChangeStatusToReviewedAllowed,
    isChangeStatusBackToPreparedAllowed,
    isChangeStatusFromPreparedToCheckedAllowed,
    isChangeStatusFromCheckedToReviewedAllowed,
    isChangeStatusFromReviewedToApprovedAllowed,
    isChangeStatusFromApprovedToReviewedAllowed,
  ]);

  return (
    <>
      {canShowTheStatus && (
        <>
          <div className={classNames(ChangeTimesheetStatusContentStyles.statusContainer, GlobalStyles.flex, GlobalStyles.gap05)}>
            <div
              className={classNames(
                ChangeTimesheetStatusContentStyles.checkIcon,
                statusItem === statusSelected &&
                  (statusSelected === FrontendTimesheetStatusEnum.APPROVED
                    ? [ChangeTimesheetStatusContentStyles.checkIconApproved, GlobalStyles.approvedStatusBackground]
                    : statusSelected === FrontendTimesheetStatusEnum.PENDING
                      ? [ChangeTimesheetStatusContentStyles.checkIconPending, GlobalStyles.pendingStatusBackground]
                      : [ChangeTimesheetStatusContentStyles.checkIconIntermediate, GlobalStyles.reviewedStatusBackground]),
                GlobalStyles.centerVertical,
              )}
            >
              <img src={checkIcon} alt="check" className={classNames(ChangeTimesheetStatusContentStyles.checkIconImg)} />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
              <div>
                {`${capitalizeFirstLetter(statusItem)} 
            `}
              </div>
              {statusItem === timesheetItem.status && (
                <div
                  className={classNames(
                    ChangeTimesheetStatusContentStyles.currentStatus,
                    timesheetItem.status === FrontendTimesheetStatusEnum.APPROVED
                      ? GlobalStyles.approvedStatusBackground
                      : timesheetItem.status === FrontendTimesheetStatusEnum.PENDING
                        ? GlobalStyles.pendingStatusBackground
                        : GlobalStyles.preparedStatusBackground,
                    GlobalStyles.centerVertical,
                  )}
                >{`Current`}</div>
              )}
            </div>
          </div>
          <TimesheetItemActorDetails timesheetFlowActor={findTimesheetStatusActor(timesheetItem, statusItem)} />
        </>
      )}
    </>
  );
};
export default ChangeTimesheetStatusContent;
