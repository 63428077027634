import { FrontendCalendarProjectStatusEnum, FrontendTimesheetPeriodType, FrontendTimesheetStatusEnum } from "../../../ProjectTimesheetsApiTypes.ts";
import { TimesheetPeriod } from "epcm-common/dist/Types/TimesheetTypes.ts";
import { calendarProjectStatus } from "epcm-common/dist/Types/CalendarTypes";
import { TimesheetStatus } from "epcm-common/dist/Types/TimesheetTypes";
import { ProjectTimesheetTabStatusEnum } from "../../../../projects/ProjectTimesheetsTypes.ts";

export const projectTimesheetStatusToFrontend: Map<TimesheetStatus, FrontendTimesheetStatusEnum> = new Map<
  TimesheetStatus,
  FrontendTimesheetStatusEnum
>([
  [TimesheetStatus.PENDING, FrontendTimesheetStatusEnum.PENDING],
  [TimesheetStatus.PREPARED, FrontendTimesheetStatusEnum.PREPARED],
  [TimesheetStatus.REVIEWED, FrontendTimesheetStatusEnum.REVIEWED],
  [TimesheetStatus.CHECKED, FrontendTimesheetStatusEnum.CHECKED],
  [TimesheetStatus.APPROVED, FrontendTimesheetStatusEnum.APPROVED],
]);

export const convertProjectTimesheetStatusToFrontend = (projectTimesheetStatus: TimesheetStatus): FrontendTimesheetStatusEnum => {
  return projectTimesheetStatusToFrontend.get(projectTimesheetStatus)!;
};
export const convertProjectTimesheetStatusToBackend = (projectTimesheetStatus: FrontendTimesheetStatusEnum): TimesheetStatus => {
  const projectTimesheetStatusMap: Map<FrontendTimesheetStatusEnum, TimesheetStatus> = new Map([
    [FrontendTimesheetStatusEnum.PENDING, TimesheetStatus.PENDING],
    [FrontendTimesheetStatusEnum.PREPARED, TimesheetStatus.PREPARED],
    [FrontendTimesheetStatusEnum.REVIEWED, TimesheetStatus.REVIEWED],
    [FrontendTimesheetStatusEnum.CHECKED, TimesheetStatus.CHECKED],
    [FrontendTimesheetStatusEnum.APPROVED, TimesheetStatus.APPROVED],
  ]);
  return projectTimesheetStatusMap.get(projectTimesheetStatus)!;
};

export const convertProjectTimesheetTabStatusEnumToFrontend = (
  projectTimesheetTabStatusEnum: ProjectTimesheetTabStatusEnum,
): FrontendTimesheetStatusEnum => {
  const projectTimesheetTabStatusEnumMap: Map<ProjectTimesheetTabStatusEnum, FrontendTimesheetStatusEnum> = new Map([
    [ProjectTimesheetTabStatusEnum.pending, FrontendTimesheetStatusEnum.PENDING],
    [ProjectTimesheetTabStatusEnum.prepared, FrontendTimesheetStatusEnum.PREPARED],
    [ProjectTimesheetTabStatusEnum.reviewed, FrontendTimesheetStatusEnum.REVIEWED],
    [ProjectTimesheetTabStatusEnum.checked, FrontendTimesheetStatusEnum.CHECKED],
    [ProjectTimesheetTabStatusEnum.approved, FrontendTimesheetStatusEnum.APPROVED],
  ]);
  return projectTimesheetTabStatusEnumMap.get(projectTimesheetTabStatusEnum)!;
};
export const convertFrontendTimesheetStatusToProjectTimesheetTabStatusEnum = (
  frontendTimesheetStatusEnu: FrontendTimesheetStatusEnum,
): ProjectTimesheetTabStatusEnum => {
  const frontendTimesheetStatusEnumMap: Map<FrontendTimesheetStatusEnum, ProjectTimesheetTabStatusEnum> = new Map([
    [FrontendTimesheetStatusEnum.PENDING, ProjectTimesheetTabStatusEnum.pending],
    [FrontendTimesheetStatusEnum.PREPARED, ProjectTimesheetTabStatusEnum.prepared],
    [FrontendTimesheetStatusEnum.REVIEWED, ProjectTimesheetTabStatusEnum.reviewed],
    [FrontendTimesheetStatusEnum.CHECKED, ProjectTimesheetTabStatusEnum.checked],
    [FrontendTimesheetStatusEnum.APPROVED, ProjectTimesheetTabStatusEnum.approved],
  ]);
  return frontendTimesheetStatusEnumMap.get(frontendTimesheetStatusEnu)!;
};

export const calendarProjectStatusToFrontend: Map<calendarProjectStatus, FrontendCalendarProjectStatusEnum> = new Map<
  calendarProjectStatus,
  FrontendCalendarProjectStatusEnum
>([
  [calendarProjectStatus.VALID, FrontendCalendarProjectStatusEnum.VALID],
  [calendarProjectStatus.BEFORE_PROJECT_START, FrontendCalendarProjectStatusEnum.BEFORE_PROJECT_START],
  [calendarProjectStatus.AFTER_PROJECT_END, FrontendCalendarProjectStatusEnum.AFTER_PROJECT_END],
]);

export const convertProjectTimesheetPeriodsData = (projectTimesheetPeriodsData: TimesheetPeriod[]): FrontendTimesheetPeriodType[] => {
  return projectTimesheetPeriodsData.map((timesheetPeriodItem): FrontendTimesheetPeriodType => {
    return {
      periodStart: new Date(timesheetPeriodItem.periodStart),
      periodEnd: new Date(timesheetPeriodItem.periodEnd),
    };
  });
};
