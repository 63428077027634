export enum AppRoutesPaths {
  home = "/",
  projects = "projects",
  projectSingle = ":projectId",
  projectSingleEmployees = "project-employees",
  projectSingleDocuments = "documents",
  configurationsPositions = "positions",
  configurationsTrades = "trades",
  configurationsLocations = "locations",
  configurationsCategories = "categories",
  configurationsDisciplines = "disciplines",
  configurationsCtrLibrary = "ctr-library",
  configurationsHolidays = "holidays",
  projectConfigurationsManpowerPositions = "manpower-positions",
  userPermissions = "user-permissions",
  configurationsCtrManagement = "ctr-management",
  projectConfigurationsLibrary = "library",
  projectConfigurationsMapping = "mapping",
  configurationsPermissions = "permissions",
  projectSingleManpowerPlan = "manpower-plan",
  projectSingleTimesheets = "timesheets",
  projectSingleTimesheetsGroups = "groups",
  projectSingleTimesheetsGroupsSingle = ":groupId",
  projectSingleTimesheetsReports = "reports",
  projectSingleTimesheetsArchive = "archive",
  employees = "employees",
  settings = "settings",
  fileUploader = "file-uploader",
  calendarManagement = "calendar",
  calendarManagementSingle = ":monthId",
  configurations = "configurations",
  projectConfigurationsInfo = "project-info",
  unauthorized = "unauthorized",
}

export const DEFAULT_ROUTE: AppRoutesPaths = AppRoutesPaths.projects;

export interface TimesheetsExtraRouteHandleData {
  showTimesheetExtraComponents?: TimesheetButtonsType;
  isProjectEmployees?: boolean;
  isTimesheets?: boolean;
  isManpowerPlan?: boolean;
  isDocuments?: boolean;
}

export enum TimesheetButtonsType {
  ListButtons = "ListButtons",
  GroupButtons = "GroupButtons",
  ReportButtons = "ReportButtons",
}

// Route-to-actions mapping using enums
