import { EpcmMutationOptionsType } from "../../config.ts";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../types/apicallstypes/queryCommons.ts";
import { createGlobalConfigurationEntity } from "./createGlobalConfigurationEntity.ts";
import { updateGlobalConfigurationEntity } from "./updateGlobalConfigurationEntity.ts";
import { deleteGlobalConfigurationEntity } from "./deleteGlobalConfigurationEntity.ts";
import { createSampleData } from "./createSampleData.ts";

export enum FrontendGlobalConfigurationsEntityEnum {
  Position = "positions",
  Subposition = "sub-positions",
  Trade = "trades",
  Category = "categories",
  Location = "locations",
  Discipline = "disciplines",
  Ctr = "ctrs",
  Holiday = "holidays",
}

interface ApiDefinition {
  useCreateSampleDataMutation: (mutationOptions: EpcmMutationOptionsType) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;

  useCreateGlobalEntityMutation: <T>(
    entityType: FrontendGlobalConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, T>;

  useUpdateGlobalEntityMutation: <T>(
    globalConfigurationEntityId: number,
    entityType: FrontendGlobalConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, T>;

  useDeleteGlobalEntityMutation: (
    entityId: number,
    entityType: FrontendGlobalConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subPositionId?: number,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;
}

export const useEpcmApiConfigurationsMutations = (): ApiDefinition => {
  const useCreateSampleDataMutation = (mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
      mutationFn: async () => {
        return createSampleData();
      },
      onSuccess: (data) => {
        console.log("on post create sample data mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create sample data mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useCreateGlobalEntityMutation = <T>(
    entityType: FrontendGlobalConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, T>({
      mutationFn: async (newEntityData: T) => {
        return createGlobalConfigurationEntity(entityType, newEntityData, subEntityId);
      },
      onSuccess: (data) => {
        console.log(`on post create global ${entityType} mutation success: `, data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log(`on post create global ${entityType} mutation error: `, err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useUpdateGlobalEntityMutation = <T>(
    globalConfigurationEntityId: number,
    entityType: FrontendGlobalConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, T>({
      mutationFn: async (updatedEntityData: T) => {
        return updateGlobalConfigurationEntity(globalConfigurationEntityId, entityType, updatedEntityData, subEntityId);
      },
      onSuccess: (data) => {
        console.log(`on post update global ${entityType} mutation success: `, data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log(`on post update global ${entityType} mutation error: `, err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useDeleteGlobalEntityMutation = (
    globalConfigurationEntityId: number,
    entityType: FrontendGlobalConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
      mutationFn: async () => {
        return deleteGlobalConfigurationEntity(globalConfigurationEntityId, entityType, subEntityId);
      },
      onSuccess: (data) => {
        console.log(`on post delete global ${entityType} mutation success: `, data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log(`on post delete global ${entityType} mutation error: `, err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useCreateSampleDataMutation: useCreateSampleDataMutation,
    useCreateGlobalEntityMutation: useCreateGlobalEntityMutation,
    useUpdateGlobalEntityMutation: useUpdateGlobalEntityMutation,
    useDeleteGlobalEntityMutation: useDeleteGlobalEntityMutation,
  };
};
