import classNames from "classnames";
import GlobalConfigurationHolidaysStyles from "./GlobalConfigurationHolidaysPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { FrontendGlobalHolidayType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { ApiRequestBodyGlobalHolidayCreate, ApiRequestBodyGlobalHolidayUpdate } from "epcm-common/dist/Types/GlobalHolidayTypes";
import { convertDateToMilSecs } from "../../../../../utils/DateManipulation.ts";
import { AntSwitch } from "../../../../../ui/epcmswitch/EpcmSwitch.tsx";
import EpcmDatepickerMui from "../../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { UiComponentGeneralEnum } from "../../../../../types/UiComponentGeneralType.ts";
import { Tooltip } from "@mui/material";

interface GlobalConfigurationHolidaysPopupProps extends PopupType {
  globalHolidayItem?: FrontendGlobalHolidayType;
  mode: configurationsPopupType;
}

const GlobalConfigurationHolidaysPopup: FC<GlobalConfigurationHolidaysPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  globalHolidayItem,
  mode,
}) => {
  const [globalHolidayDescription, setGlobalHolidayDescription] = useState<string>(globalHolidayItem?.description || "");
  const [globalHolidayStartDate, setGlobalHolidayStartDate] = useState<Date | null>(globalHolidayItem?.startDate || null);
  const [globalHolidayEndDate, setGlobalHolidayEndDate] = useState<Date | null>(globalHolidayItem?.endDate || null);
  const [globalHolidayIsRecurring, setGlobalHolidayIsRecurring] = useState<boolean>(globalHolidayItem?.isRecurring || false);
  const [globalHolidayIsDefault, setGlobalHolidayIsDefault] = useState<boolean>(globalHolidayItem?.setAsDefault || false);

  useEffect(() => {
    if (globalHolidayItem) {
      setGlobalHolidayDescription(globalHolidayItem.description);
      setGlobalHolidayStartDate(globalHolidayItem.startDate);
      setGlobalHolidayEndDate(globalHolidayItem.endDate);
      setGlobalHolidayIsRecurring(globalHolidayItem.isRecurring);
      setGlobalHolidayIsDefault(globalHolidayItem.setAsDefault);
    }
  }, [
    globalHolidayItem,
    setGlobalHolidayDescription,
    setGlobalHolidayStartDate,
    setGlobalHolidayEndDate,
    setGlobalHolidayIsRecurring,
    setGlobalHolidayIsDefault,
  ]);

  const { useCreateGlobalEntityMutation, useUpdateGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createGlobalHolidayMutation = useCreateGlobalEntityMutation(FrontendGlobalConfigurationsEntityEnum.Holiday, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Holiday was added successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateGlobalLocation = useCallback(() => {
    createGlobalHolidayMutation.mutate({
      description: globalHolidayDescription,
      startDateTimestamp: convertDateToMilSecs(globalHolidayStartDate!),
      endDateTimestamp: globalHolidayEndDate ? convertDateToMilSecs(globalHolidayEndDate) : undefined,
      isRecurring: globalHolidayIsRecurring,
      setAsDefault: globalHolidayIsDefault,
    } satisfies ApiRequestBodyGlobalHolidayCreate);
    onOpenLoaderPopup();
  }, [
    createGlobalHolidayMutation,
    globalHolidayDescription,
    globalHolidayStartDate,
    globalHolidayEndDate,
    globalHolidayIsRecurring,
    globalHolidayIsDefault,
    onOpenLoaderPopup,
  ]);

  const updateGlobalLocationMutation = useUpdateGlobalEntityMutation(globalHolidayItem?.id ?? 0, FrontendGlobalConfigurationsEntityEnum.Holiday, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Holiday was updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onUpdateGlobalHoliday = useCallback(() => {
    updateGlobalLocationMutation.mutate({
      description: globalHolidayDescription,
      startDateTimestamp: convertDateToMilSecs(globalHolidayStartDate!),
      endDateTimestamp: globalHolidayEndDate ? convertDateToMilSecs(globalHolidayEndDate) : undefined,
      isRecurring: globalHolidayIsRecurring,
      setAsDefault: globalHolidayIsDefault,
    } satisfies ApiRequestBodyGlobalHolidayUpdate);
    onOpenLoaderPopup();
  }, [
    updateGlobalLocationMutation,
    globalHolidayDescription,
    globalHolidayStartDate,
    globalHolidayEndDate,
    globalHolidayIsRecurring,
    globalHolidayIsDefault,
    onOpenLoaderPopup,
  ]);

  const invalidDates = useMemo(
    () => !!globalHolidayEndDate && !!globalHolidayStartDate && globalHolidayEndDate < globalHolidayStartDate,
    [globalHolidayEndDate, globalHolidayStartDate],
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalHolidays] });
  }, [queryClient]);

  const isEitherInputEmpty = useMemo(() => !globalHolidayDescription || !globalHolidayStartDate, [globalHolidayDescription, globalHolidayStartDate]);
  const hasChanges = useMemo(
    () =>
      globalHolidayDescription !== globalHolidayItem?.description ||
      globalHolidayStartDate?.getTime() !== globalHolidayItem?.startDate.getTime() ||
      globalHolidayEndDate?.getTime() !== globalHolidayItem?.endDate?.getTime() ||
      globalHolidayIsRecurring !== globalHolidayItem?.isRecurring ||
      globalHolidayIsDefault !== globalHolidayItem?.setAsDefault,
    [
      globalHolidayDescription,
      globalHolidayStartDate,
      globalHolidayEndDate,
      globalHolidayIsRecurring,
      globalHolidayIsDefault,
      globalHolidayItem?.description,
      globalHolidayItem?.startDate,
      globalHolidayItem?.endDate,
      globalHolidayItem?.isRecurring,
      globalHolidayItem?.setAsDefault,
    ],
  );

  useEffect(() => {
    if (globalHolidayStartDate && mode === configurationsPopupType.create && !globalHolidayEndDate) {
      setGlobalHolidayEndDate(globalHolidayStartDate);
    }
    if (globalHolidayStartDate && globalHolidayEndDate && globalHolidayEndDate < globalHolidayStartDate) {
      setGlobalHolidayEndDate(globalHolidayStartDate);
    }
  }, [globalHolidayStartDate, mode, globalHolidayEndDate]);
  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(GlobalConfigurationHolidaysStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Holiday" : "Create New Global Holiday",

          tooltipText: isEitherInputEmpty
            ? "Please fill all required fields"
            : !hasChanges
              ? "No changes"
              : invalidDates
                ? "End Date must be after Start Date"
                : "",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isEitherInputEmpty && onUpdateGlobalHoliday();
            } else {
              !isEitherInputEmpty && onCreateGlobalLocation();
            }
          },
          disabled: isEitherInputEmpty || !hasChanges || invalidDates,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalConfigurationHolidaysStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationHolidaysStyles.infoText)}>Holiday Description</div>
          <input
            placeholder={"Type a holiday description..."}
            className={classNames(GlobalConfigurationHolidaysStyles.infoContainer, GlobalConfigurationHolidaysStyles.infoContainerInput)}
            required
            value={globalHolidayDescription}
            onChange={(e) => setGlobalHolidayDescription(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationHolidaysStyles.infoText)}>Holiday Start Date</div>
          <EpcmDatepickerMui
            label={""}
            placeholder={"Select Start Date"}
            date={globalHolidayStartDate}
            setDate={setGlobalHolidayStartDate}
            theme={UiComponentGeneralEnum.SECONDARY}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationHolidaysStyles.infoText)}>Holiday End Date (Optional)</div>
          <Tooltip title={!globalHolidayStartDate ? "Cant select End Date before Start Date" : ""} placement="left" arrow>
            <div>
              <EpcmDatepickerMui
                label={""}
                placeholder={!globalHolidayStartDate ? "Select Start Date first" : "Select End Date"}
                disabled={!globalHolidayStartDate}
                date={globalHolidayEndDate}
                setDate={setGlobalHolidayEndDate}
                theme={!globalHolidayStartDate ? UiComponentGeneralEnum.FOURTH : UiComponentGeneralEnum.SECONDARY}
                minDate={globalHolidayStartDate ?? undefined}
              />
            </div>
          </Tooltip>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
            <div className={classNames(GlobalConfigurationHolidaysStyles.infoText)}>Recurring</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

            <AntSwitch
              type={"checkbox"}
              className={classNames(GlobalConfigurationHolidaysStyles.infoContainer, GlobalConfigurationHolidaysStyles.antSwitchContainer)}
              checked={globalHolidayIsRecurring}
              onChange={() => setGlobalHolidayIsRecurring((prev) => !prev)}
            ></AntSwitch>
          </div>
          <div className={classNames(GlobalConfigurationHolidaysStyles.helperText)}>{`(Recurring holidays are repeated every year)`}</div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
            <div className={classNames(GlobalConfigurationHolidaysStyles.infoText)}>Default</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            <AntSwitch
              type={"checkbox"}
              className={classNames(GlobalConfigurationHolidaysStyles.infoContainer, GlobalConfigurationHolidaysStyles.antSwitchContainer)}
              checked={globalHolidayIsDefault}
              onChange={() => setGlobalHolidayIsDefault((prev) => !prev)}
            ></AntSwitch>
          </div>
          <div className={classNames(GlobalConfigurationHolidaysStyles.helperText)}>
            {`(Default holidays are pre-selected in the calendar creation process)`}
          </div>
        </div>
      </div>

      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default GlobalConfigurationHolidaysPopup;
