import {
  FrontendSectionHeadType,
  FrontendTimesheetBasicType,
  FrontendTimesheetCtrRecordType,
  FrontendTimesheetEmployeeLimitedType,
  FrontendTimesheetFormHeaderDateType,
  FrontendTimesheetGroupBasicWithHasPendingType,
  FrontendTimesheetGroupCanBeDeletedType,
  FrontendTimesheetGroupDepartmentManagerType,
  FrontendTimesheetGroupNormalType,
  FrontendTimesheetLimitedType,
  FrontendTimesheetProjectEmployeeActiveMMRType,
  FrontendTimesheetRecordType,
  FrontendTimesheetStatusEnum,
  FrontendTimesheetType,
} from "../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../../../types/apicallstypes/queryCommons.ts";
import { getAllTimesheetGroups } from "./getAllTimesheetGroups.ts";
import {
  convertGroupTimesheetCtrItemRecordData,
  convertGroupTimesheetDatesData,
  convertGroupTimesheetEmployeesLimitedData,
  convertGroupTimesheetRecordData,
  convertTimesheetProjectEmployeeActiveMMRsData,
  convertTimesheetsGroupNormalData,
  convertTimesheetsGroupsData,
  convertTimesheetsGroupTimesheetBasicData,
  convertTimesheetsGroupTimesheetLimitedData,
  convertTimesheetsGroupTimesheetNormalData,
  convertTimesheetsGroupTimesheetsLimitedData,
} from "../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetgroups/ProjectTimesheetGroupsApiConverters.ts";
import { getTimesheetGroupEmployeeCount } from "./getTimesheetGroupEmployeeCount.ts";
import { getTimesheetGroup } from "./getTimesheetGroup.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { getTimesheetGroupTimesheets } from "./getTimesheetGroupTimesheets.ts";
import { getTimesheetGroupTimesheet } from "./getTimesheetGroupTimesheet.ts";
import { TimesheetBasic, TimesheetLimited, TimesheetNormal } from "epcm-common/dist/Types/TimesheetTypes.ts";
import { FrontendProjectGroupEmployeeLimitedType } from "../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { convertProjectTimesheetGroupsEmployeesData } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetgroups/ProjectTimesheetGroupsEmployeesApiConverter.ts";
import { getAllTimesheetGroupsEmployees } from "./getAllTimesheetGroupEmployees.ts";
import { getTimesheetGroupSectionHead } from "./getTimesheetGroupSectionHead.ts";
import { convertProjectTimesheetGroupSectionHeadData } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetgroups/ProjectTimesheetGroupsSectionHeadApiConverter.ts";
import { getAllGroupTimesheetEmployees } from "./getAllGroupTimesheetEmployees.ts";
import { getGroupTimesheetProjectEmployeeActiveMMRs } from "./getGroupTimesheetProjectEmployeeActiveMMRs.ts";
import { getGroupTimesheetDates } from "./getGroupTimesheetDates.ts";
import { getGroupTimesheetDateRecords } from "./getGroupTimesheetDateRecords.ts";
import { getTimesheetGroupDeletionEligibility } from "./getTimesheetGroupDeletionEligibility.ts";
import { FrontendCtrLimitedType } from "../../../../types/apicallstypes/CtrTypes.ts";
import { getGroupTimesheetActiveMmrCtrData } from "./getGroupTimesheetActiveMmrCtrData.ts";
import { getGroupTimesheetActiveMmrCtrItemRecords } from "./getGroupTimesheetActiveMmrCtrItemRecords.ts";
import { getTimesheetGroupDepartmentManager } from "./getTimesheetGroupDepartmentManager.ts";
import { convertProjectTimesheetGroupDepartmentManagerData } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetgroups/projectTimesheetGroupsDepartmentManagerApiConverter.ts";

interface ApiDefinition {
  getAllTimesheetGroups: (
    id: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendTimesheetGroupBasicWithHasPendingType>>;
  getTimesheetGroup: (id: number, timesheetGroupId: number, detailLevel?: DetailLevel) => Promise<FrontendTimesheetGroupNormalType>;
  getTimesheetGroupEmployeeCount: (id: number, timesheetGroupId: number) => Promise<number>;
  getTimesheetGroupTimesheets: (
    id: number,
    timesheetGroupId: number,
    status: FrontendTimesheetStatusEnum[],
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendTimesheetLimitedType>>;

  getTimesheetGroupTimesheet: (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    detailLevel: DetailLevel,
  ) => Promise<FrontendTimesheetLimitedType | FrontendTimesheetBasicType | FrontendTimesheetType>;

  getTimesheetGroupSectionHead: (id: number, timesheetGroupId: number) => Promise<FrontendSectionHeadType>;
  getTimesheetGroupDepartmentManager: (id: number, timesheetGroupId: number) => Promise<FrontendTimesheetGroupDepartmentManagerType>;

  getAllTimesheetGroupsEmployees: (
    id: number,
    timesheetGroupId: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
    periodStart?: Date,
    periodEnd?: Date,
  ) => Promise<QueryModelWithPagination<FrontendProjectGroupEmployeeLimitedType>>;

  getAllGroupTimesheetEmployees: (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendTimesheetEmployeeLimitedType>>;
  getGroupTimesheetProjectEmployeeActiveMMRs: (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    projectEmployeeId: number,
  ) => Promise<FrontendTimesheetProjectEmployeeActiveMMRType[]>;
  getGroupTimesheetDates: (id: number, timesheetGroupId: number, timesheetId: number) => Promise<FrontendTimesheetFormHeaderDateType[]>;
  getGroupTimesheetDateRecords: (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    calendarId: number,
  ) => Promise<FrontendTimesheetRecordType>;

  getTimesheetGroupDeletionEligibility: (id: number, timesheetGroupId: number) => Promise<FrontendTimesheetGroupCanBeDeletedType>;

  getGroupTimesheetActiveMmrCtrData: (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    projectEmployeeId: number,
    mmrId: number,
  ) => Promise<QueryModelWithCountAndData<FrontendCtrLimitedType>>;

  getGroupTimesheetActiveMmrCtrItemRecords: (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    projectEmployeeId: number,
    mmrId: number,
    ctrId: number,
  ) => Promise<FrontendTimesheetCtrRecordType>;
}

export const useEpcmApiProjectsTimesheetsGroups = (): ApiDefinition => {
  const getAllTimesheetGroupsConverted = async (
    id: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendTimesheetGroupBasicWithHasPendingType>> => {
    const timesheetGroupsData = await getAllTimesheetGroups(id, pageSize, pageOffset, searchFilter);
    return {
      data: convertTimesheetsGroupsData(timesheetGroupsData.data),
      nextPage: timesheetGroupsData.nextPage,
      totalCount: timesheetGroupsData.totalCount,
    };
  };
  const getAllTimesheetGroupsEmployeesConverted = async (
    id: number,
    timesheetGroupId: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
    periodStart?: Date,
    periodEnd?: Date,
  ): Promise<QueryModelWithPagination<FrontendProjectGroupEmployeeLimitedType>> => {
    const timesheetGroupsEmployeesData = await getAllTimesheetGroupsEmployees(
      id,
      timesheetGroupId,
      pageSize,
      pageOffset,
      searchFilter,
      periodStart,
      periodEnd,
    );
    return {
      data: convertProjectTimesheetGroupsEmployeesData(timesheetGroupsEmployeesData.data),
      nextPage: timesheetGroupsEmployeesData.nextPage,
      totalCount: timesheetGroupsEmployeesData.totalCount,
    };
  };

  const getTimesheetGroupConverted = async (
    id: number,
    timesheetGroupId: number,
    detailLevel?: DetailLevel,
  ): Promise<FrontendTimesheetGroupNormalType> => {
    const timesheetGroupData = await getTimesheetGroup(id, timesheetGroupId, detailLevel);
    return convertTimesheetsGroupNormalData(timesheetGroupData);
  };

  const getTimesheetGroupEmployeeCountConverted = async (id: number, timesheetGroupId: number): Promise<number> => {
    const timesheetGroupEmployeeCount = await getTimesheetGroupEmployeeCount(id, timesheetGroupId);
    return timesheetGroupEmployeeCount.count;
  };

  const getTimesheetGroupTimesheetsConverted = async (
    id: number,
    timesheetGroupId: number,
    status: FrontendTimesheetStatusEnum[],
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendTimesheetLimitedType>> => {
    const timesheetGroupTimesheetsData = await getTimesheetGroupTimesheets(id, timesheetGroupId, status, pageSize, pageOffset, searchFilter);
    return {
      data: convertTimesheetsGroupTimesheetsLimitedData(timesheetGroupTimesheetsData.data),
      nextPage: timesheetGroupTimesheetsData.nextPage,
      totalCount: timesheetGroupTimesheetsData.totalCount,
    };
  };

  const getTimesheetGroupTimesheetConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    detailLevel: DetailLevel = DetailLevel.BASIC,
  ): Promise<FrontendTimesheetLimitedType | FrontendTimesheetBasicType | FrontendTimesheetType> => {
    const timesheetGroupTimesheetData = await getTimesheetGroupTimesheet(id, timesheetGroupId, timesheetId, detailLevel);
    return detailLevel === DetailLevel.NORMAL
      ? convertTimesheetsGroupTimesheetNormalData(timesheetGroupTimesheetData as TimesheetNormal)
      : detailLevel === DetailLevel.LIMITED
        ? convertTimesheetsGroupTimesheetLimitedData(timesheetGroupTimesheetData as TimesheetLimited)
        : convertTimesheetsGroupTimesheetBasicData(timesheetGroupTimesheetData as TimesheetBasic);
  };
  const getTimesheetGroupSectionHeadConverted = async (id: number, timesheetGroupId: number): Promise<FrontendSectionHeadType> => {
    const timesheetGroupSectionHeadData = await getTimesheetGroupSectionHead(id, timesheetGroupId);

    return convertProjectTimesheetGroupSectionHeadData(timesheetGroupSectionHeadData);
  };
  const getTimesheetGroupDepartmentManagerConverted = async (
    id: number,
    timesheetGroupId: number,
  ): Promise<FrontendTimesheetGroupDepartmentManagerType> => {
    const timesheetGroupDepartmentManagerData = await getTimesheetGroupDepartmentManager(id, timesheetGroupId);
    return convertProjectTimesheetGroupDepartmentManagerData(timesheetGroupDepartmentManagerData);
  };

  const getAllGroupTimesheetEmployeesConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendTimesheetEmployeeLimitedType>> => {
    const groupTimesheetEmployeesData = await getAllGroupTimesheetEmployees(id, timesheetGroupId, timesheetId, pageSize, pageOffset, searchFilter);
    return {
      data: convertGroupTimesheetEmployeesLimitedData(groupTimesheetEmployeesData.data),
      nextPage: groupTimesheetEmployeesData.nextPage,
      totalCount: groupTimesheetEmployeesData.totalCount,
    };
  };

  const getGroupTimesheetProjectEmployeeActiveMMRsConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    projectEmployeeId: number,
  ): Promise<FrontendTimesheetProjectEmployeeActiveMMRType[]> => {
    const timesheetProjectEmployeeActiveMMRsData = await getGroupTimesheetProjectEmployeeActiveMMRs(
      id,
      timesheetGroupId,
      timesheetId,
      timesheetEmployeeId,
      projectEmployeeId,
    );
    return convertTimesheetProjectEmployeeActiveMMRsData(timesheetProjectEmployeeActiveMMRsData);
  };

  const getGroupTimesheetDatesConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
  ): Promise<FrontendTimesheetFormHeaderDateType[]> => {
    const timesheetDatesData = await getGroupTimesheetDates(id, timesheetGroupId, timesheetId);
    return convertGroupTimesheetDatesData(timesheetDatesData.calendars);
  };

  const getGroupTimesheetDateRecordsConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    calendarId: number,
  ): Promise<FrontendTimesheetRecordType> => {
    const timesheetDateRecordsData = await getGroupTimesheetDateRecords(id, timesheetGroupId, timesheetId, timesheetEmployeeId, calendarId);
    return convertGroupTimesheetRecordData(timesheetDateRecordsData);
  };

  const getTimesheetGroupCanBeDeletedConverted = async (id: number, timesheetGroupId: number): Promise<FrontendTimesheetGroupCanBeDeletedType> => {
    const timesheetGroupCanBeDeleted = await getTimesheetGroupDeletionEligibility(id, timesheetGroupId);

    return {
      canBeDeleted: timesheetGroupCanBeDeleted.canBeDeleted,
    };
  };
  const getGroupTimesheetActiveMmrCtrDataConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    projectEmployeeId: number,
    mmrId: number,
  ): Promise<QueryModelWithCountAndData<FrontendCtrLimitedType>> => {
    const timesheetGroupActiveMmrCtrData = await getGroupTimesheetActiveMmrCtrData(
      id,
      timesheetGroupId,
      timesheetId,
      timesheetEmployeeId,
      projectEmployeeId,
      mmrId,
    );

    return {
      data: timesheetGroupActiveMmrCtrData.data,
      count: timesheetGroupActiveMmrCtrData.count,
    };
  };

  const getGroupTimesheetActiveMmrCtrItemRecordsConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
    timesheetEmployeeId: number,
    projectEmployeeId: number,
    mmrId: number,
    ctrId: number,
  ): Promise<FrontendTimesheetCtrRecordType> => {
    const timesheetGroupActiveMmrCtrItemRecords = await getGroupTimesheetActiveMmrCtrItemRecords(
      id,
      timesheetGroupId,
      timesheetId,
      timesheetEmployeeId,
      projectEmployeeId,
      mmrId,
      ctrId,
    );
    return convertGroupTimesheetCtrItemRecordData(timesheetGroupActiveMmrCtrItemRecords);
  };

  return {
    getAllTimesheetGroups: getAllTimesheetGroupsConverted,
    getTimesheetGroup: getTimesheetGroupConverted,
    getTimesheetGroupEmployeeCount: getTimesheetGroupEmployeeCountConverted,
    getTimesheetGroupTimesheets: getTimesheetGroupTimesheetsConverted,
    getTimesheetGroupTimesheet: getTimesheetGroupTimesheetConverted,
    getAllTimesheetGroupsEmployees: getAllTimesheetGroupsEmployeesConverted,
    getTimesheetGroupSectionHead: getTimesheetGroupSectionHeadConverted,
    getTimesheetGroupDepartmentManager: getTimesheetGroupDepartmentManagerConverted,
    getAllGroupTimesheetEmployees: getAllGroupTimesheetEmployeesConverted,
    getGroupTimesheetProjectEmployeeActiveMMRs: getGroupTimesheetProjectEmployeeActiveMMRsConverted,
    getGroupTimesheetDates: getGroupTimesheetDatesConverted,
    getGroupTimesheetDateRecords: getGroupTimesheetDateRecordsConverted,
    getTimesheetGroupDeletionEligibility: getTimesheetGroupCanBeDeletedConverted,
    getGroupTimesheetActiveMmrCtrData: getGroupTimesheetActiveMmrCtrDataConverted,
    getGroupTimesheetActiveMmrCtrItemRecords: getGroupTimesheetActiveMmrCtrItemRecordsConverted,
  };
};
