import { FrontendTimesheetActorType, FrontendTimesheetStatusEnum, FrontendTimesheetType } from "../types/apicallstypes/ProjectTimesheetsApiTypes.ts";

export const findTimesheetStatusActor = (
  timesheet: FrontendTimesheetType,
  status: FrontendTimesheetStatusEnum,
): FrontendTimesheetActorType | null => {
  const statusToActorMapping: Record<FrontendTimesheetStatusEnum, FrontendTimesheetActorType | null> = {
    [FrontendTimesheetStatusEnum.PENDING]: {
      user: timesheet.editor,
      actionDate: timesheet.editDate,
    },
    [FrontendTimesheetStatusEnum.PREPARED]: timesheet.submitter ?? null,
    [FrontendTimesheetStatusEnum.CHECKED]: timesheet.checker ?? null,
    [FrontendTimesheetStatusEnum.REVIEWED]: timesheet.reviewer ?? null,
    [FrontendTimesheetStatusEnum.APPROVED]: timesheet.approver ?? null,
  };

  return statusToActorMapping[status];
};
