import { FC, useEffect, useMemo, useState } from "react";
import TrashCanDarkIcon from "../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIconHover from "../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import EditIcon from "../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../assets/images/trashcan-icon-red.svg";

import GlobalConfigurationHolidaysRowStyles from "./GlobalConfigurationHolidaysRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FrontendGlobalHolidayType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { useQueryClient } from "@tanstack/react-query";
import { SettingsPagePopups, useSettingsPopups } from "../../../use-settings-pupups.ts";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";

import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../../types/Roles.ts";
import { formatDate, formatDateWithoutYear } from "../../../../../utils/DateManipulation.ts";
import GlobalConfigurationHolidaysPopup from "../../popups/globalconfigurationholidayspopup/GlobalConfigurationHolidaysPopup.tsx";

interface GlobalConfigurationHolidayRowProps {
  globalHolidayItem: FrontendGlobalHolidayType;
}

const GlobalConfigurationHolidaysRow: FC<GlobalConfigurationHolidayRowProps> = ({ globalHolidayItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { useDeleteGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleetGlobalHolidayMutation = useDeleteGlobalEntityMutation(globalHolidayItem.id, FrontendGlobalConfigurationsEntityEnum.Holiday, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Global Holiday was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const invalidateQueries = () => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalHolidays] });
  };

  const canListConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  const canEditConfigurations = canPerformGlobalAction(GlobalAction.ConfigUpdate);
  const canDeleteConfigurations = canPerformGlobalAction(GlobalAction.ConfigDelete);
  //const canCreateConfiguration = canPerformGlobalAction(GlobalAction.ConfigCreate);

  const isRecurring = useMemo(() => globalHolidayItem.isRecurring, [globalHolidayItem.isRecurring]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.GlobalHolidays, globalHolidayItem.id] }).then(() => {
        console.log(`In configuration-holiday-row component, ${QueryNames.GlobalHolidays} query for position ${globalHolidayItem.id} cancelled`);
      });
    };
  }, [queryClient, globalHolidayItem.id]);

  return (
    <>
      <tr
        key={globalHolidayItem.id}
        className={classNames(
          GlobalConfigurationHolidaysRowStyles.itemContainer,
          isRowHovered && GlobalConfigurationHolidaysRowStyles.rowHoverEffect,
          GlobalConfigurationHolidaysRowStyles.Trow,
        )}
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
      >
        {globalHolidayItem && canListConfigurations ? (
          <>
            <td className={classNames(GlobalConfigurationHolidaysRowStyles.tableCell, GlobalConfigurationHolidaysRowStyles.tD)}>
              {globalHolidayItem.description}
            </td>
            <td className={classNames(GlobalConfigurationHolidaysRowStyles.tableCell, GlobalConfigurationHolidaysRowStyles.tD)}>
              {isRecurring ? formatDateWithoutYear(globalHolidayItem.startDate) : formatDate(globalHolidayItem.startDate)}
            </td>
            <td className={classNames(GlobalConfigurationHolidaysRowStyles.tableCell, GlobalConfigurationHolidaysRowStyles.tD)}>
              {globalHolidayItem.endDate
                ? isRecurring
                  ? formatDateWithoutYear(globalHolidayItem.endDate)
                  : formatDate(globalHolidayItem.endDate)
                : "-"}
            </td>
            <td className={classNames(GlobalConfigurationHolidaysRowStyles.tableCell, GlobalConfigurationHolidaysRowStyles.tD)}>
              {globalHolidayItem.duration > 1 ? globalHolidayItem.duration + " Days" : globalHolidayItem.duration + " Day"}
            </td>
            <td className={classNames(GlobalConfigurationHolidaysRowStyles.tableCell, GlobalConfigurationHolidaysRowStyles.tD)}>
              {globalHolidayItem.isRecurring ? "Yes" : "No"}
            </td>
            <td className={classNames(GlobalConfigurationHolidaysRowStyles.tableCell, GlobalConfigurationHolidaysRowStyles.tD)}>
              {globalHolidayItem.setAsDefault ? "Yes" : "No"}
            </td>
            <td className={classNames(GlobalConfigurationHolidaysRowStyles.tableCellActions, GlobalConfigurationHolidaysRowStyles.tD)}>
              <div
                className={classNames(
                  GlobalConfigurationHolidaysRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfigurations && (
                  <div
                    className={classNames(GlobalConfigurationHolidaysRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(SettingsPagePopups.updateGlobalHoliday, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>

      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleetGlobalHolidayMutation.mutate();

            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(SettingsPagePopups.updateGlobalHoliday)!.isOpen && (
        <GlobalConfigurationHolidaysPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(SettingsPagePopups.updateGlobalHoliday, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.updateGlobalHoliday)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.updateGlobalHoliday)}
          globalHolidayItem={globalHolidayItem}
        />
      )}
      {/*{popupHandler.get(SettingsPagePopups.updateGlobalPosition)!.isOpen && (*/}
      {/*  <GlobalConfigurationPositionsPopup*/}
      {/*    mode={configurationsPopupType.edit}*/}
      {/*    closeFn={() => onClosePopup(SettingsPagePopups.updateGlobalPosition, popupHandler)}*/}
      {/*    isOpen={popupHandler.get(SettingsPagePopups.updateGlobalPosition)!.isOpen}*/}
      {/*    headerText={popupHeaders.get(SettingsPagePopups.updateGlobalPosition)}*/}
      {/*    globalPositionItem={globalHolidayItem}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default GlobalConfigurationHolidaysRow;
