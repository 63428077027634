import classNames from "classnames";
import ViewSettingsOptionsStyles from "./ViewSettingsOptionsStyles.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { EPCMInfoContainerDiv } from "../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import {
  ProjectGroupTimesheetSettingsActionTypesEnum,
  ProjectTimesheetGroupModificationTypeEnum,
  ProjectTimesheetGroupPopupModeEnum,
} from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import TimesheetGroupSettingsSectionHeadActiveIcon from "../../../../../assets/images/timesheet-group-settings-section-head-active-icon.svg";
import TimesheetSectionIcon from "../../../../../assets/images/timesheet-section-icon.svg";
import TimesheetGroupSettingsMemberActiveIcon from "../../../../../assets/images/timesheet-group-settings-member-active-icon.svg";
import TimesheetGroupIcon from "../../../../../assets/images/timesheet-group-icon.svg";
import TimesheetGroupRenamePencilIcon from "../../../../../assets/images/pencil-black.svg";

import TimesheetDeleteGroupLockIcon from "../../../../../assets/images/timesheet-delete-group-lock-icon.svg";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import CreateNewProjectGroup from "../../createnewprojectgroup/CreateNewProjectGroup.tsx";

interface ViewSettingsOptionsProps {
  setPopupMode: (mode: ProjectTimesheetGroupPopupModeEnum) => void;
  projectId: number;
  groupId: number;
  timesheetGroupName: string;
  redirectLink?: string;
}

const ViewSettingsOptions: FC<ViewSettingsOptionsProps> = ({ timesheetGroupName, setPopupMode, projectId, groupId, redirectLink }) => {
  const [hoveredOption, setHoveredOption] = useState<ProjectGroupTimesheetSettingsActionTypesEnum | null>(null);
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const isAddSectionHeadButtonHovered = useMemo(() => hoveredOption === ProjectGroupTimesheetSettingsActionTypesEnum.addSection, [hoveredOption]);
  const isAddMemberButtonHovered = useMemo(() => hoveredOption === ProjectGroupTimesheetSettingsActionTypesEnum.addGroupMember, [hoveredOption]);
  const isAddDepartmentManagerButtonHovered = useMemo(
    () => hoveredOption === ProjectGroupTimesheetSettingsActionTypesEnum.addDepartmentManager,
    [hoveredOption],
  );
  // const isRenameGroupButtonHovered = useMemo(() => hoveredOption === ProjectGroupTimesheetSettingsActionTypesEnum.renameGroup, [hoveredOption]);
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { popupHandler, popupHeaders, onOpenPopup, onClosePopup } = useProjectsPopups();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { useDeleteTimesheetGroupMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const { getTimesheetGroupDeletionEligibility } = useEpcmApiProjectsTimesheetsGroups();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const timesheetGroupItemDeletionEligibilityQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupDeletionEligibility, projectId, groupId],
    queryFn: () => getTimesheetGroupDeletionEligibility(projectId, groupId),
    enabled: isAuthorized,
  });

  const timesheetGroupItemDeletionEligibilityData = useMemo(() => {
    return timesheetGroupItemDeletionEligibilityQuery.data ?? undefined;
  }, [timesheetGroupItemDeletionEligibilityQuery]);

  const canDeleteTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupDelete);
  const canRenameTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupRename);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.TimesheetGroupDeletionEligibility, projectId, groupId] }).then(() => {
        console.log(`In timesheets-groups component, ${QueryNames.TimesheetGroupDeletionEligibility} query cancelled`);
      });
    };
  }, [queryClient, projectId, groupId]);

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const deleteTimesheetGroupMutation = useDeleteTimesheetGroupMutation(Number(projectId), groupId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Group was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });
  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, Number(projectId)] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, Number(projectId)] });
  }, [queryClient, projectId]);

  return (
    <>
      <div
        className={classNames(ViewSettingsOptionsStyles.settingsContentDiv, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}
      >
        <div className={classNames(ViewSettingsOptionsStyles.contentTitle)}>Group Settings</div>
        <div className={classNames(ViewSettingsOptionsStyles.contentValue)}>
          Select from the options below to manage group members or section head.
        </div>
      </div>

      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2, ViewSettingsOptionsStyles.settingsContentDiv)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap2)}>
          <EPCMInfoContainerDiv
            onMouseEnter={() => setHoveredOption(ProjectGroupTimesheetSettingsActionTypesEnum.addSection)}
            onMouseLeave={() => setHoveredOption(ProjectGroupTimesheetSettingsActionTypesEnum.none)}
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
              hoveredOption === ProjectGroupTimesheetSettingsActionTypesEnum.addSection
                ? ViewSettingsOptionsStyles.epcmDivActive
                : ViewSettingsOptionsStyles.epcmDiv,
            )}
            onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.addSection)}
          >
            <div>
              <img
                src={isAddSectionHeadButtonHovered ? TimesheetGroupSettingsSectionHeadActiveIcon : TimesheetSectionIcon}
                alt={"timesheet-group-settings-section-icon"}
              />
            </div>
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap01,
                isAddSectionHeadButtonHovered ? ViewSettingsOptionsStyles.epcmDivTextActive : ViewSettingsOptionsStyles.epcmDivText,
              )}
            >
              <div>Set</div>
              <div>Section head</div>
            </div>
          </EPCMInfoContainerDiv>

          <EPCMInfoContainerDiv
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
              isAddMemberButtonHovered ? ViewSettingsOptionsStyles.epcmDivActive : ViewSettingsOptionsStyles.epcmDiv,
            )}
            onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.addGroupMember)}
            onMouseEnter={() => setHoveredOption(ProjectGroupTimesheetSettingsActionTypesEnum.addGroupMember)}
            onMouseLeave={() => setHoveredOption(ProjectGroupTimesheetSettingsActionTypesEnum.none)}
          >
            <div>
              <img
                src={isAddMemberButtonHovered ? TimesheetGroupSettingsMemberActiveIcon : TimesheetGroupIcon}
                alt={"timesheet-group-settings-members-icon"}
              />
            </div>
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap01,
                isAddMemberButtonHovered ? ViewSettingsOptionsStyles.epcmDivTextActive : ViewSettingsOptionsStyles.epcmDivText,
              )}
            >
              <div>Add</div>
              <div>Group Employees</div>
            </div>
          </EPCMInfoContainerDiv>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap2)}>
          <EPCMInfoContainerDiv
            className={classNames(
              ViewSettingsOptionsStyles.addDepartmentManagerDiv,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
              isAddDepartmentManagerButtonHovered ? ViewSettingsOptionsStyles.epcmDivActive : ViewSettingsOptionsStyles.epcmDiv,
            )}
            onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.addDepartmentManager)}
            onMouseEnter={() => setHoveredOption(ProjectGroupTimesheetSettingsActionTypesEnum.addDepartmentManager)}
            onMouseLeave={() => setHoveredOption(ProjectGroupTimesheetSettingsActionTypesEnum.none)}
          >
            <div>
              <img
                src={isAddDepartmentManagerButtonHovered ? TimesheetGroupSettingsSectionHeadActiveIcon : TimesheetSectionIcon}
                alt={"timesheet-group-settings-section-icon"}
              />
            </div>
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap01,
                isAddDepartmentManagerButtonHovered ? ViewSettingsOptionsStyles.epcmDivTextActive : ViewSettingsOptionsStyles.epcmDivText,
              )}
            >
              <div>Set</div>
              <div>Department Manager</div>
            </div>
          </EPCMInfoContainerDiv>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
            {canRenameTimesheetGroup && (
              <div
                className={classNames(
                  ViewSettingsOptionsStyles.deleteTextPadding,
                  ViewSettingsOptionsStyles.underlinedText,
                  GlobalStyles.elementWithCursor,
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,

                  GlobalStyles.gap05,
                )}
                onClick={() => {
                  onOpenPopup(ProjectsPagePopups.updateProjectGroupName, popupHandler);
                }}
              >
                <div>Rename Group</div>
                <div>
                  <img
                    className={classNames(ViewSettingsOptionsStyles.renameIcon)}
                    src={TimesheetGroupRenamePencilIcon}
                    alt={"timesheet-group-settings-rename-icon"}
                  />
                </div>
              </div>
            )}
            {canDeleteTimesheetGroup ? (
              !timesheetGroupItemDeletionEligibilityQuery.isLoading ? (
                <div
                  className={classNames(
                    ViewSettingsOptionsStyles.deleteTextPadding,
                    timesheetGroupItemDeletionEligibilityData?.canBeDeleted && GlobalStyles.elementWithCursor,
                    GlobalStyles.flex,
                    GlobalStyles.centerHorizontal,
                    GlobalStyles.gap05,
                  )}
                  onClick={() => {
                    if (timesheetGroupItemDeletionEligibilityData?.canBeDeleted) {
                      onOpenUtilPopup();
                    }
                  }}
                >
                  <Tooltip
                    title={timesheetGroupItemDeletionEligibilityData?.canBeDeleted ? "" : "Group cannot be deleted"}
                    TransitionComponent={Fade}
                    placement={"top-end"}
                    arrow
                  >
                    <div
                      className={classNames(
                        timesheetGroupItemDeletionEligibilityData?.canBeDeleted
                          ? (GlobalStyles.elementWithCursor, ViewSettingsOptionsStyles.underlinedText)
                          : ViewSettingsOptionsStyles.deleteTextDisabled,
                      )}
                    >
                      Delete Group
                    </div>
                  </Tooltip>
                  {timesheetGroupItemDeletionEligibilityData?.canBeDeleted && (
                    <div
                      className={classNames(
                        ViewSettingsOptionsStyles.iconContainer,
                        GlobalStyles.flex,
                        GlobalStyles.centerHorizontal,
                        GlobalStyles.centerVertical,
                      )}
                    >
                      <img src={TimesheetDeleteGroupLockIcon} alt={"timesheet-group-settings-delete-icon"} />
                    </div>
                  )}
                </div>
              ) : (
                <Skeleton variant={"rounded"} width={150} height={30}></Skeleton>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteTimesheetGroupMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              redirectLink && navigate(redirectLink, { replace: true }); // ensure replace: true is set here
            }
          }}
        />
      )}
      {popupHandler.get(ProjectsPagePopups.updateProjectGroupName)!.isOpen && (
        <CreateNewProjectGroup
          mode={ProjectTimesheetGroupModificationTypeEnum.update}
          projectId={Number(projectId)!}
          timesheetGroupId={groupId}
          existingGroupName={timesheetGroupName}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectGroupName)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectGroupName, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectGroupName)}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </>
  );
};

export default ViewSettingsOptions;
